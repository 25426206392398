import React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const ExtraWidthBtn = styled(Button)({
  minWidth: '186px',
  minHeight: '42px',
});

interface IExtraWidthButtonProps {
  children: React.ReactNode;
  variant?: 'contained' | 'outlined' | 'text';
  disabled?: boolean;
  onClick: () => void;
}

const ExtraWidthButton: React.FC<IExtraWidthButtonProps> = ({ children, onClick, variant, disabled, ...rest }) => {
  return (
    <ExtraWidthBtn onClick={onClick} variant={variant} disabled={disabled} {...rest}>
      {children}
    </ExtraWidthBtn>
  );
};

export default ExtraWidthButton;
