import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormGroup,
  FormControlLabel,
  InputLabel,
  Radio,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { TCreateUser, TUserRole } from '../../../../../../types';
import ExtraWidthButton from '../../../../../../components/Buttons/ExtraWidthButton';
import CloseButton from '../../../../../../components/Buttons/CloseButton';
import { isValidMail } from '../../../../../../helpers';
import { debounce } from 'lodash';

const InviteFormGroup = styled(FormGroup)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'baseline',
  marginBottom: '24px',
});

const CloseButtonContainer = styled('div')({
  position: 'absolute',
  right: '8px',
});

interface IInviteUserProps {
  open: boolean;
  onClose: () => void;
  onInvite: (newUser: TCreateUser) => void;
  roles: TUserRole[] | null;
}

const InviteUser: React.FC<IInviteUserProps> = ({ open, onClose, onInvite, roles }) => {
  const { t } = useTranslation();

  const [firstName, setFirstName] = useState<string | null>();
  const [lastName, setLastName] = useState<string | null>();
  const [email, setEmail] = useState<string | null>();
  const [userRole, setUserRole] = useState<string | null>();

  const [formIsValid, setFormIsValid] = useState<boolean>(false);
  const [mailIsValid, setMailIsValid] = useState<boolean>(false);
  const [showMailError, setShowMailError] = useState<boolean>(false);

  const cleanForm = () => {
    setFirstName(null);
    setLastName(null);
    setEmail(null);
    setShowMailError(false);
    setUserRole(null);
  };

  const handleSubmit = () => {
    if (firstName && lastName && email && userRole) {
      //send invite
      onInvite({
        firstName,
        lastName,
        displayName: `${firstName} ${lastName}`,
        emailAddress: email,
        roleIds: [userRole],
      });

      // clear and close form
      cleanForm();
      onClose();
    }
  };

  const handleClose = () => {
    cleanForm();
    onClose();
  };

  const handleMailError = useMemo(
    () =>
      debounce((isValid) => {
        if (isValid) {
          setShowMailError(false);
        } else {
          setShowMailError(true);
        }
      }, 500),
    [],
  );

  useEffect(() => {
    if (email) {
      const isValid = isValidMail(email);
      setMailIsValid(!!isValid);
      handleMailError(isValid);
    }
  }, [email, handleMailError]);

  useEffect(() => {
    if (firstName && lastName && email && userRole && mailIsValid) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  }, [firstName, lastName, email, userRole, mailIsValid]);

  return (
    <Dialog open={open} onClose={handleClose} PaperProps={{ sx: { minWidth: '414px' } }}>
      <CloseButtonContainer>
        <CloseButton onClick={handleClose} />
      </CloseButtonContainer>
      <DialogTitle>{t('administration:userManagement:invite:title')}</DialogTitle>
      <DialogContent>
        <InviteFormGroup>
          <InputLabel shrink htmlFor='firstName'>
            {t('administration:userManagement:invite:firstName')}
            <sup>*</sup>
          </InputLabel>
          <TextField
            autoFocus
            id='firstName'
            type='text'
            size='small'
            fullWidth
            value={firstName}
            onChange={(event) => setFirstName(event.target.value)}
          />
        </InviteFormGroup>
        <InviteFormGroup>
          <InputLabel shrink htmlFor='lastName'>
            {t('administration:userManagement:invite:lastName')}
            <sup>*</sup>
          </InputLabel>
          <TextField
            autoFocus
            id='lastName'
            type='text'
            size='small'
            fullWidth
            value={lastName}
            onChange={(event) => setLastName(event.target.value)}
          />
        </InviteFormGroup>
        <InviteFormGroup>
          <InputLabel shrink htmlFor='email'>
            {t('administration:userManagement:invite:email:label')}
            <sup>*</sup>
          </InputLabel>
          <TextField
            id='email'
            type='email'
            size='small'
            fullWidth
            value={email}
            error={showMailError}
            helperText={showMailError ? t('administration:userManagement:invite:email:invalid') : ''}
            onChange={(event) => setEmail(event.target.value)}
          />
        </InviteFormGroup>
        <InputLabel shrink htmlFor='roles'>
          {t('administration:userManagement:invite:role')}
          <sup>*</sup>
        </InputLabel>
        <FormGroup id='roles'>
          {roles?.map((role) => (
            <FormControlLabel
              key={role.roleId}
              control={<Radio checked={userRole === role.roleId} onChange={() => setUserRole(role.roleId)} />}
              label={t(`${role.i18nKey}`)}
            />
          ))}
        </FormGroup>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-around', marginBottom: '16px' }}>
        <ExtraWidthButton onClick={handleClose} variant='outlined'>
          {t('common:cancel')}
        </ExtraWidthButton>
        <ExtraWidthButton onClick={handleSubmit} variant='contained' disabled={!formIsValid}>
          {t('administration:userManagement:invite:submit')}
        </ExtraWidthButton>
      </DialogActions>
    </Dialog>
  );
};

export default InviteUser;
