import React from 'react';
import { Snackbar, IconButton, Slide, Link, Alert } from '@mui/material';
import { styled } from '@mui/material/styles';
import { SNACKBAR_TIMEOUT } from '../../constants';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseIcon from '@mui/icons-material/Close';

/* ------- Styles ------- */
const ActionLink = styled(Link)({
  fontSize: '13.5px',
  fontWeight: 500,
  padding: '4px 10px',
  cursor: 'pointer',
});

/* ------- Types ------- */
interface ICustomSnackbar {
  open: boolean;
  message: string;
  actionLabel?: string;
  severity?: 'error' | 'warning' | 'info' | 'success';
  onClose: () => void;
  onAction?: () => void;
}

/* ------- Components ------- */
const TransitionDown = (props) => <Slide {...props} direction='down' />;

const CustomSnackbar: React.FC<ICustomSnackbar> = ({ open, message, actionLabel, severity, onClose, onAction }) => {
  const handleClose = (_e: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    onClose();
  };

  const action = (
    <>
      {actionLabel && onAction && (
        <ActionLink underline='always' variant='body1' onClick={onAction} data-testid='snackbar-action-button'>
          {actionLabel}
        </ActionLink>
      )}
      <IconButton
        size='small'
        aria-label='close'
        color='inherit'
        onClick={handleClose}
        data-testid='snackbar-close-button'
      >
        <CloseIcon fontSize='small' />
      </IconButton>
    </>
  );

  const getAlertIcon = () => {
    switch (severity) {
      case 'error':
        return <ErrorIcon fontSize='inherit' />;
      case 'warning':
        return <WarningIcon fontSize='inherit' />;
      case 'info':
        return <InfoOutlinedIcon fontSize='inherit' />;
      case 'success':
        return <CheckCircleIcon fontSize='inherit' />;
      default:
        return <InfoOutlinedIcon fontSize='inherit' />;
    }
  };

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      TransitionComponent={TransitionDown}
      autoHideDuration={SNACKBAR_TIMEOUT}
      onClose={handleClose}
      data-testid='snackbar-container'
      sx={{
        '&.MuiSnackbar-root': {
          top: '140px',
        },
      }}
      ContentProps={{
        sx: {
          backgroundColor: 'white',
          color: 'black',
        },
      }}
    >
      <Alert severity={severity} icon={getAlertIcon()} action={action} sx={{ width: '100%' }} onClose={handleClose}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackbar;
