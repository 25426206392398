import React from 'react';
import { Card, CardContent, Typography, Button, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

/* ------- Styles ------- */
const Wrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  backgroundImage: 'url("/images/login-bg.png")',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
});

const StyledCard = styled(Card)({
  minWidth: '49%',
  minHeight: '51%',
  padding: '40px 60px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const StyledCardContent = styled(CardContent)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const StyledLabel = styled(Typography)(
  ({ theme }) => `
    font-size: 48px;
    fontWeight: 700;
    color: ${theme.palette.primary.main}
  `,
);

/* ------- Types ------- */
interface IUnauthenticated {
  handleClick: () => void;
}

/* ------- Components ------- */
const Unauthenticated: React.FC<IUnauthenticated> = ({ handleClick }) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <StyledCard>
        <StyledCardContent>
          <img
            src='/images/unauthenticated-lock.svg'
            width='143'
            height='143'
            alt={t('common:logo:alt')}
            style={{ alignSelf: 'center' }}
          />
          <StyledLabel>{t('common:notAuthenticated')}</StyledLabel>
          <br />
          <Typography sx={{ fontSize: '21px' }}>{t('common:haveNoAccess')}</Typography>
          <br />
          <br />
          <Button
            variant='contained'
            sx={{ color: '#fff', fontSize: '16px' }}
            onClick={handleClick}
            data-testid='unauthenticated-button-to-login'
          >
            {t('user:login:loginHere')}
          </Button>
        </StyledCardContent>
      </StyledCard>
    </Wrapper>
  );
};

export default Unauthenticated;
