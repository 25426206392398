import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from '../../../../../hooks/redux';
import { selectUserId, selectUsers } from '../../../../../store/selectors/user';
import { selectIssueDetails } from '../../../../../store/selectors/issue';
import Comment from '../../../../../components/Comment';
import { Button, TextField, Typography } from '@mui/material';
import {
  deleteIssueCommentsPending,
  setIssueCommentsPending,
  updateIssueCommentsPending,
} from '../../../../../store/slices/issues';
import { getApiUserName, getAvatarLetters, getUserName } from '../../../../../helpers';
import DeleteDialog from '../../../../../components/DeleteDialog';
import { REACT_APP_USE_API_DIRECTLY_FF } from '../../../../../config';
import SelectUserAutocomplete from '../../../../../components/Autocomplete';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 40px;
  height: 100%;
`;

const CommentsWrapper = styled('div')<{ height: number }>(
  ({ height }) => `
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: ${height}px;
  overflow: auto;
  padding: 0 20px;
`,
);

const EmptyCommentsBlock = styled(Typography)`
  color: #bcbcbc;
  text-align: center;
  font-size: 32px;
  font-weight: 400;
  line-height: 42px;
  letter-spacing: 0.25px;
  margin-top: 30%;
`;

const NewCommentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

interface ICommentProps {
  tabPanelHeight: number;
}

const Comments: React.FC<ICommentProps> = ({ tabPanelHeight }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userId = useSelector(selectUserId);
  const users = useSelector(selectUsers);
  const details = useSelector(selectIssueDetails);

  const getName = REACT_APP_USE_API_DIRECTLY_FF ? getApiUserName : getUserName;

  const [newComment, setNewComment] = useState<string>('');
  const [tagUser, setTagUser] = useState<{ title: string; id: string }[]>([]);
  const [deleteDialog, setDeleteDialog] = useState<boolean>(false);
  const [commentToDelete, setCommentToDelete] = useState<string | null>(null);
  const newCommentRef = useRef<HTMLDivElement>(null);
  const commentsRef = useRef<HTMLDivElement>(null);

  const commentsWrapperHeight = tabPanelHeight - (newCommentRef.current?.offsetHeight || 250) - 100; // minus container paddings adn margins

  useEffect(() => {
    if (commentsRef.current) {
      commentsRef.current.scrollTop = commentsRef.current.scrollHeight;
    }
  }, [details?.issueComments]);

  const handleSend = () => {
    if (!details || !newComment.length) return;

    // send comment
    dispatch(
      setIssueCommentsPending({
        issueComments: [
          {
            issueId: details.id,
            comment: newComment,
            tagUserIds: tagUser.map((user) => user.id),
          },
        ],
      }),
    );

    // clear comment data
    setNewComment('');
    setTagUser([]);
  };

  const handleUpdate = (id, comment) => {
    // update comment
    dispatch(
      updateIssueCommentsPending({
        issueComments: [
          {
            id,
            comment,
          },
        ],
      }),
    );
  };

  const handleDeleteRequest = (id: string) => {
    setCommentToDelete(id);
    setDeleteDialog(true);
  };

  const cancelDeleteRequest = () => {
    setCommentToDelete(null);
    setDeleteDialog(false);
  };

  const handleDelete = () => {
    if (commentToDelete) {
      // delete comment
      dispatch(
        deleteIssueCommentsPending({
          ids: [commentToDelete],
        }),
      );

      // clear states
      setDeleteDialog(false);
      setCommentToDelete(null);
    }
  };

  return (
    <Container>
      {userId && users && details?.issueComments && (
        <>
          <CommentsWrapper ref={commentsRef} height={commentsWrapperHeight}>
            {details.issueComments.length ? (
              details.issueComments.map((comment) => (
                <Comment
                  key={comment.id}
                  comment={{
                    ...comment,
                    userName: getName(comment.createdBy, users) || t('common:unknownUser'),
                    avatarLetters: getAvatarLetters(getName(comment.createdBy, users) || t('common:unknownUser')),
                  }}
                  userIsAuthor={comment.createdBy === userId}
                  onUpdate={handleUpdate}
                  onDelete={handleDeleteRequest}
                />
              ))
            ) : (
              <EmptyCommentsBlock>{t('issues:detailedView:comments:noComments')}</EmptyCommentsBlock>
            )}
          </CommentsWrapper>

          <NewCommentWrapper ref={newCommentRef}>
            <TextField
              fullWidth
              multiline
              rows={4}
              placeholder={t('issues:detailedView:comments:newCommentPlaceholder')}
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
            />
            <div style={{ display: 'flex', justifyContent: 'space-between', gap: '32px' }}>
              <div style={{ width: 'auto', maxWidth: '70%' }}>
                <SelectUserAutocomplete
                  value={tagUser}
                  onValueChange={(val) => setTagUser(val)}
                  options={users.map((user) => ({ title: user.displayName, id: user.userId }))}
                />
              </div>
              <div style={{ display: 'flex', justifyContent: 'end', gap: '32px', height: '48px' }}>
                <Button
                  variant='outlined'
                  onClick={() => {
                    setNewComment('');
                    setTagUser([]);
                  }}
                >
                  {t('issues:detailedView:comments:discardComment')}
                </Button>
                <Button variant='contained' onClick={handleSend}>
                  {t('issues:detailedView:comments:sendComment')}
                </Button>
              </div>
            </div>
          </NewCommentWrapper>
        </>
      )}

      <DeleteDialog
        open={deleteDialog}
        onConfirm={handleDelete}
        onClose={cancelDeleteRequest}
        dialogTitle={t('issues:detailedView:comments:deleteComment:title')}
        dialogText={
          <Trans
            i18nKey='issues:detailedView:comments:deleteComment:text'
            values={{
              lineBreak: '<br />',
            }}
          />
        }
      />
    </Container>
  );
};

export default Comments;
