import { gql } from '@apollo/client';
import {
  ISSUE_STATUS_FIELDS,
  ISSUE_TYPE_FIELDS,
  ISSUE_STATUS_CHANGE_HISTORY,
  MEASUREMENT_EVENTS,
  ISSUE_COMMENT,
  ISSUE_FEEDBACK,
} from '../fragments';

const GET_ISSUES = gql`
  ${ISSUE_STATUS_FIELDS}
  ${ISSUE_TYPE_FIELDS}
  query GetIssues($issuesSort: [IssueSortInput!], $issuesFilter: IssueFilterInput, $limit: Int, $offset: Int) {
    issues(order: $issuesSort, where: $issuesFilter, take: $limit, skip: $offset) {
      id
      description
      tenantId
      h3Index
      severityLevel
      issueCategory
      issueDateTime
      issueTypeId
      issueStatusId
      issueStatus {
        ...IssueStatusFields
      }
      issueType {
        ...IssueTypeFields
      }
      measurementEvents {
        severityLevel
      }
    }
  }
`;

const GET_ISSUE_BY_ID = gql`
  ${ISSUE_STATUS_FIELDS}
  ${ISSUE_TYPE_FIELDS}
  ${ISSUE_COMMENT}
  ${ISSUE_STATUS_CHANGE_HISTORY}
  ${MEASUREMENT_EVENTS}
  ${ISSUE_FEEDBACK}
  query GetIssueById($issueId: Uuid!) {
    issue(id: $issueId) {
      id
      description
      tenantId
      h3Index
      severityLevel
      issueTypeId
      issueStatusId
      issueCategory
      issueDateTime
      issueType {
        ...IssueTypeFields
      }
      issueStatus {
        ...IssueStatusFields
      }
      issueComments {
        ...IssueComment
      }
      issueStatusChangeHistories {
        ...IssueStatusChangeHistory
      }
      measurementEvents {
        ...MeasurementEvent
      }
      issueFeedbacks {
        ...IssueFeedback
      }
    }
  }
`;

const GET_ISSUES_FILTERED_BY_ID = gql`
  ${ISSUE_STATUS_FIELDS}
  ${ISSUE_TYPE_FIELDS}
  ${ISSUE_STATUS_CHANGE_HISTORY}
  ${MEASUREMENT_EVENTS}
  query GetIssuesFilteredById($issueFilter: IssueFilterInput) {
    issues(where: $issueFilter) {
      id
      description
      tenantId
      h3Index
      severityLevel
      issueCategory
      issueDateTime
      issueTypeId
      issueStatusId
      issueStatus {
        ...IssueStatusFields
      }
      issueType {
        ...IssueTypeFields
      }
      issueStatusChangeHistories {
        ...IssueStatusChangeHistory
      }
      measurementEvents {
        ...MeasurementEvent
      }
    }
  }
`;

const GET_ISSUES_INFO = gql`
  ${ISSUE_STATUS_FIELDS}
  ${ISSUE_TYPE_FIELDS}
  query GetIssuesInfo {
    issueStatuses {
      ...IssueStatusFields
    }
    issueTypes {
      ...IssueTypeFields
    }
  }
`;

const GET_CAUSES_BY_ISSUE_TYPE = gql`
  query GetCausesByIssueType($issueTypeId: Uuid!) {
    issueCausesByIssueTypeId(issueTypeId: $issueTypeId, order: { issueTypeId: ASC }) {
      id
      issueTypeId
      causeEn
      causeDe
    }
  }
`;

const GET_ISSUE_FEEDBACKS = gql`
  ${ISSUE_FEEDBACK}
  query GetIssueFeedbacks($sort: [IssueFeedbackSortInput!], $filter: IssueFeedbackFilterInput) {
    issueFeedbacks(order: $sort, where: $filter) {
      ...IssueFeedback
    }
  }
`;

export default {
  GET_ISSUES,
  GET_ISSUE_BY_ID,
  GET_ISSUES_FILTERED_BY_ID,
  GET_ISSUES_INFO,
  GET_CAUSES_BY_ISSUE_TYPE,
  GET_ISSUE_FEEDBACKS,
};
