import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from '../../../../../../hooks/redux';
import Filter from '../../../../../../components/Filter';
import { selectIssueCauses, selectIssueFeedbacksFilter } from '../../../../../../store/selectors/issue';
import { TFilterSelect } from '../../../../../../types';
import { setFeedbacksFilterPending } from '../../../../../../store/slices/issues';

/* ------- Components ------- */
const MaintenanceHistoryFilter: React.FC = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const dispatch = useDispatch();
  const { feedbackFilter, isFiltered } = useSelector(selectIssueFeedbacksFilter);
  const causeOptions = useSelector(selectIssueCauses);

  const filterState = Object.assign(
    {},
    ...Object.keys(feedbackFilter).map((filterKey) => ({ [filterKey]: feedbackFilter[filterKey].issueCauseId.in })),
  );

  const filters: TFilterSelect[] = [
    {
      key: 'issueCause',
      label: t('issues:detailedView:maintenanceHistory:cause'),
      options: causeOptions.map((cause) => ({
        ...cause,
        value: cause.id,
        label: language === 'en' ? cause.causeEn : cause.causeDe,
      })),
    },
  ];

  const handleApply = (updatedFilterState: object) => {
    dispatch(setFeedbacksFilterPending({ filter: updatedFilterState }));
  };

  const handleReset = () => {
    dispatch(setFeedbacksFilterPending({ filter: null }));
  };

  return (
    <Filter
      filters={filters}
      filterState={filterState}
      onApply={handleApply}
      onReset={handleReset}
      isFiltered={isFiltered}
    />
  );
};

export default MaintenanceHistoryFilter;
