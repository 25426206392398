import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Avatar, Link, TextField, Typography } from '@mui/material';
import { getIssueFormattedDate } from '../../helpers';
import { TIssueComment } from '../../types';
import { useSelector } from '../../hooks/redux';
import { selectUsers } from '../../store/selectors/user';

const StyledComment = styled('div')`
  display: flex;
  gap: 20px;
  align-items: baseline;
  border-radius: 30px;
  border: 1px solid #d9d9d9;
  padding: 16px;
  max-width: 710px;
  min-width: 500px;
`;

const Mentioned = styled('span')(({ theme }) => ({
  color: theme.palette.green[100],
}));

interface ICommentProps {
  comment: TIssueComment & {
    userName: string;
    avatarLetters: string;
  };
  userIsAuthor?: boolean;
  onUpdate?: (id: string, comment: string) => void;
  onDelete?: (id: string) => void;
  styles?: React.CSSProperties;
}

const Comment: React.FC<ICommentProps> = ({ comment, userIsAuthor, onUpdate, onDelete, styles }) => {
  const { t } = useTranslation();

  const users = useSelector(selectUsers);
  const [commentToBeUpdated, setCommentToBeUpdated] = useState<string>('');
  const [editMode, setEditMode] = useState<boolean>(false);
  const [showMore, setShowMore] = useState<boolean>(false);

  const handleEditMode = () => {
    setEditMode(true);
    setCommentToBeUpdated(comment.comment);
  };

  const tagIds = comment.issueCommentTags?.map((user) => user.userId) || [];
  const tags = users
    ?.filter((user) => tagIds.includes(user.userId))
    .map((user) => user.displayName)
    .join(', ');

  return (
    <StyledComment
      key={comment.id}
      sx={{
        backgroundColor: (theme) => (userIsAuthor ? theme.palette.green[20] : theme.palette.grey[20]),
        alignSelf: userIsAuthor ? 'flex-end' : 'flex-start',
        ...styles,
      }}
    >
      <Avatar
        sx={{
          marginTop: '8px',
          textTransform: 'uppercase',
          backgroundColor: (theme) => (userIsAuthor ? theme.palette.green[100] : theme.palette.blue[80]),
        }}
      >
        {comment.avatarLetters}
      </Avatar>
      <div
        style={{
          width: 'calc(100% - 40px - 16px)',
          alignSelf: 'center',
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline', gap: '32px' }}>
          <Typography sx={{ fontWeight: 500, marginBottom: '4px' }}>{comment.userName}</Typography>
          <div style={{ display: 'flex', gap: '24px' }}>
            {userIsAuthor && !editMode && onDelete && onUpdate && (
              <>
                <Link
                  component='button'
                  variant='subtitle1'
                  underline='none'
                  onClick={() => onDelete && onDelete(comment.id)}
                >
                  {t('common:delete')}
                </Link>
                <Link component='button' variant='subtitle1' underline='none' onClick={handleEditMode}>
                  {t('common:edit')}
                </Link>
              </>
            )}
            {editMode && onUpdate && (
              <>
                <Link component='button' variant='subtitle1' underline='none' onClick={() => setEditMode(false)}>
                  {t('common:cancel')}
                </Link>
                <Link
                  component='button'
                  variant='subtitle1'
                  underline='none'
                  onClick={() => {
                    onUpdate && onUpdate(comment.id, commentToBeUpdated);
                    setEditMode(false);
                  }}
                >
                  {t('common:save')}
                </Link>
              </>
            )}
          </div>
        </div>
        <div style={{ display: 'flex', marginBottom: '20px', gap: '30px', alignItems: 'center' }}>
          <Typography sx={{ fontSize: '14px', minWidth: 'fit-content' }}>
            {comment.modifiedAt ? getIssueFormattedDate(comment.modifiedAt) : getIssueFormattedDate(comment.createdAt)}
          </Typography>
          {comment.issueCommentTags?.length ? (
            <Typography
              sx={{
                fontSize: '12px',
                width: 'fit-content',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {t('issues:detailedView:comments:mentioned')}:&nbsp;
              <Mentioned>{tags}</Mentioned>
            </Typography>
          ) : null}
        </div>

        {editMode ? (
          <TextField
            fullWidth
            multiline
            maxRows={5}
            value={commentToBeUpdated}
            onChange={(e) => setCommentToBeUpdated(e.target.value)}
            sx={{ '& .MuiInputBase-root': { fontSize: '14px', backgroundColor: (theme) => theme.palette.white.main } }}
          />
        ) : (
          <Typography sx={{ fontSize: '14px', wordBreak: 'break-word' }}>
            {comment.comment.length > 300 && !showMore ? comment.comment.slice(0, 300).concat('...') : comment.comment}
            {comment.comment.length > 300 && (
              <Link
                component='button'
                underline='none'
                onClick={() => setShowMore(!showMore)}
                sx={{ fontSize: '14px', marginLeft: '8px' }}
              >
                {showMore ? t('common:showLess') : t('common:showMore')}
              </Link>
            )}
          </Typography>
        )}
      </div>
    </StyledComment>
  );
};

export default Comment;
