import React from 'react';
import Layout from '../../components/Layout';
import { Box, FormControl, FormGroup, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from '../../hooks/redux';
import { selectUserId, selectUsers } from '../../store/selectors/user';

/* ------- Styles ------- */
const StyleFormControl = styled(FormControl)({
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  margin: '8px 0',
});

const StyledFormLabel = styled('div')({
  width: '100px',
});

const Label = styled(Typography)({
  color: 'black',
  fontWeight: '700',
  fontSize: '16px',
});

const Text = styled(Typography)({
  fontWeight: '400',
  fontSize: '14px',
});

/* ------- Components ------- */
const Profile = () => {
  const { t } = useTranslation();
  const id = useSelector(selectUserId);
  const users = useSelector(selectUsers);

  const profile = users?.find((user) => user.userId === id);

  return (
    <Layout>
      <div style={{ padding: '32px' }}>
        <h3 style={{ fontSize: '24px' }}>{t('user:profile:title')}</h3>
        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
          {profile ? (
            <FormGroup>
              <StyleFormControl size='small' focused={false}>
                <StyledFormLabel>
                  <Label>{t('user:profile:name')}</Label>
                </StyledFormLabel>
                <Text>{profile.displayName}</Text>
              </StyleFormControl>
              <StyleFormControl size='small' focused={false}>
                <StyledFormLabel>
                  <Label>{t('user:profile:email')}</Label>
                </StyledFormLabel>
                <Text>{profile.email}</Text>
              </StyleFormControl>
              <StyleFormControl size='small' focused={false}>
                <StyledFormLabel>
                  <Label>{t('user:profile:role')}</Label>
                </StyledFormLabel>
                <Text>{t(`administration:roles:${profile.roles[0].name}`)}</Text>
              </StyleFormControl>
            </FormGroup>
          ) : null}
        </Box>
      </div>
    </Layout>
  );
};

export default Profile;
