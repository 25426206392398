import { createSelector } from 'reselect';
import { RootState } from '..';

const issues = (state: RootState) => state.issue.issues;
const issuesList = (state: RootState) => state.issue.issues.list;
const issueTypes = (state: RootState) => state.issue.issueTypes;
const issueStatuses = (state: RootState) => state.issue.issueStatuses;
const issueSeverities = (state: RootState) => state.issue.issueSeverities;
const issueDetails = (state: RootState) => state.issue.issueDetails;
const issueCauses = (state: RootState) => state.issue.issueCauses;
const issueFeedbacksSort = (state: RootState) => state.issue.issueFeedbacksSort;
const issueFeedbacksFilter = (state: RootState) => state.issue.issueFeedbacksFilter;

export const selectIssuesList = createSelector(issuesList, (issuesList) => issuesList);
export const selectIssueTypes = createSelector(issueTypes, (issueTypes) => issueTypes);
export const selectIssueStatuses = createSelector(issueStatuses, (issueStatuses) => issueStatuses);
export const selectIssueSeverities = createSelector(issueSeverities, (issueSeverities) => issueSeverities);
export const selectIssuesListAttributes = createSelector(
  issues,
  ({ issuesSort, issuesFilter, limit, offset, isFiltered, lastUpdated }) => {
    return { issuesSort, issuesFilter, limit, offset, isFiltered, lastUpdated };
  },
);
export const selectIssueDetails = createSelector(issueDetails, (issueDetails) => issueDetails);
export const selectIssueCauses = createSelector(issueCauses, (issueCauses) => issueCauses);
export const selectIssueFeedbacksSort = createSelector(issueFeedbacksSort, (issueFeedbacksSort) => issueFeedbacksSort);
export const selectIssueFeedbacksFilter = createSelector(issueFeedbacksFilter, ({ feedbackFilter, isFiltered }) => {
  return { feedbackFilter, isFiltered };
});
export const selectIssueFeedbacks = createSelector(issueDetails, (issueDetails) => issueDetails?.issueFeedbacks);
