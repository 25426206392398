import React, { useState, useEffect } from 'react';
import { alpha, styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogContent,
  FormLabel,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Collapse,
} from '@mui/material';
import { FilterList, ExpandLess, ExpandMore } from '@mui/icons-material';
import CloseButton from '../Buttons/CloseButton';
import { TFilterSelect } from '../../types';
import { stringArrayEquals } from '../../helpers';
import ExtraWidthButton from '../Buttons/ExtraWidthButton';

/* ------- Styles ------- */
const FilterButtonContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  cursor: 'pointer',
});

const DialogActions = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '32px',
});

const FilterLabel = styled(FormLabel)(({ theme }) => ({
  color: 'black',
  fontWeight: '500',
  background: alpha(theme.palette.blue[20], 0.35),
  borderTop: `1px solid ${theme.palette.blue[20]}`,
  borderBottom: `1px solid ${theme.palette.blue[20]}`,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '51px',
  padding: '0 32px',
  cursor: 'pointer',
}));

const SingleFilterLabel = styled(FormLabel)(({ theme }) => ({
  color: theme.palette.black[100],
  fontWeight: '500',
  padding: '15px 32px',
}));

const DialogHeader = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '32px 32px 24px',
});

const DialogContainer = styled(DialogContent)({
  width: '472px',
  padding: 0,
  maxHeight: '800px',
  minHeight: '366px',
});

const Title = styled('h3')({
  margin: '0',
  fontWeight: 700,
});

const CloseButtonContainer = styled('div')({
  display: 'flex',
  justifyContent: 'end',
  position: 'absolute',
  top: '3px',
  right: '10px',
});

/* ------- Types ------- */
interface IFilterProps {
  filters: TFilterSelect[];
  filterState: { [key: string]: string[] };
  onApply: (entityFilter: object) => void;
  onReset: () => void;
  isFiltered: boolean;
}

/* ------- Components ------- */
const Filter: React.FC<IFilterProps> = ({ filters, filterState, onApply, onReset, isFiltered }) => {
  const { t } = useTranslation();
  const [filterSetupIsOpen, setFilterSetupIsOpen] = useState<boolean>(false);
  const [entityFilter, setEntityFilter] = useState<{ [key: string]: string[] }>(filterState);
  const [expandedFilters, setExpandedFilters] = useState<string[]>(filters.map((f) => f.key));

  useEffect(() => {
    setEntityFilter(filterState);
  }, [filterState]);

  const handleFilterExpand = (key: string) => {
    const isExpanded = expandedFilters.includes(key);

    if (isExpanded) {
      setExpandedFilters(expandedFilters.filter((el) => el !== key));
    } else {
      setExpandedFilters(expandedFilters.concat([key]));
    }
  };

  const handleOptionCheck = (filterKey: string, optionKey: string) => (_e, isChecked: boolean) => {
    const newFilterObj = {
      ...entityFilter,
      [filterKey]: isChecked
        ? entityFilter[filterKey].concat([optionKey])
        : entityFilter[filterKey].filter((opt) => opt !== optionKey),
    };

    setEntityFilter(newFilterObj);
  };

  const handleCloseDialog = (clearSelected: boolean = true) => {
    setFilterSetupIsOpen(false);
    clearSelected && setEntityFilter(filterState);
  };

  const handleSubmit = () => {
    onApply(entityFilter);
    handleCloseDialog(false);
  };

  const handleReset = () => {
    onReset();
  };

  const flattenValues = (obj: { [key: string]: string[] }) => Object.values(obj).flat();

  const mapOptions = (options: TFilterSelect['options'], filterKey: string) =>
    options.map((opt) => {
      const value = opt.value || opt.description;
      const label = opt.label || opt.description;

      return (
        <FormControlLabel
          key={opt.id}
          control={
            <Checkbox
              checked={entityFilter[filterKey]?.includes(value)}
              onChange={handleOptionCheck(filterKey, value)}
              name={value}
              data-testid={`filter-option-${opt.id}`}
            />
          }
          label={label}
        />
      );
    });

  const multipleFiltersLayout = filters.map((filter) => {
    const isOpen = expandedFilters.includes(filter.key);

    return (
      <FormControl
        sx={{ width: '100%' }}
        key={filter.key}
        component='fieldset'
        variant='standard'
        data-testid='filter-multiple-view'
      >
        <FilterLabel
          onClick={() => handleFilterExpand(filter.key)}
          data-testid={`filter-${filter.key}-expand`}
          focused={false}
        >
          <span>{filter.label}</span>
          {isOpen ? <ExpandLess /> : <ExpandMore />}
        </FilterLabel>

        <Collapse in={isOpen} timeout='auto' unmountOnExit>
          <FormGroup sx={{ padding: '10px 32px' }} data-testid={`${filter.key}-options-container`}>
            {mapOptions(filter.options, filter.key)}
          </FormGroup>
        </Collapse>
      </FormControl>
    );
  });

  const singleFilterLayout = filters.map((filter) => (
    <FormControl
      sx={{ width: '100%' }}
      key={filter.key}
      component='fieldset'
      variant='standard'
      data-testid='filter-single-view'
    >
      <SingleFilterLabel focused={false}>{filter.label}</SingleFilterLabel>
      <FormGroup sx={{ padding: '0 24px' }}>{mapOptions(filter.options, filter.key)}</FormGroup>
    </FormControl>
  ));

  const isResetDisabled = !flattenValues(entityFilter).length;
  const isSubmitDisabled = stringArrayEquals(flattenValues(entityFilter), flattenValues(filterState));

  return (
    <>
      <FilterButtonContainer onClick={() => setFilterSetupIsOpen(true)} data-testid='filter-button'>
        <FilterList color={isFiltered ? 'primary' : 'inherit'} />
        <span>{t('common:list:filter')}</span>
      </FilterButtonContainer>
      <Dialog open={filterSetupIsOpen} onClose={() => handleCloseDialog()}>
        <DialogHeader>
          <Title>{t('common:filter:title')}</Title>
          <CloseButtonContainer>
            <CloseButton onClick={handleCloseDialog} color={(theme) => theme.palette.black[60]} />
          </CloseButtonContainer>
        </DialogHeader>

        <DialogContainer data-testid='filter-content'>
          {filters.length > 1 ? multipleFiltersLayout : singleFilterLayout}
        </DialogContainer>
        <DialogActions>
          <ExtraWidthButton
            variant='outlined'
            onClick={handleReset}
            disabled={isResetDisabled}
            data-testid='filter-cancel'
          >
            {t('common:filter:reset')}
          </ExtraWidthButton>
          <ExtraWidthButton
            variant='contained'
            onClick={handleSubmit}
            data-testid='filter-submit'
            disabled={isSubmitDisabled}
          >
            {t('common:filter:apply')}
          </ExtraWidthButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Filter;
