import React from 'react';
import Alert, { AlertColor } from '@mui/material/Alert';

/* ------- Styles ------- */
const linkCopiedStyles = {
  position: 'absolute',
  width: 'fit-content',
  padding: '8px 32px',
  top: '7%',
  left: '50%',
  transform: 'translateX(-50%)',
  zIndex: 10000,
};

/* ------- Types ------- */
interface ICustomAlert {
  show: boolean;
  message: string;
  severity: AlertColor;
}

/* ------- Components ------- */
const CustomAlert: React.FC<ICustomAlert> = ({ show, message, severity }) => (
  <>
    {show && (
      <Alert severity={severity} sx={linkCopiedStyles} data-testid='alert'>
        {message}
      </Alert>
    )}
  </>
);

export default CustomAlert;
