import React, { useEffect, useState } from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useAccount, useMsal } from '@azure/msal-react';
import { useDispatch, useSelector } from '../../hooks/redux';
import { fetchUserRolesPending, fetchUsersPending, setAuthUser } from '../../store/slices/user';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import { routes } from '../../constants';
import Unauthenticated from '../Unauthenticated';
import useAccessControl from '../../hooks/useAccessControl';
import { selectUserRoles, selectUsers } from '../../store/selectors/user';
import { loginRequest } from '../../services/auth/auth.config';

/* ------- Types ------- */
interface ILayout {
  children: React.ReactNode;
  sidebarOnChange?: () => void;
}

/* ------- Components ------- */
const Layout: React.FC<ILayout> = ({ children, sidebarOnChange }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const account = useAccount();
  const { instance } = useMsal();
  const { userAccess } = useAccessControl();
  const users = useSelector(selectUsers);
  const roles = useSelector(selectUserRoles);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [activeSidebarItem, setActiveSidebarItem] = useState<string | undefined>(
    Object.values(routes).find((route) => pathname === route),
  );

  const handleLogin = async () => {
    await instance.loginRedirect(loginRequest);
  };

  useEffect(() => {
    if (
      pathname.includes(routes.ADMIN) &&
      (userAccess.user_management_allowed || userAccess.asset_management_allowed)
    ) {
      !roles && dispatch(fetchUserRolesPending());
    }

    !users && dispatch(fetchUsersPending());
  }, [dispatch, pathname, userAccess]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (account) {
      dispatch(
        setAuthUser({
          tenantId: account.tenantId,
          organizationId: (account?.idTokenClaims?.extension_organizationId as string) || account.tenantId,
          organizationPermissions: (account?.idTokenClaims?.extension_permissions as string)?.split(' ') || '',
          localAccountId: account.localAccountId,
          name: account.name || 'John Doe',
        }),
      );
    }
  }, [account, dispatch]);

  useEffect(() => {
    Object.values(routes).forEach((route) => {
      if (pathname.includes(route)) {
        setActiveSidebarItem(route);
      }
    });
  }, [pathname]);

  const sidebarItems = [
    [
      {
        id: routes.TRACK,
        name: t('common:sidebar:views:track'),
        icon: <img src='/icons/app/infrastructureview.svg' alt={t('common:sidebar:views:track')} />,
        onClick: () => navigate(routes.TRACK),
        permitted: userAccess.infrastructure_allowed,
      },
      {
        id: routes.TRAIN,
        name: t('common:sidebar:views:train'),
        icon: <img src='/icons/app/trainview.svg' alt={t('common:sidebar:views:train')} />,
        onClick: () => navigate(routes.TRAIN),
        permitted: userAccess.assets_allowed,
        disabled: true,
      },
    ],
    [
      {
        id: routes.EXPORT_HISTORY,
        name: t('common:sidebar:pages:exportHistory'),
        icon: <img src='/icons/app/exporthistory.svg' alt={t('common:sidebar:pages:exportHistory')} />,
        onClick: () => navigate(routes.EXPORT_HISTORY),
        permitted: userAccess.infrastructure_allowed || userAccess.assets_allowed,
        disabled: true,
      },
      {
        id: routes.ADMIN,
        name: t('common:sidebar:pages:administration'),
        icon: <img src='/icons/app/administration.svg' alt={t('common:sidebar:pages:administration')} />,
        onClick: () => navigate(routes.ADMIN),
        permitted: userAccess.user_management_allowed || userAccess.asset_management_allowed,
      },
    ],
  ];

  return (
    <>
      <AuthenticatedTemplate>
        <Grid container>
          <Grid xs={12}>
            <Header />
          </Grid>
          <Grid xs={12} sx={{ display: 'flex', flexWrap: 'nowrap' }}>
            <Sidebar
              open={sidebarOpen}
              title={t('common:sidebar:title')}
              activeItemId={activeSidebarItem}
              groupedItems={sidebarItems}
              onChange={() => {
                setSidebarOpen(!sidebarOpen);
                sidebarOnChange && sidebarOnChange();
              }}
            />
            {children}
          </Grid>
        </Grid>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Unauthenticated handleClick={handleLogin} />
      </UnauthenticatedTemplate>
    </>
  );
};

export default Layout;
