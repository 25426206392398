import React from 'react';
import { useSelector, useDispatch } from '../../../../../hooks/redux';
import Filter from '../../../../../components/Filter';
import {
  selectIssueSeverities,
  selectIssueStatuses,
  selectIssueTypes,
  selectIssuesListAttributes,
} from '../../../../../store/selectors/issue';
import { TFilterSelect } from '../../../../../types';
import { setIssueFilterPending } from '../../../../../store/slices/issues';

/* ------- Components ------- */
const IssueListFilter: React.FC = () => {
  const dispatch = useDispatch();
  const { isFiltered, issuesFilter } = useSelector(selectIssuesListAttributes);
  const typeOptions = useSelector(selectIssueTypes);
  const statusOptions = useSelector(selectIssueStatuses);
  const severityOptions = useSelector(selectIssueSeverities);

  const filterState = Object.assign(
    {},
    ...Object.keys(issuesFilter).map((filterKey) => ({ [filterKey]: issuesFilter[filterKey].description.in })),
  );

  const filters: TFilterSelect[] = [
    {
      key: 'issueType',
      label: 'Type',
      options: typeOptions,
    },
    {
      key: 'severityLevel',
      label: 'Severity',
      options: severityOptions,
    },
    {
      key: 'issueStatus',
      label: 'Status',
      options: statusOptions,
    },
  ];

  const handleApply = (updatedFilterState: object) => {
    dispatch(setIssueFilterPending({ filter: updatedFilterState }));
  };

  const handleReset = () => {
    dispatch(setIssueFilterPending({ filter: null }));
  };

  return (
    <Filter
      filters={filters}
      filterState={filterState}
      onApply={handleApply}
      onReset={handleReset}
      isFiltered={isFiltered}
    />
  );
};

export default IssueListFilter;
