export enum ISSUE_CATEGORY {
  TRACK = 'TRACK_ISSUE',
  ASSET = 'ASSET_ISSUE',
}

export enum SORT_ORDER {
  ASCENDING = 'ASC',
  DESCENDING = 'DESC',
}

export enum SEVERITY_LEVEL {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
}

export enum USER_ROLE {
  ADMINISTRATOR = 'OrganizationAdministrator',
  TRAIN_USER = 'TrainUser',
  INFRASTRUCTURE_USER = 'InfrastructureUser',
  TRAIN_INFRASTRUCTURE_USER = 'TrainAndInfrastructureUser',
  DEVICE_ADMINISTRATOR = 'DeviceAdministrator',
}

export enum ISSUE_STATUS {
  READ = 'Read',
  UNREAD = 'Unread',
  PROCESSED = 'Processed',
  IGNORED = 'Ignored',
  EXPORTED = 'Exported',
  MAINTENANCE_COMPLETED = 'Maintenance Completed',
}

/*-----------------Common Types-----------------*/
export type TSortOrder = SORT_ORDER.ASCENDING | SORT_ORDER.DESCENDING;

export type TChartData = {
  ts: string[];
  acc_x?: number[];
  acc_y?: number[];
  acc_z?: number[];
  norm?: number[];
};

/*-----------------Issue Types-----------------*/
export type TSeverityLevel = SEVERITY_LEVEL.LOW | SEVERITY_LEVEL.MEDIUM | SEVERITY_LEVEL.HIGH;
export type TSeverityInfo = {
  id: TSeverityLevel;
  description: TSeverityLevel;
  label?: string;
};

export type TIssueCategory = ISSUE_CATEGORY.TRACK | ISSUE_CATEGORY.ASSET;

export type TIssueType = {
  id: string;
  description: 'HardSpot' | 'Height' | 'Stagger' | 'CarbonStripWear' | 'DynamicUpliftForce' | 'Arcing' | 'Geometry';
  isSystemOwned?: boolean;
  __typename: 'IssueType';
};

export type TIssueStatus = {
  id: string;
  description:
    | ISSUE_STATUS.UNREAD
    | ISSUE_STATUS.READ
    | ISSUE_STATUS.PROCESSED
    | ISSUE_STATUS.IGNORED
    | ISSUE_STATUS.EXPORTED
    | ISSUE_STATUS.MAINTENANCE_COMPLETED;
  __typename: 'IssueStatus';
};

export type TIssueStatusChange = {
  id: string;
  issueId: string;
  fromStatusId: string;
  toStatusId: string;
  changeDateTime: string;
  description: null;
  issue: {
    id: string;
    __typename: string;
  };
  fromStatus: {
    id: string;
    description: string;
    __typename: string;
  };
  toStatus: {
    id: string;
    description: string;
    __typename: string;
  };
  __typename: 'IssueStatusChangeHistory';
};

export type TMeasurementEvent = {
  id: string;
  issueId: string;
  createdAt: string;
  measurementEventDateTime: string;
  severity: number;
  severityLevel: TSeverityLevel;
  eventData?: {
    rawData: string;
    __typename: string;
  }[];
  __typename: string;
};

export type TIssueComment = {
  id: string;
  issueId: string;
  comment: string;
  createdAt: string;
  createdBy: string;
  modifiedAt: string;
  issueCommentTags: { userId: string }[];
};

export type TIssueFeedback = {
  id: string;
  issueId: string;
  createdAt: string;
  createdBy: string;
  modifiedAt: string | null;
  issueCauseId: string;
  issueCause: TIssueCause;
  otherCause: string;
  comment: string;
  severityLevel: TSeverityLevel;
  ignoreIssue: boolean;
  __typename: string;
};

export type TFeedbackSort = {
  createdAt?: TSortOrder;
  severityLevel?: TSortOrder;
};

export type TFeedbackFilter = {
  issueCause?: {
    issueCauseId: {
      in: string[];
    };
  };
};

export type TIssue = {
  id: string;
  description: string;
  tenantId?: string;
  h3Index: string;
  severity: number | null;
  severityLevel: TSeverityLevel | null;
  line?: string;
  issueTypeId: string;
  issueType: TIssueType;
  issueStatusId: string;
  issueStatus: TIssueStatus;
  issueDateTime: string;
  issueComments?: TIssueComment[];
  issueCategory: TIssueCategory;
  issueStatusChangeHistories?: TIssueStatusChange[];
  measurementEvents: TMeasurementEvent[];
  issueFeedbacks: TIssueFeedback[];
  __typename: 'Issue';
};

export type TIssueCause = {
  id: string;
  issueTypeId: string | null;
  causeEn: string;
  causeDe: string;
  issueType?: TIssueType;
  issueFeedbacks?: object[];
  __typename: string;
};

export type TIssueFilter = {
  issueType?: {
    description: {
      in: string[];
    };
  };
  severityLevel?: {
    description: {
      in: string[];
    };
  };
  issueStatus?: {
    description: {
      in: string[];
    };
  };
};

export type TIssueSort = {
  issueDateTime?: TSortOrder;
  severity?: TSortOrder;
};

export type TFilterSelect = {
  key: string;
  label: string;
  options: TIssueType[] | TIssueStatus[] | TSeverityInfo[] | TIssueCause[];
};

/*-----------------Export Types-----------------*/
export type TExportIssue = Pick<
  TIssue,
  '__typename' | 'id' | 'description' | 'issueCategory' | 'issueDateTime' | 'issueType' | 'line'
>;

export type TExportCollection = {
  __typename: string;
  id: string;
  createdBy: string;
  createdAt: string;
  isPdf: boolean;
  isDoc: boolean;
  exportCollectionBridgeIssues: {
    issue: TExportIssue;
    __typename: 'ExportCollectionBridgeIssue';
  }[];
};

/*-----------------User Types-----------------*/
export type TUser = {
  id: string;
  tenantId: string;
  userName: string;
  authenticationProvider: string;
  email: string;
  language: string;
  userDescription: string;
  lastLogin: string;
  createdAt: string;
  modifiedAt: string;
  isDeleted: boolean;
  roles: TUserRole[];
  tenant: {
    id: string;
    tenantName: string;
  };
};

export type TUserRole = {
  roleId: string;
  name: string;
  organization: {
    organizationId: string;
    name: string;
    domain: string | null;
  };
  permissions: string[];
  i18nKey?: string;
};

export type TCreateUser = {
  firstName: string;
  lastName: string;
  displayName: string;
  emailAddress: string;
  roleIds: string[];
};

export type TApiUser = {
  userId: string;
  firstName: string;
  lastName: string;
  displayName: string;
  emailAddress: string;
  roles: TUserRole[];
  organizationId: {
    organizationId: string;
    name: string;
    domain: string | null;
  };
};

export type TAuthUser = {
  tenantId: string;
  organizationId: string;
  organizationPermissions: string[];
  localAccountId: string;
  name: string;
};
