import { createTheme } from '@mui/material/styles';

const palette = {
  primary: {
    main: '#9AA302',
  },
  secondary: {
    main: '#00204E',
  },
  success: {
    main: '#E3E5C4',
  },
  warning: {
    main: '#F3E2CD',
  },
  error: {
    main: '#A6192E',
  },
  info: {
    main: '#C4DAEE',
  },
  action: {
    main: '#006DD0',
  },
  green: {
    100: '#9AA302',
    80: '#ACB333',
    60: '#BEC463',
    40: '#D1D494',
    20: '#E3E5C4',
  },
  blue: {
    100: '#00204E',
    80: '#334D71',
    60: '#667995',
    40: '#99A6B8',
    20: '#CCD2DC',
  },
  red: {
    100: '#A6192E',
    80: '#B84758',
    60: '#CA7582',
    40: '#DBA3AB',
    20: '#EDD1D5',
  },
  black: {
    100: '#000000',
    80: '#333333',
    60: '#666666',
    40: '#999999',
    20: '#cccccc',
  },
  white: {
    main: '#ffffff',
  },
  grey: {
    100: '#868789',
    80: '#9E9FA1',
    60: '#B6B7B8',
    40: '#CFCFD0',
    20: '#E7E7E7',
  },
  others: {
    orange: {
      100: '#EC942C',
      20: '#F3E2CD',
    },
    actionBlue: {
      100: '#006DD0',
      72: '#4A96DC',
      20: '#C4DAEE',
    },
    blue: {
      9: '#DFE2E6',
    },
  },
};

const theme = {
  palette,
  typography: {
    fontFamily: ['Roboto', 'sans-serif'].join(','),
    fontSize: 16,
    htmlFontSize: 18,
    h1: {
      fontWeight: 300,
      fontSize: '96px',
    },
    h2: {
      fontWeight: 300,
      fontSize: '60px',
    },
    h3: {
      fontWeight: 400,
      fontSize: '48px',
    },
    h4: {
      fontWeight: 400,
      fontSize: '34px',
    },
    h5: {
      fontWeight: 400,
      fontSize: '24px',
    },
    h6: {
      fontWeight: 500,
      fontSize: '20px',
    },
    subtitle1: {
      textTransform: 'uppercase',
      fontWeight: 500,
    },
  },
  severity: {
    LOW: palette.primary.main,
    MEDIUM: '#FFB803',
    HIGH: '#FF0000',
  },
  divider: {
    borderColor: palette.grey[20],
    borderStyle: 'solid',
    borderWidth: '1px',
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          color: palette.others.actionBlue[100],
          textDecorationColor: palette.others.actionBlue[100],
          fontSize: '16px',
          fontWeight: 500,

          '&[disabled]': {
            color: palette.black[40],
            pointerEvents: 'none',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          paddingLeft: '24px',
          paddingRight: '24px',
        },
        contained: {
          color: palette.white.main,

          '&:hover': {
            backgroundColor: palette.green[60],
          },

          '&[disabled]': {
            backgroundColor: palette.grey[20],
            color: palette.black[60],
          },
        },
        outlined: {
          color: palette.green[100],

          '&:hover': {
            color: palette.green[60],
            borderColor: palette.green[60],
          },

          '&[disabled]': {
            backgroundColor: palette.white.main,
            borderColor: palette.grey[20],
            color: palette.black[60],
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: palette.green[100],
          color: palette.white.main,
          fontSize: '14px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '5px',
          borderColor: palette.black[40],
          fontSize: '14px',

          '&:hover': {
            borderColor: palette.green[80],
          },

          '&.Mui-focused': {
            borderColor: palette.black[100],
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '16px',
          fontWeight: 500,
          color: palette.black[100],
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: palette.black[80],
          backgroundColor: palette.grey[20],
          fontSize: '10px',
          fontWeight: 500,

          '& .MuiTooltip-arrow': {
            color: palette.grey[20],
          },
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          '& .MuiSnackbarContent-root': {
            color: palette.black[100],
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          '&.MuiAlert-standardSuccess': {
            backgroundColor: palette.success.main,
            color: palette.black[100],
          },
          '&.MuiAlert-standardSuccess .MuiAlert-icon': {
            color: palette.green[100],
          },

          '&.MuiAlert-standardWarning': {
            backgroundColor: palette.warning.main,
            color: palette.black[100],
          },
          '&.MuiAlert-standardWarning .MuiAlert-icon': {
            color: palette.others.orange[100],
          },

          '&.MuiAlert-standardError': {
            backgroundColor: palette.red[20],
            color: palette.black[100],
          },
          '&.MuiAlert-standardError .MuiAlert-icon': {
            color: palette.error.main,
          },

          '&.MuiAlert-standardInfo': {
            backgroundColor: palette.info.main,
            color: palette.black[100],
          },
          '&.MuiAlert-standardInfo .MuiAlert-icon': {
            color: palette.others.actionBlue[100],
          },
        },
      },
    },
  },
} as const;

export default createTheme(theme);
