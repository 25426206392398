import React, { ReactNode } from 'react';
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { v4 as uuidv4 } from 'uuid';
import { getThemedBorder } from '../../helpers';

/* ------- Styles ------- */
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '12px',
  borderTop: getThemedBorder(theme),
  borderBottom: 'unset',
}));

/* ------- Types ------- */
export type TColumn = {
  id: string;
  label: string;
  width?: number;
  align?: 'left' | 'right' | 'center';
};

type TDataValue = string | number | ReactNode | { [key: string]: TDataValue }[] | null;

export type TData = {
  [key: string]: TDataValue;
  groupedItems?: { [key: string]: TDataValue }[];
};

interface ICollapsibleTable {
  columns: readonly TColumn[];
  rows: readonly TData[] | undefined;
  collapsible?: boolean;
  height?: number;
  expandedRows?: string[];
  onExpandToggle?: (id: string) => void;
}

interface IRow {
  row: TData;
  isCollapsibleTable: boolean | undefined;
  expandedRows?: string[];
  onExpandToggle?: (id: string) => void;
}

/* ------- Components ------- */
const Row: React.FC<IRow> = ({ row, isCollapsibleTable, expandedRows, onExpandToggle }) => {
  const keys = Object.keys(row).filter((key) => key !== 'groupedItems' && key !== 'id');

  return (
    <>
      <TableRow selected={expandedRows?.includes(row.id as string)} sx={{ '& > *': { borderBottom: 'unset' } }}>
        {isCollapsibleTable && row.groupedItems && (
          <StyledTableCell>
            <IconButton
              sx={{ padding: 0 }}
              size='small'
              onClick={() => onExpandToggle && onExpandToggle(row.id as string)}
            >
              {expandedRows?.includes(row.id as string) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </StyledTableCell>
        )}
        {isCollapsibleTable && !row.groupedItems && <StyledTableCell />}
        {keys.map((key) => (
          <StyledTableCell key={uuidv4()}>{row[key] as string}</StyledTableCell>
        ))}
      </TableRow>
      {row.groupedItems &&
        row.groupedItems.map((groupedItem) => (
          <TableRow
            key={uuidv4()}
            sx={{ visibility: expandedRows?.includes(row.id as string) ? 'visible' : 'collapse' }}
          >
            <StyledTableCell sx={{ borderTop: 'unset' }} />
            {keys.map((key) => (
              <StyledTableCell sx={{ borderTop: 'unset' }} key={uuidv4()}>
                {groupedItem[key] as string}
              </StyledTableCell>
            ))}
          </TableRow>
        ))}
    </>
  );
};

const CollapsibleTable: React.FC<ICollapsibleTable> = ({
  columns,
  rows,
  collapsible = true,
  height = 'auto',
  expandedRows,
  onExpandToggle,
}) => {
  // check if any object in rows has groupedItems
  const isCollapsibleTable = collapsible && rows?.some((row) => row.groupedItems);

  return (
    <TableContainer component={Paper} sx={{ maxHeight: height }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {isCollapsibleTable && <StyledTableCell sx={{ boxShadow: '2px 4px 4px 0px rgba(0, 0, 0, 0.05)' }} />}
            {columns.map((column) => (
              <StyledTableCell
                key={column.id}
                align={column.align}
                sx={{ width: column.width, fontWeight: 700, boxShadow: '2px 4px 4px 0px rgba(0, 0, 0, 0.05)' }}
              >
                {column.label}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody data-testid='table-body'>
          {rows?.map((row) => (
            <Row
              key={uuidv4()}
              row={row}
              isCollapsibleTable={isCollapsibleTable}
              expandedRows={expandedRows}
              onExpandToggle={onExpandToggle}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CollapsibleTable;
