import { gql } from '@apollo/client';
import { ISSUE_COMMENT } from '../fragments';

const CREATE_ISSUE_TYPES = gql`
  mutation CreateTypes($issueTypes: [IssueTypeCreateType!]!) {
    createIssueTypes(issueTypes: $issueTypes) {
      description
    }
  }
`;

const UPDATE_ISSUES = gql`
  mutation UpdateIssues($issues: [IssueUpdateType!]!) {
    updateIssues(issues: $issues) {
      id
      tenantId
      issueTypeId
      issueStatusId
      issueDateTime
      description
    }
  }
`;

const CREATE_ISSUE_COMMENTS = gql`
  ${ISSUE_COMMENT}
  mutation CreateIssueComments($issueComments: [IssueCommentCreateType!]!) {
    createIssueComments(issueComments: $issueComments) {
      ...IssueComment
    }
  }
`;

const UPDATE_ISSUE_COMMENTS = gql`
  ${ISSUE_COMMENT}
  mutation UpdateIssueComments($issueComments: [IssueCommentUpdateType!]!) {
    updateIssueComments(issueComments: $issueComments) {
      ...IssueComment
    }
  }
`;

const DELETE_ISSUE_COMMENTS = gql`
  mutation DeleteIssueComments($ids: [Uuid!]!) {
    deleteIssueComments(ids: $ids) {
      id
    }
  }
`;

const CREATE_ISSUE_FEEDBACKS = gql`
  mutation CreateIssueFeedbacks($issueFeedbacks: [IssueFeedbackCreateType!]!) {
    createIssueFeedbacks(issueFeedbacks: $issueFeedbacks) {
      id
      issueId
      createdBy
      createdAt
    }
  }
`;

const UPDATE_ISSUE_FEEDBACKS = gql`
  mutation UpdateIssueFeedbacks($issueFeedbacks: [IssueFeedbackUpdateType!]!) {
    updateIssueFeedbacks(issueFeedbacks: $issueFeedbacks) {
      id
    }
  }
`;

export default {
  CREATE_ISSUE_TYPES,
  UPDATE_ISSUES,
  CREATE_ISSUE_COMMENTS,
  UPDATE_ISSUE_COMMENTS,
  DELETE_ISSUE_COMMENTS,
  CREATE_ISSUE_FEEDBACKS,
  UPDATE_ISSUE_FEEDBACKS,
};
