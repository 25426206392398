import { takeLatest, put, call, select } from 'redux-saga/effects';
import { graphqlClient, queries, mutations } from '../services/graphqlClient';
import {
  addToExportCartFailed,
  addToExportCartPending,
  addToExportCartSuccess,
  createExportCollectionFailed,
  createExportCollectionPending,
  createExportCollectionSuccess,
  fetchExportCartFailed,
  fetchExportCartPending,
  fetchExportCartSuccess,
  fetchExportHistoryFailed,
  fetchExportHistoryPending,
  fetchExportHistorySuccess,
  removeFromExportCartFailed,
  removeFromExportCartPending,
  removeFromExportCartSuccess,
} from '../store/slices/export';
import { selectExportHistory } from '../store/selectors/export';

function* fetchExportCartSaga() {
  try {
    const { data } = yield call(graphqlClient.query, {
      query: queries.exports.GET_EXPORT_CART,
    });

    yield put(
      fetchExportCartSuccess({
        cart: data.exportCarts.map((item) => item.issue),
      }),
    );
  } catch (error) {
    yield put(
      fetchExportCartFailed({
        error,
      }),
    );
  }
}

function* fetchExportHistorySaga() {
  try {
    const { exportsSort } = yield select(selectExportHistory);
    const { data } = yield call(graphqlClient.query, {
      query: queries.exports.GET_EXPORT_COLLECTIONS,
      variables: {
        exportsSort,
      },
    });

    yield put(
      fetchExportHistorySuccess({
        collections: data.exportCollections,
      }),
    );
  } catch (error) {
    yield put(
      fetchExportHistoryFailed({
        error,
      }),
    );
  }
}

function* addToExportCartSaga({ payload: { issueId } }) {
  try {
    const { data } = yield call(graphqlClient.mutate, {
      mutation: mutations.exports.ADD_TO_EXPORT_CART,
      variables: {
        issueIds: [issueId],
      },
    });

    yield put(
      addToExportCartSuccess({
        cart: data.createExportCarts.map((item) => item.issue),
      }),
    );
  } catch (error) {
    yield put(
      addToExportCartFailed({
        error,
      }),
    );
  }
}

function* removeFromExportCartSaga({ payload: { issueIds } }) {
  try {
    const { data } = yield call(graphqlClient.mutate, {
      mutation: mutations.exports.REMOVE_FROM_EXPORT_CART,
      variables: {
        issueIds,
      },
    });

    yield put(
      removeFromExportCartSuccess({
        cart: data.deleteExportCarts,
      }),
    );
  } catch (error) {
    yield put(
      removeFromExportCartFailed({
        error,
      }),
    );
  }
}

function* createExportCollectionSaga({ payload: { isPdf, isDoc } }) {
  try {
    const { data } = yield call(graphqlClient.mutate, {
      mutation: mutations.exports.CREATE_EXPORT_COLLECTION,
      variables: {
        isPdf,
        isDoc,
      },
    });

    if (data) yield put(createExportCollectionSuccess());
  } catch (error) {
    yield put(
      createExportCollectionFailed({
        error,
      }),
    );
  }
}

export default function* watch() {
  yield takeLatest(fetchExportCartPending, fetchExportCartSaga);
  yield takeLatest(fetchExportHistoryPending, fetchExportHistorySaga);
  yield takeLatest(addToExportCartPending, addToExportCartSaga);
  yield takeLatest(removeFromExportCartPending, removeFromExportCartSaga);
  yield takeLatest(createExportCollectionPending, createExportCollectionSaga);
}
