import { createSelector } from 'reselect';
import { RootState } from '..';

const exportCart = (state: RootState) => state.export.cart;
const exportHistory = (state: RootState) => state.export.history;

export const selectExportCart = createSelector(exportCart, (cart) => cart);
export const selectExportHistory = createSelector(exportHistory, ({ collections, exportsSort }) => ({
  collections,
  exportsSort,
}));
