import { gql } from '@apollo/client';

const ADD_TO_EXPORT_CART = gql`
  mutation AddToExportCart($issueIds: [Uuid!]!) {
    createExportCarts(issueIds: $issueIds) {
      issueId
      issue {
        id
        description
        issueDateTime
        issueType {
          id
          description
        }
      }
    }
  }
`;

const REMOVE_FROM_EXPORT_CART = gql`
  mutation RemoveFromExportCart($issueIds: [Uuid!]!) {
    deleteExportCarts(issueIds: $issueIds) {
      issueId
    }
  }
`;

const CREATE_EXPORT_COLLECTION = gql`
  mutation CreateExportCollection($isPdf: Boolean!, $isDoc: Boolean!) {
    createExportCollection(isPdf: $isPdf, isDoc: $isDoc) {
      id
      createdBy
      createdAt
      isPdf
      isDoc
    }
  }
`;

export default { ADD_TO_EXPORT_CART, REMOVE_FROM_EXPORT_CART, CREATE_EXPORT_COLLECTION };
