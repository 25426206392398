import { createSelector } from 'reselect';
import { RootState } from '..';

const user = (state: RootState) => state.user.user;
const users = (state: RootState) => state.user.users;
const authUser = (state: RootState) => state.user.authUser;
const userRoles = (state: RootState) => state.user.roles;
const actionFlags = (state: RootState) => state.user.actionFlags;

export const selectUser = createSelector(user, (user) => user);
export const selectUsers = createSelector(users, (users) => users);
export const selectAuthUser = createSelector(authUser, (authUser) => authUser);
export const selectUserId = createSelector(authUser, (authUser) => authUser?.localAccountId);
export const selectUserRoles = createSelector(userRoles, (userRoles) => userRoles);
export const selectActionFlags = createSelector(actionFlags, (actionFlags) => actionFlags);
