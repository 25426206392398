import React, { useEffect, useRef } from 'react';
import { alpha, styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useDispatch, useSelector } from '../../../../hooks/redux';
import { selectIssuesList, selectIssuesListAttributes } from '../../../../store/selectors/issue';
import {
  setIssuesListAttributes,
  fetchIssuesListPending,
  refetchIssuesListPending,
} from '../../../../store/slices/issues';
import InfiniteList from '../../../../components/InfiniteList';
import TrackIssue from './TrackIssue';
import { MapHandle } from '../../../../components/InfrastructureMap';
import { getThemedBorder, getFormattedTime } from '../../../../helpers';
import IssueListFilter from './IssueListFilter';
import IssueListSort from './IssueListSort';
import useCalculatedDimensions from '../../../../hooks/useCalculatedDimensions';
import { ISSUE_LIST_FETCH_INTERVAL } from '../../../../constants';
import { Typography } from '@mui/material';

/* ------- Styles ------- */
const ListWrapper = styled('div', { shouldForwardProp: (prop) => prop !== 'width' })<{ width?: number }>(
  ({ theme, width }) => ({
    display: 'inline-flex',
    flexDirection: 'column',
    width: 'max-content',
    minWidth: `${width}px`,
    borderRight: getThemedBorder(theme),
  }),
);

const LastUpdate = styled('div')(
  ({ theme }) => `
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 4px;
  background: ${alpha(theme.palette.blue[20], 0.6)};
  color: ${theme.palette.blue[100]};
  height: 30px;
  borderBottom: ${getThemedBorder(theme)};
`,
);

const ListHeader = styled('div')(
  ({ theme }) => `
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px;
  height: 54px;
  border-bottom: ${getThemedBorder(theme)};
`,
);

const DateTime = styled('span')`
  font-size: 14px;
  line-height: 1;
`;

/* ------- Types ------- */
interface IIssueList {
  map: React.RefObject<MapHandle>;
}

/* ------- Components ------- */
const IssueList: React.FC<IIssueList> = ({ map }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const issues = useSelector(selectIssuesList);
  const { lastUpdated, offset, limit } = useSelector(selectIssuesListAttributes);

  const lastUpdateRef = useRef<HTMLDivElement | null>(null);
  const listHeaderRef = useRef<HTMLDivElement | null>(null);
  const headerRef = document.getElementsByTagName('header')[0];
  const { height, width } = useCalculatedDimensions([headerRef]);
  const listHeight =
    height - (lastUpdateRef.current?.clientHeight || 0) - (listHeaderRef.current?.clientHeight || 0) - 2; // remove 2px to prevent scroll bar from appearing
  const loadMoreIssuesAllowed = issues.length % limit === 0;

  useEffect(() => {
    // first load issues
    dispatch(fetchIssuesListPending());

    // then refetch issues every 5 minutes
    const interval = setInterval(() => {
      dispatch(refetchIssuesListPending());
    }, ISSUE_LIST_FETCH_INTERVAL);
    return () => {
      clearInterval(interval);
    };
  }, [dispatch]);

  const loadMoreIssues = () => {
    dispatch(
      setIssuesListAttributes({
        issues: {
          offset: offset + limit,
        },
      }),
    );
    dispatch(fetchIssuesListPending());
  };

  return (
    <ListWrapper width={width * 0.264}>
      <LastUpdate ref={lastUpdateRef} data-testid='lastUpdate'>
        <InfoOutlinedIcon sx={{ color: 'secondary.main', fontSize: '16px' }} />
        <DateTime>
          {t('common:list:lastUpdated')} {lastUpdated && getFormattedTime(lastUpdated)}
        </DateTime>
      </LastUpdate>
      <ListHeader ref={listHeaderRef}>
        <IssueListSort />
        <IssueListFilter />
      </ListHeader>
      {issues?.length ? (
        <InfiniteList
          listItems={issues.map((issue) => (
            <TrackIssue key={issue.id} issue={issue} map={map} />
          ))}
          listHeight={listHeight}
          loadMore={loadMoreIssues}
          loadMoreAllowed={loadMoreIssuesAllowed}
        />
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center', padding: '36px' }}>
          <Typography sx={{ fontWeight: 500, color: (theme) => theme.palette.black[60] }}>
            {t('common:list:emptyList')}
          </Typography>
        </div>
      )}
    </ListWrapper>
  );
};

export default IssueList;
