import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from '../../hooks/redux';
import { selectIssuesList } from '../../store/selectors/issue';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import Layout from '../../components/Layout';
import IssueList from './containers/IssueList';
import InfrastructureMap, { MapHandle } from '../../components/InfrastructureMap';
import { fetchIssuesInfoPending } from '../../store/slices/issues';
import { routes } from '../../constants';
import ExportCart from './containers/ExportCart';
import ExportHistory from './containers/ExportHistory';
import Administration from './containers/Administration';

/* ------- Components ------- */
const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const issues = useSelector(selectIssuesList);
  const { view, issueId } = useParams();
  const mapRef = useRef<MapHandle | null>(null);

  useEffect(() => {
    // view === 'train' condition can be removed after train view is ready
    if (!view || view === 'train') {
      navigate(routes.TRACK);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // load issues statuses
    dispatch(fetchIssuesInfoPending());
  }, [dispatch]);

  const getSelectedView = (() => {
    switch (view) {
      case 'track':
        return {
          listSection: <IssueList map={mapRef} />,
          viewSection: issueId ? <Outlet /> : <InfrastructureMap ref={mapRef} issues={issues} />,
        };
      case 'train':
        return {
          listSection: <div style={{ width: 270, height: 500 }}>Assets list</div>,
          viewSection: <p>Assets</p>,
        };
      case 'export-cart':
        return {
          listSection: null,
          viewSection: <ExportCart />,
        };
      case 'export-history':
        return {
          listSection: null,
          viewSection: <ExportHistory />,
        };
      case 'administration':
        return {
          listSection: null,
          viewSection: <Administration />,
        };
      default:
        return;
    }
  })();

  return (
    <Layout sidebarOnChange={() => mapRef.current?.resizeMap()}>
      {getSelectedView?.listSection}
      {getSelectedView?.viewSection}
    </Layout>
  );
};

export default Dashboard;
