import React, { useState, useRef, forwardRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Badge, Avatar } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ArrowDropDown } from '@mui/icons-material';
import ContextMenu, { ContextButton } from '../ContextMenu';
import CustomTooltip from '../CustomTooltip';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../constants';
import { useDispatch, useSelector } from '../../hooks/redux';
import { selectExportCart } from '../../store/selectors/export';
import { fetchExportCartPending } from '../../store/slices/export';
import { useMsal } from '@azure/msal-react';
import { selectAuthUser } from '../../store/selectors/user';
import { getAvatarLetters } from '../../helpers';

/* ------- Styles ------- */
const StyledHeader = styled('header')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: `1px solid ${theme.palette.grey[20]}`,
  padding: '16px 24px',
  height: '80px',
}));

const HeaderItemGroup = styled('div')({
  display: 'inline-flex',
  alignItems: 'center',
  gap: '90px',
});

const HeaderItem = styled('div')({
  display: 'inline-flex',
  alignItems: 'center',
  gap: '16px',
});

/* ------- Components ------- */
const Header = forwardRef<HTMLDivElement, object>((_props, ref) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { instance } = useMsal();
  const cart = useSelector(selectExportCart);
  const authUser = useSelector(selectAuthUser);
  const menuAnchorRef = useRef<HTMLButtonElement | null>(null);
  const [contextOpen, setContextOpen] = useState<boolean>(false);

  useEffect(() => {
    dispatch(fetchExportCartPending());
  }, [dispatch]);

  const contextActions = [
    { name: t('user:headerContextMenu:actionAccount'), href: '/profile' },
    { name: t('user:headerContextMenu:actionNotifications'), href: '/notifications', disabled: true },
    { name: t('user:headerContextMenu:actionLogout'), href: '/', callback: () => instance.logoutRedirect() },
  ];

  const handleExportCartClick = () => {
    // navigate(routes.EXPORT_CART); // disabled
  };

  const handleLogoNavigation = () => {
    navigate(routes.TRACK);
  };

  return (
    <StyledHeader ref={ref} data-testid='header'>
      <img
        style={{ cursor: 'pointer' }}
        src='/images/Logo_OnTrack.svg'
        alt={t('common:logo:alt')}
        width={180}
        height={40}
        onClick={handleLogoNavigation}
      />

      <HeaderItemGroup>
        <CustomTooltip message={t('common:comingSoon')}>
          <HeaderItem style={{ opacity: 0.5 }} data-testid='exportCart' onClick={handleExportCartClick}>
            <Badge
              badgeContent={cart.length}
              color='primary'
              sx={{ height: '24px', '& .MuiBadge-badge': { color: (theme) => theme.palette.white.main } }}
              invisible={!cart.length}
            >
              <img src='/icons/app/exportcart.svg' alt={t('export:exportCartButton')} data-testid='ExportCartIcon' />
            </Badge>
            <Typography>{t('export:exportCartButton')}</Typography>
          </HeaderItem>
        </CustomTooltip>

        <HeaderItem data-testid='user' onClick={() => setContextOpen(!contextOpen)}>
          <Avatar
            sx={{
              textTransform: 'uppercase',
              backgroundColor: (theme) => theme.palette.green[100],
            }}
            data-testid='userAvatar'
          >
            {getAvatarLetters(authUser?.name)}
          </Avatar>
          <Typography>{authUser?.name || 'John Doe'}</Typography>
          <ContextButton ref={menuAnchorRef} menuOpen={contextOpen} handleOpenMenu={() => setContextOpen(true)}>
            <ArrowDropDown sx={contextOpen ? { transform: 'rotate(180deg)' } : {}} />
          </ContextButton>

          <ContextMenu
            menuOpen={contextOpen}
            anchorEl={menuAnchorRef.current}
            handleCloseMenu={() => setContextOpen(false)}
            contextActions={contextActions}
          />
        </HeaderItem>
      </HeaderItemGroup>
    </StyledHeader>
  );
});

Header.displayName = 'Header';
export default Header;
