import React from 'react';
import { useTranslation } from 'react-i18next';
import Sort from '../../../../../../components/Sort';
import { useSelector, useDispatch } from '../../../../../../hooks/redux';
import { selectIssueFeedbacksSort } from '../../../../../../store/selectors/issue';
import { setFeedbacksSortPending } from '../../../../../../store/slices/issues';
import { TSortOrder } from '../../../../../../types';

/* ------- Components ------- */
const MaintenanceHistorySort: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const issueFeedbacksSort = useSelector(selectIssueFeedbacksSort);

  const handleSort = (selectedOption: string, selectedOrder: TSortOrder) => {
    dispatch(setFeedbacksSortPending({ sort: { [selectedOption]: selectedOrder } }));
  };

  const sortOptions = [
    { value: 'createdAt', label: t('common:sort:date') },
    { value: 'severityLevel', label: t('common:sort:severity') },
  ];

  return (
    <Sort
      icon
      sortByLabel=''
      selected={Object.keys(issueFeedbacksSort)[0]}
      onChange={handleSort}
      options={sortOptions}
      sortOrder={Object.values(issueFeedbacksSort)[0]}
    />
  );
};

export default MaintenanceHistorySort;
