import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useTranslation } from 'react-i18next';

export default function SelectUserAutocomplete({ value, onValueChange, options }) {
  const { t } = useTranslation();
  const mappedSelected = value.map((val) => val.id);
  const filterSelectedOptions = options.filter((opt) => !mappedSelected.includes(opt.id));

  return (
    <Autocomplete
      multiple
      value={value}
      onChange={(_, newValue) => {
        onValueChange(newValue);
      }}
      size='small'
      options={filterSelectedOptions}
      getOptionLabel={(option) => option.title}
      renderOption={(props, option) => <li {...props}>{option.title}</li>}
      sx={{ minWidth: 300 }}
      freeSolo
      renderInput={(params) => {
        return <TextField {...params} label={t('issues:detailedView:comments:mention')} fullWidth />;
      }}
    />
  );
}
