import React, { useRef, useState } from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import LanguageIcon from '@mui/icons-material/Language';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ContextMenu, { ContextButton } from '../ContextMenu';
import { Typography } from '@mui/material';

/* ------- Styles ------- */
const iconColorStyle = { color: '#000000' };

/* ------- Types ------- */
interface ILanguageSwitchProps {
  collapsed?: boolean;
  color?: unknown;
  shortLanguageText?: boolean;
}

/* ------- Components ------- */
const LanguageSwitch: React.FC<ILanguageSwitchProps> = ({ collapsed = false, color, shortLanguageText = false }) => {
  const { t } = useTranslation();
  const { language, languages, changeLanguage } = i18next;
  const [contextOpen, setContextOpen] = useState<boolean>(false);
  const menuAnchorRef = useRef<HTMLButtonElement | null>(null);

  const iconStyles = {
    ...(color ? { color } : iconColorStyle),
    ...(contextOpen && { transform: 'rotate(180deg)' }),
  };

  return (
    <div data-testid='languageSwitch'>
      <ContextButton
        ref={menuAnchorRef}
        testId='languageSwitch-button'
        menuOpen={contextOpen}
        handleOpenMenu={() => setContextOpen(true)}
      >
        <>
          <LanguageIcon sx={{ ...(color ? { color } : iconColorStyle), width: '24px', height: '24px' }} />
          <Typography
            data-testid='languageSwitch-button-text'
            color={color as string}
            sx={{
              display: collapsed ? 'none' : 'block',
              textTransform: shortLanguageText ? 'uppercase' : 'none',
              marginLeft: shortLanguageText ? '8px' : '16px',
              fontWeight: shortLanguageText ? 'bold' : 'normal',
            }}
          >
            {shortLanguageText ? language : t(`common:languages:${language}`)}
          </Typography>
          <ArrowDropDownIcon sx={iconStyles} />
        </>
      </ContextButton>
      <ContextMenu
        menuOpen={contextOpen}
        anchorEl={menuAnchorRef.current}
        handleCloseMenu={() => setContextOpen(false)}
        contextActions={languages.map((lang) => ({
          name: collapsed ? lang.toUpperCase() : t(`common:languages:${lang}`),
          callback: () => changeLanguage(lang),
          disabled: lang === language,
        }))}
      />
    </div>
  );
};

export default LanguageSwitch;
