import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import CustomTabPanel from '../../../../components/TabPanel';
import CloseButton from '../../../../components/Buttons/CloseButton';
import Overview from './Overview';
import Comments from './Comments';
import EventHistory from './EventHistory';
import { allyTabProps, getThemedBorder } from '../../../../helpers';
import { useDispatch, useSelector } from '../../../../hooks/redux';
import { fetchIssueDetailsPending, fetchIssueDetailsSuccess } from '../../../../store/slices/issues';
import { routes } from '../../../../constants';
import useCalculatedDimensions from '../../../../hooks/useCalculatedDimensions';
import { selectIssueDetails } from '../../../../store/selectors/issue';
import MaintenanceHistory from './MaintenanceHistory';

/* ------- Styles ------- */
const TabNavContainer = styled(Box)(
  ({ theme }) => `
    border-bottom: ${getThemedBorder(theme)};
    border-top: ${getThemedBorder(theme)};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-shadow: 2px 6px 6px 0px rgba(0, 0, 0, 0.05);
  `,
);

const StyledTab = styled(Tab)`
  min-width: 140px;
`;

/* ------- Components ------- */

const DetailedView: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { tab, issueId } = useParams();
  const tabNavRef = useRef<HTMLDivElement>(null);
  const details = useSelector(selectIssueDetails);

  const headerRef = document.getElementsByTagName('header')[0];
  const { height } = useCalculatedDimensions([headerRef, tabNavRef?.current]);
  const tabHeight = height - 4; // remove 2px to prevent scroll bar from appearing

  const getSelectedTab = (() => {
    switch (tab) {
      case 'events':
        return 1;
      case 'comments':
        return 2;
      case 'maintenance':
        return 3;
      default:
        return 0;
    }
  })();

  const [selectedTab, setSelectedTab] = useState<number>(getSelectedTab);

  useEffect(() => {
    if ((issueId && !details) || details?.id !== issueId) {
      dispatch(fetchIssueDetailsPending({ issueId }));
      setSelectedTab(0);
    }
  }, [dispatch, issueId, details]);

  const handleChange = (_e: React.SyntheticEvent | null, tabIndex: number) => {
    setSelectedTab(tabIndex);
  };

  const onClose = () => {
    dispatch(
      fetchIssueDetailsSuccess({
        issueDetails: null,
      }),
    );
    navigate(routes.TRACK);
  };

  const handleSelectTab = (tabIndex: number) => {
    setSelectedTab(tabIndex);
  };

  return (
    <Box sx={{ position: 'relative', width: '100%', borderLeft: (theme) => getThemedBorder(theme) }}>
      <TabNavContainer ref={tabNavRef}>
        <Tabs value={selectedTab} onChange={handleChange} aria-label='detailed view tabs' sx={{ marginTop: '22px' }}>
          <StyledTab label={t('issues:detailedView:overviewTabLabel')} {...allyTabProps(0)} />
          <StyledTab label={t('issues:detailedView:eventsTabLabel')} {...allyTabProps(1)} />
          <StyledTab label={t('issues:detailedView:commentsTabLabel')} {...allyTabProps(2)} />
          <StyledTab label={t('issues:detailedView:maintenanceTabLabel')} {...allyTabProps(3)} />
        </Tabs>
        <CloseButton onClick={onClose} styles={{ padding: '22px 30px 0 0' }} />
      </TabNavContainer>
      <CustomTabPanel value={selectedTab} index={0} height={tabHeight}>
        <Overview onSelectTab={handleSelectTab} />
      </CustomTabPanel>
      <CustomTabPanel value={selectedTab} index={1} height={tabHeight}>
        <EventHistory tabPanelHeight={tabHeight} />
      </CustomTabPanel>
      <CustomTabPanel value={selectedTab} index={2} height={tabHeight}>
        <Comments tabPanelHeight={tabHeight} />
      </CustomTabPanel>
      <CustomTabPanel value={selectedTab} index={3} height={tabHeight}>
        <MaintenanceHistory tabPanelHeight={tabHeight} />
      </CustomTabPanel>
    </Box>
  );
};

export default DetailedView;
