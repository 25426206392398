import React from 'react';
import { useTranslation } from 'react-i18next';
import Sort from '../../../../../components/Sort';
import { useSelector, useDispatch } from '../../../../../hooks/redux';
import { selectIssuesListAttributes } from '../../../../../store/selectors/issue';
import { setIssueSortPending } from '../../../../../store/slices/issues';
import { TSortOrder } from '../../../../../types';

/* ------- Components ------- */
const IssueListSort: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { issuesSort } = useSelector(selectIssuesListAttributes);

  const handleSort = (selectedOption: string, selectedOrder: TSortOrder) => {
    dispatch(setIssueSortPending({ sort: { [selectedOption]: selectedOrder } }));
  };

  const sortOptions = [
    { value: 'issueDateTime', label: t('common:sort:date') },
    { value: 'severity', label: t('common:sort:severity') },
  ];

  return (
    <Sort
      icon
      sortByLabel=''
      selected={Object.keys(issuesSort)[0]}
      onChange={handleSort}
      options={sortOptions}
      sortOrder={Object.values(issuesSort)[0]}
    />
  );
};

export default IssueListSort;
