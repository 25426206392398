import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-http-backend';
import localesDe from './locales/de';
import localesEn from './locales/en';

export const defaultNS = 'common';
const detectionOptions = {
  order: ['localStorage', 'navigator'],
  lookupLocalStorage: 'i18nextLng',
  caches: ['localStorage'],
};

i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: detectionOptions,
    resources: {
      de: localesDe,
      en: localesEn,
    },
    ns: ['common', 'map', 'user', 'issues', 'export', 'administration'],
    defaultNS,
    fallbackLng: ['de', 'en'],
    supportedLngs: ['de', 'en'],
    interpolation: {
      escapeValue: false,
    },
    debug: false,
  });

export default i18n;
