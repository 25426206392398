import React from 'react';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import {
  Drawer as MuiDrawer,
  Box,
  CssBaseline,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import LanguageSwitch from '../LanguageSwitch';
import { useTranslation } from 'react-i18next';
import useCalculatedDimensions from '../../hooks/useCalculatedDimensions';
import { REACT_APP_VERSION } from '../../config';
import CustomTooltip from '../CustomTooltip';

/* ------- Styles ------- */
const openedMixin = (theme: Theme, sidebarWidth): CSSObject => ({
  width: sidebarWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  margin: '36px 16px 32px 36px',
});

const DrawerTitle = styled(Typography)({
  fontWeight: '400',
  fontSize: 24,
});

const DrawerFooter = styled('div')<{ open: boolean }>(({ open }) => ({
  width: '78%',
  position: 'absolute',
  bottom: '120px',
  left: open ? '40px' : '16px',
  display: 'flex',
  alignItems: 'baseline',
  justifyContent: 'space-between',
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' && prop !== 'sidebarWidth' })<{
  sidebarWidth?: number;
}>(({ theme, open, sidebarWidth }) => ({
  width: sidebarWidth,
  minWidth: '100px',
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme, sidebarWidth),
    '& .MuiDrawer-paper': openedMixin(theme, sidebarWidth),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),

  '& .MuiDrawer-paper': {
    position: 'relative',
    overflowX: 'hidden',
  },
}));

const Version = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: '14px',
  color: theme.palette.black[40],
}));

/* ------- Types ------- */
export type TItem = {
  id: string;
  name: string;
  permitted: boolean;
  icon: JSX.Element;
  onClick: () => void;
  disabled?: boolean;
};

interface ISidebarProps {
  title?: string;
  activeItemId: string | undefined;
  groupedItems: TItem[][];
  open: boolean;
  onChange: () => void;
}
/* ------- Components ------- */
const Sidebar: React.FC<ISidebarProps> = ({ title, activeItemId, groupedItems, open, onChange }) => {
  const { t } = useTranslation();
  const headerRef = document.getElementsByTagName('header')[0];
  const { height, width } = useCalculatedDimensions([headerRef]);
  const sidebarHeight = height - 2; // remove 2px to prevent scroll bar from appearing

  const renderListItem = (item: TItem) => {
    const isItemSelected = activeItemId === item.id;
    const Item = (
      <ListItem key={item.id}>
        <ListItemButton
          onClick={item.onClick}
          disabled={item.disabled}
          sx={{
            minHeight: 48,
            justifyContent: 'center',
            px: 3.5,
            backgroundColor: (theme) => (isItemSelected ? theme.palette.green[100] : theme.palette.white.main),
            borderRadius: '4px',
            ':hover': {
              backgroundColor: (theme) => (isItemSelected ? theme.palette.green[100] : theme.palette.green[20]),
            },
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : 'auto',
              justifyContent: 'center',
              marginRight: open ? '10px' : 0,
              filter: isItemSelected
                ? 'brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(296deg) brightness(105%) contrast(103%);'
                : 'none',
            }}
          >
            {item.icon}
          </ListItemIcon>
          {open ? (
            <ListItemText
              primary={item.name}
              sx={{
                color: (theme) => (isItemSelected ? theme.palette.white.main : theme.palette.black[100]),
              }}
            />
          ) : null}
        </ListItemButton>
      </ListItem>
    );

    if (item.disabled) {
      return (
        <CustomTooltip key={item.id} message={t('common:comingSoon')}>
          {Item}
        </CustomTooltip>
      );
    }

    return Item;
  };

  return (
    <Box sx={{ display: 'inline-flex', height: sidebarHeight }}>
      <CssBaseline />
      <Drawer variant='permanent' open={open} sidebarWidth={width * 0.17} data-testid='sidebar'>
        <DrawerHeader sx={!open ? { marginLeft: '16px', justifyContent: 'center' } : {}}>
          {title && open && <DrawerTitle>{title}</DrawerTitle>}
          <IconButton
            sx={{
              borderRadius: '4px',
              color: (theme) => theme.palette.black[100],
              '.MuiTouchRipple-ripple .MuiTouchRipple-child': {
                borderRadius: '4px',
                backgroundColor: (theme) => theme.palette.green[20],
              },
              ':hover': {
                backgroundColor: (theme) => theme.palette.green[20],
              },
            }}
            onClick={onChange}
          >
            {open ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>
        </DrawerHeader>

        {groupedItems.map((group, index) => (
          <React.Fragment key={`itemGroup-${index}`}>
            <List disablePadding>{group.filter((item) => item.permitted).map((item) => renderListItem(item))}</List>
            {index !== groupedItems.length - 1 && <Divider sx={{ margin: '16px' }} data-testid='divider' />}
          </React.Fragment>
        ))}

        <DrawerFooter open={open}>
          {REACT_APP_VERSION && open && <Version>{`${t('common:version')} ${REACT_APP_VERSION}`}</Version>}
          <LanguageSwitch color={(theme) => theme.palette.green[100]} shortLanguageText />
        </DrawerFooter>
      </Drawer>
    </Box>
  );
};

export default Sidebar;
