import { combineReducers } from 'redux';

import mapReducer from './slices/map';
import userReducer from './slices/user';
import issueReducer from './slices/issues';
import exportReducer from './slices/export';

export default combineReducers({
  user: userReducer,
  map: mapReducer,
  issue: issueReducer,
  export: exportReducer,
});
