import React, { ReactElement } from 'react';
import { Tooltip } from '@mui/material';

/* ------- Types ------- */
interface ICustomTooltip {
  children: ReactElement;
  message: string;
}

/* ------- Components ------- */
const CustomTooltip: React.FC<ICustomTooltip> = ({ children, message }) => (
  <Tooltip
    title={message}
    arrow
    slotProps={{
      popper: {
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -16],
            },
          },
        ],
      },
    }}
  >
    {children}
  </Tooltip>
);

export default CustomTooltip;
