import { createSlice } from '@reduxjs/toolkit';
import { ApolloError } from '@apollo/client/errors';
import { SORT_ORDER, TExportCollection, TExportIssue, TSortOrder } from '../../types';

/*-----------------TYPES-----------------*/

type TPayloadError = {
  error: ApolloError;
};

type TModifyCartData = {
  cart: TExportIssue[];
};

interface IExport {
  cart: TExportIssue[];
  history: {
    collections: TExportCollection[];
    exportsSort: {
      createdAt: TSortOrder;
    };
  };
  error: ApolloError | null;
}

/*-----------------INITIAL STATE-----------------*/
const initialState: IExport = {
  cart: [],
  history: {
    collections: [],
    exportsSort: {
      createdAt: SORT_ORDER.DESCENDING,
    },
  },
  error: null,
};

/*-----------------SLICE-----------------*/
const exportSlice = createSlice({
  name: 'export',
  initialState,
  reducers: {
    /*-----------------Pending Actions/Reducers-----------------*/
    fetchExportCartPending: (state) => state,
    fetchExportHistoryPending: (state) => state,
    addToExportCartPending: (
      state,
      _action: {
        payload: { issueId: string };
      },
    ) => state,
    removeFromExportCartPending: (
      state,
      _action: {
        payload: { issueIds: string[] };
      },
    ) => state,
    createExportCollectionPending: (
      state,
      _action: {
        payload: { isPdf: boolean; isDoc: boolean };
      },
    ) => state,

    /*-----------------Success Actions/Reducers-----------------*/
    fetchExportCartSuccess: (state, { payload }) => {
      return {
        ...state,
        cart: payload.cart,
      };
    },

    fetchExportHistorySuccess: (state, { payload }) => {
      return {
        ...state,
        history: {
          ...state.history,
          collections: payload.collections,
        },
      };
    },

    addToExportCartSuccess: (state, { payload }: { payload: TModifyCartData }) => {
      return {
        ...state,
        cart: [...state.cart, ...payload.cart],
      };
    },
    removeFromExportCartSuccess: (state, { payload }: { payload: { cart: { issueId: string }[] } }) => {
      return {
        ...state,
        cart: state.cart.filter((issue) => !payload.cart.map((item) => item.issueId).includes(issue.id)),
      };
    },
    createExportCollectionSuccess: (state) => {
      return {
        ...state,
        cart: [],
      };
    },
    setExportHistoryAttributes: (state, { payload }: { payload: { exportsSort: { createdAt: TSortOrder } } }) => {
      return {
        ...state,
        history: {
          ...state.history,
          ...payload,
        },
      };
    },

    /*-----------------Failed Actions/Reducers-----------------*/
    fetchExportCartFailed: (state, { payload }: { payload: TPayloadError }) => {
      return {
        ...state,
        error: payload.error,
      };
    },
    fetchExportHistoryFailed: (state, { payload }: { payload: TPayloadError }) => {
      return {
        ...state,
        error: payload.error,
      };
    },
    addToExportCartFailed: (state, { payload }: { payload: TPayloadError }) => {
      return {
        ...state,
        error: payload.error,
      };
    },
    removeFromExportCartFailed: (state, { payload }: { payload: TPayloadError }) => {
      return {
        ...state,
        error: payload.error,
      };
    },
    createExportCollectionFailed: (state, { payload }: { payload: TPayloadError }) => {
      return {
        ...state,
        error: payload.error,
      };
    },
  },
});

export const {
  fetchExportCartPending,
  fetchExportHistoryPending,
  addToExportCartPending,
  removeFromExportCartPending,
  createExportCollectionPending,
  fetchExportCartSuccess,
  fetchExportHistorySuccess,
  addToExportCartSuccess,
  removeFromExportCartSuccess,
  createExportCollectionSuccess,
  setExportHistoryAttributes,
  fetchExportCartFailed,
  fetchExportHistoryFailed,
  addToExportCartFailed,
  removeFromExportCartFailed,
  createExportCollectionFailed,
} = exportSlice.actions;

export default exportSlice.reducer;
