import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from '../../../../../hooks/redux';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { cellToLatLng } from 'h3-js';
import {
  deleteIssueCommentsPending,
  setIssueStatusPending,
  updateIssueCommentsPending,
} from '../../../../../store/slices/issues';
import Chart from '../../../../../components/Chart';
import Comment from '../../../../../components/Comment';
import Link from '@mui/material/Link';
import { selectIssueDetails, selectIssueStatuses } from '../../../../../store/selectors/issue';
import {
  getApiUserName,
  getAvatarLetters,
  getFormattedTime,
  getIssueFormattedDate,
  getIssueStatus,
  getUserName,
  mapIssueStatusToId,
  omit,
} from '../../../../../helpers';
import { styled } from '@mui/material/styles';
import { MARK_AS_READ_DELAY, routes } from '../../../../../constants';
import { selectUserId, selectUsers } from '../../../../../store/selectors/user';
import CollapsibleTable, { TData } from '../../../../../components/CollapsibleTable';
import { formatHistoryEventDateTime, groupMeasurementEvents } from '../EventHistory';
import MaintenanceReport from '../MaintenanceReport';
import { REACT_APP_USE_API_DIRECTLY_FF } from '../../../../../config';
import { Typography } from '@mui/material';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ISSUE_STATUS, TChartData } from '../../../../../types';
import ExtraWidthButton from '../../../../../components/Buttons/ExtraWidthButton';
import DeleteDialog from '../../../../../components/DeleteDialog';
import CustomSnackbar from '../../../../../components/Snackbar';

/* ------- Styles ------- */
const OverviewContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 24px;
`;

const TypographyWithMargin = styled(Typography)`
  margin: 16px 0 10px 0;
`;

const DetailsContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;

const DetailsHeader = styled('h3')`
  margin: 0;
  font-size: 24px;
`;

const CoordinatesBlock = styled('p')`
  display: flex;
  align-items: center;
  margin: 0 0 16px 0;
`;

const DescriptionBlock = styled('div')`
  max-width: 500px;
  '& p': {
    margin: 16px 0;
  }
`;

const LatestBlockHeader = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 30px 30px 0 0;
`;

const MaintenanceLinksBlock = styled('div')`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  justify-content: flex-end;
  gap: 24px;
  max-height: 42px;
`;

const EmptyAreaBlock = styled(Typography)`
  color: #bcbcbc;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  padding-bottom: 24px;
`;

const IgnoredLabel = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: '16px 0 8px 0',
  color: theme.palette.action.main,
}));

/* ------- Types ------- */
interface IOverviewProps {
  onSelectTab: (tabIndex: number) => void;
}

/* ------- Components ------- */
const Overview: React.FC<IOverviewProps> = ({ onSelectTab }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { issueId } = useParams();
  const details = useSelector(selectIssueDetails);
  const users = useSelector(selectUsers);
  const userId = useSelector(selectUserId);
  const statuses = useSelector(selectIssueStatuses);

  const [chartData, setChartData] = useState<TChartData>({ ts: [], acc_z: [] });
  const [deleteDialog, setDeleteDialog] = useState<boolean>(false);
  const [commentToDelete, setCommentToDelete] = useState<string | null>(null);
  const [showSubmitNotification, setShowSubmitNotification] = useState<boolean>(false);
  const [reportSubmitted, setReportSubmitted] = useState<boolean>(false);

  const status = details && getIssueStatus(details);
  const getName = REACT_APP_USE_API_DIRECTLY_FF ? getApiUserName : getUserName;
  const showMaintenanceComplete = false;
  const [issueLat, issueLong] = cellToLatLng(details ? details.h3Index : '');

  useEffect(() => {
    if (details?.measurementEvents) {
      const latestEvent = [...details.measurementEvents].sort((a, b) => {
        return new Date(b.measurementEventDateTime).getTime() - new Date(a.measurementEventDateTime).getTime();
      })[0];

      if (latestEvent?.eventData?.[0]?.rawData) {
        setChartData(omit(JSON.parse(latestEvent?.eventData?.[0]?.rawData), ['serialnumber']) as TChartData);
      }
    }
  }, [details?.measurementEvents]);

  useEffect(() => {
    let markAsRead: ReturnType<typeof setTimeout> | null = null;

    if (status?.status_transition_allowed(ISSUE_STATUS.READ)) {
      markAsRead = setTimeout(() => {
        const statusId = status.next_status && mapIssueStatusToId(statuses)[status.next_status];

        if (issueId && statusId) {
          dispatch(
            setIssueStatusPending({
              issueId,
              statusId,
            }),
          );
        }
      }, MARK_AS_READ_DELAY);
    }

    return () => {
      if (markAsRead) {
        clearTimeout(markAsRead);
      }
    };
  }, [details?.issueStatusId, dispatch, issueId, statuses, status]);

  const handleMaintenanceComplete = () => {
    const maintenanceCompleteStatusId = status?.next_status && mapIssueStatusToId(statuses)[status.next_status];

    if (issueId && maintenanceCompleteStatusId) {
      dispatch(
        setIssueStatusPending({
          issueId,
          statusId: maintenanceCompleteStatusId,
        }),
      );
    }
  };

  const createData: (event: { date: string; time: string; eventType: string }) => TData = ({
    date,
    time,
    eventType,
  }) => {
    return {
      date,
      time,
      eventType,
    };
  };

  const generateRows: () => TData[] = () => {
    if (!details) {
      return [];
    }

    const { issueStatusChangeHistories, measurementEvents } = details;

    let historyEvents: TData[] = measurementEvents.map((event) =>
      createData({
        date: event.measurementEventDateTime,
        time: event.measurementEventDateTime,
        eventType: t('issues:detailedView:eventHistory:eventType:' + event.__typename),
      }),
    );

    if (issueStatusChangeHistories) {
      historyEvents = [
        ...historyEvents,
        ...issueStatusChangeHistories.map((event) =>
          createData({
            date: event.changeDateTime,
            time: event.changeDateTime,
            eventType: t('issues:detailedView:eventHistory:eventType:' + event.toStatus.description),
          }),
        ),
      ];
    }

    // sort history events by date, latest first and use only first five
    historyEvents = historyEvents
      .sort((a, b) => new Date(b.date as string).getTime() - new Date(a.date as string).getTime())
      .slice(0, 5);

    // group measurement events
    if (historyEvents.some((event) => event.eventType === 'Measurement')) {
      historyEvents = groupMeasurementEvents(
        historyEvents,
        t('issues:detailedView.eventHistory:eventType:MeasurementGroup'),
        t('issues:detailedView.eventHistory:eventType:MeasurementEvent'),
      );
    }

    // formate date and time for history events
    historyEvents = formatHistoryEventDateTime(historyEvents);

    // return only first five events from history events for overview
    return historyEvents;
  };

  const handleUpdate = (id, comment) => {
    // update comment
    dispatch(
      updateIssueCommentsPending({
        issueComments: [
          {
            id,
            comment,
          },
        ],
      }),
    );
  };

  const handleDeleteRequest = (id: string) => {
    setCommentToDelete(id);
    setDeleteDialog(true);
  };

  const cancelDeleteRequest = () => {
    setCommentToDelete(null);
    setDeleteDialog(false);
  };

  const handleDelete = () => {
    if (commentToDelete) {
      // delete comment
      dispatch(
        deleteIssueCommentsPending({
          ids: [commentToDelete],
        }),
      );

      // clear states
      setDeleteDialog(false);
      setCommentToDelete(null);
    }
  };
  const handleGoToTrackView = () => {
    navigate(routes.TRACK);
  };

  useEffect(() => {
    if (reportSubmitted && status?.maintenance_complete_allowed) {
      setShowSubmitNotification(true);
      setReportSubmitted(false);
    }
  }, [reportSubmitted, status]);

  return (
    details && (
      <>
        <OverviewContainer>
          <DetailsContainer>
            <DetailsHeader>{details.issueType.description}</DetailsHeader>
            {status?.name === ISSUE_STATUS.IGNORED && (
              <IgnoredLabel>
                <InfoOutlinedIcon color='action' />
                &nbsp;{t('issues:detailedView:overview:ignoredLabel')}
              </IgnoredLabel>
            )}
            <TypographyWithMargin>
              <span style={{ fontWeight: '600' }}>{t('issues:detailedView:overview:detectedOnLabel')}&nbsp;</span>
              <span>{getIssueFormattedDate(details.issueDateTime)}</span>
            </TypographyWithMargin>
            <CoordinatesBlock>
              <FmdGoodOutlinedIcon color='action' />
              &nbsp;
              <Link
                rel='noopener noreferrer'
                href={`https://maps.google.com/?q=${issueLat},${issueLong}`}
                target='_blank'
              >
                {issueLat},&nbsp;{issueLong}
              </Link>
            </CoordinatesBlock>
            <DescriptionBlock>
              <TypographyWithMargin>{t('issues:detailedView:overview:issueCaption')}</TypographyWithMargin>
              <TypographyWithMargin>{t('issues:detailedView:overview:diagramCaption')}</TypographyWithMargin>
            </DescriptionBlock>
          </DetailsContainer>
          <div>
            <MaintenanceLinksBlock>
              <MaintenanceReport
                issueId={issueId || details.id}
                reportAllowed={status?.maintenance_report_allowed || false}
                onSubmit={() => setReportSubmitted(true)}
              />

              {showMaintenanceComplete ? (
                <ExtraWidthButton
                  variant='contained'
                  onClick={handleMaintenanceComplete}
                  data-testid='maintenance-report-cancel'
                  disabled={!status?.maintenance_complete_allowed}
                >
                  {t('issues:detailedView:overview:maintenanceCompleteLink')}
                </ExtraWidthButton>
              ) : null}
            </MaintenanceLinksBlock>
            <Chart
              title={t('issues:detailedView:overview:graphTitle')}
              labels={chartData.ts.map((date) =>
                getFormattedTime(date, { hour: '2-digit', minute: '2-digit', second: '2-digit' }),
              )}
              data={omit(chartData, ['ts'])}
              hiddenDatasets={['acc_x', 'acc_y', 'norm']}
              xAxisLabel='Time (ms)'
              yAxisLabel='Accelleration in mm/s²'
            />
          </div>
        </OverviewContainer>
        <div>
          <LatestBlockHeader>
            <h3>{t('issues:detailedView:overview:latestEventsLabel')}</h3>
            <Link component='button' variant='body1' onClick={() => onSelectTab(1)}>
              {t('issues:detailedView:overview:viewAllLink')}
            </Link>
          </LatestBlockHeader>
          <CollapsibleTable
            collapsible={false}
            columns={[
              { id: 'date', label: t('issues:detailedView:eventHistory:date') },
              { id: 'time', label: t('issues:detailedView:eventHistory:time') },
              { id: 'eventType', label: t('issues:detailedView:eventHistory:eventType:title') },
            ]}
            rows={generateRows()}
            height={330}
          />
        </div>
        <div>
          <LatestBlockHeader>
            <h3>{t('issues:detailedView:overview:latestCommentsLabel')}</h3>
            <Link component='button' variant='body1' onClick={() => onSelectTab(2)}>
              {t('issues:detailedView:overview:viewAllLink')}
            </Link>
          </LatestBlockHeader>
          {users && details.issueComments?.length ? (
            [...details.issueComments]
              ?.sort((a, b) => new Date(b.createdAt as string).getTime() - new Date(a.createdAt as string).getTime())
              .slice(0, 5)
              .map((comment) => (
                <Comment
                  key={comment.id}
                  comment={{
                    ...comment,
                    userName: getName(comment.createdBy, users) || t('common:unknownUser'),
                    avatarLetters: getAvatarLetters(getName(comment.createdBy, users) || t('common:unknownUser')),
                  }}
                  styles={{
                    border: 'none',
                    backgroundColor: 'white',
                    width: '100%',
                    maxWidth: 'none',
                    wordBreak: 'break-all',
                    padding: '20px 10px 20px 0',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  userIsAuthor={comment.createdBy === userId}
                  onUpdate={handleUpdate}
                  onDelete={handleDeleteRequest}
                />
              ))
          ) : (
            <EmptyAreaBlock>{t('issues:detailedView:overview:noComments')}</EmptyAreaBlock>
          )}
        </div>

        <DeleteDialog
          open={deleteDialog}
          onConfirm={handleDelete}
          onClose={cancelDeleteRequest}
          dialogTitle={t('issues:detailedView:comments:deleteComment:title')}
          dialogText={t('issues:detailedView:comments:deleteComment:text')}
        />

        <CustomSnackbar
          open={showSubmitNotification}
          message={t('issues:detailedView:maintenanceReport:successMessage')}
          onClose={() => setShowSubmitNotification(false)}
          onAction={handleGoToTrackView}
          actionLabel={t('issues:detailedView:overview:closeAction')}
          data-testid='add-maintenance-report-snackbar'
        />
      </>
    )
  );
};

export default Overview;
