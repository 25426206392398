import common from './common.json';
import map from './map.json';
import user from './user.json';
import issues from './issues.json';
import exportDictionary from './export.json';
import administration from './administration.json';

const localesDe = {
  common,
  map,
  user,
  issues,
  export: exportDictionary,
  administration,
};

export default localesDe;
