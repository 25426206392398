import { gql } from '@apollo/client';

const GET_EXPORT_CART = gql`
  query GetExportCart($limit: Int, $offset: Int) {
    exportCarts(take: $limit, skip: $offset) {
      issueId
      issue {
        id
        description
        issueCategory
        issueDateTime
        issueType {
          id
          description
        }
      }
    }
  }
`;

const GET_EXPORT_COLLECTIONS = gql`
  query GetExportCollections($exportsSort: [ExportCollectionSortInput!], $limit: Int, $offset: Int) {
    exportCollections(order: $exportsSort, take: $limit, skip: $offset) {
      id
      createdBy
      createdAt
      isPdf
      isDoc
      exportCollectionBridgeIssues {
        issue {
          id
          description
          issueCategory
          issueDateTime
          issueType {
            id
            description
          }
        }
      }
    }
  }
`;

const GET_EXPORT_COLLECTION_BY_ID = gql`
  query GetExportCollectionById($id: [Uuid!]!) {
    exportCollection(id: $id) {
      id
      createdBy
      createdAt
      isPdf
      isDoc
      exportCollectionBridgeIssues {
        id
        issueId
        exportCollectionId
        lastMeasurementEventId
        lastMeasurementEvent
      }
    }
  }
`;

export default { GET_EXPORT_CART, GET_EXPORT_COLLECTIONS, GET_EXPORT_COLLECTION_BY_ID };
