import React from 'react';
import Box from '@mui/material/Box';

/* ------- Types ------- */
interface ICustomTabPanelProps {
  children?: React.ReactNode;
  value: number;
  index: number;
  height: number;
  width?: string;
}

const CustomTabPanel: React.FC<ICustomTabPanelProps> = ({ children, value, index, height, width = '100%' }) => (
  <div
    role='tabpanel'
    hidden={value !== index}
    id={`tabpanel-${index}`}
    aria-labelledby={`tab-${index}`}
    style={{ height: height, overflow: 'auto', width }}
  >
    {value === index && <Box sx={{ p: 4, height: '100%' }}>{children}</Box>}
  </div>
);

export default CustomTabPanel;
