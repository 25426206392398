import { ISSUE_STATUS, TApiUser, TIssue, TIssueStatus, TMeasurementEvent, TSeverityLevel, TUser } from './types';
import { Theme } from '@mui/material';
import { accessKeys, routes } from './constants';

/* ------- Types ------- */
type TIntervalImmediately = (callback: () => void, interval: number) => NodeJS.Timeout;

type TAllyTabProps = (index: number) => {
  id: string;
  'aria-controls': string;
};

type TGetFormattedDateTime = (dateString: string, options?: Intl.DateTimeFormatOptions) => string;

type TMapIssueStatusToId = (statuses: TIssueStatus[]) => {
  [key in ISSUE_STATUS]: string | undefined;
};

type TCopyToClipboard = (value: string) => void;

type TStringArrayEquals = (value1: string[], value2: string[]) => boolean;

type TGetLatestSeverity = (measurementEvents: TMeasurementEvent[]) => TSeverityLevel | undefined;

type TGetAvatarLetters = (name: string) => string;

export type TGetUserName = (id: string, users: TUser[]) => string | undefined;

export type TGetApiUserName = (userId: string, users: TApiUser[]) => string | undefined;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TOmit = (obj: object, keys: string[]) => Record<string, any>;

/* ------- Helpers ------- */
export const setIntervalImmediately: TIntervalImmediately = (callback, interval) => {
  callback();
  return setInterval(callback, interval);
};

export const allyTabProps: TAllyTabProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

export const getFormattedDate: TGetFormattedDateTime = (
  dateString,
  options = { year: 'numeric', month: '2-digit', day: '2-digit' },
) => new Date(dateString).toLocaleDateString('de', options);

export const getFormattedTime: TGetFormattedDateTime = (dateString, options = { hour: '2-digit', minute: '2-digit' }) =>
  new Date(dateString).toLocaleTimeString('de', options);

export const getIssueFormattedDate: TGetFormattedDateTime = (dateString) =>
  `${getFormattedDate(dateString)} | ${getFormattedTime(dateString)}`;

export const mapIssueStatusToId: TMapIssueStatusToId = (statuses) => ({
  [ISSUE_STATUS.READ]: statuses?.find((status) => status.description === ISSUE_STATUS.READ)?.id,
  [ISSUE_STATUS.UNREAD]: statuses?.find((status) => status.description === ISSUE_STATUS.UNREAD)?.id,
  [ISSUE_STATUS.PROCESSED]: statuses?.find((status) => status.description === ISSUE_STATUS.PROCESSED)?.id,
  [ISSUE_STATUS.IGNORED]: statuses?.find((status) => status.description === ISSUE_STATUS.IGNORED)?.id,
  [ISSUE_STATUS.EXPORTED]: statuses?.find((status) => status.description === ISSUE_STATUS.EXPORTED)?.id,
  [ISSUE_STATUS.MAINTENANCE_COMPLETED]: statuses?.find(
    (status) => status.description === ISSUE_STATUS.MAINTENANCE_COMPLETED,
  )?.id,
});

export const copyToClipboard: TCopyToClipboard = (value) => {
  navigator.clipboard.writeText(value);
};

export const stringArrayEquals: TStringArrayEquals = (array1, array2) => {
  const sortedArray2 = array2.sort();

  return (
    Array.isArray(array1) &&
    Array.isArray(array2) &&
    array1.length === array2.length &&
    array1.sort().every((val, index) => val === sortedArray2[index])
  );
};

export const getLatestSeverity: TGetLatestSeverity = (measurementEvents) => {
  const sortedEvents = [...measurementEvents].sort((a, b) => {
    return new Date(b.measurementEventDateTime).getTime() - new Date(a.measurementEventDateTime).getTime();
  });

  return sortedEvents[0]?.severityLevel;
};

export const getIssueLink = (issueId: TIssue['id'], isURL: boolean = false) =>
  `${isURL ? window.location.origin : ''}${routes.TRACK}/${issueId}`;

export const getThemedBorder = (theme: Theme) =>
  `${theme.divider.borderWidth} ${theme.divider.borderStyle} ${theme.divider.borderColor}`;

export const getAvatarLetters: TGetAvatarLetters = (name) => {
  if (!name || !name.length) return '';
  const matches = name.match(/\b(\w)/g);
  return matches ? matches.join('').substring(0, 2) : name.charAt(0);
};

export const getUserName: TGetUserName = (userId, users) => {
  const creator = users.find((u) => u.id === userId);
  return creator ? creator.userName : undefined;
};

export const getApiUserName: TGetApiUserName = (userId, users) => {
  const creator = users.find((u) => u.userId === userId);
  return creator ? creator.displayName : undefined;
};

export const isValidMail: (email: string) => RegExpMatchArray | null = (email) => {
  return String(email)
    .toLowerCase()
    .match(/^([\w.%+-]+)@([\w-]+\.)+(\w{2,})$/);
};

export const omit: TOmit = (obj, keys) => Object.fromEntries(Object.entries(obj).filter(([k]) => !keys.includes(k)));

export class User {
  [accessKeys.ASSETS_ALLOWED]: boolean;
  [accessKeys.INFRASTRUCTURE_ALLOWED]: boolean;
  [accessKeys.DEVICE_MANAGEMENT_ALLOWED]: boolean;
  [accessKeys.ASSET_MANAGEMENT_ALLOWED]: boolean;
  [accessKeys.USER_MANAGEMENT_ALLOWED]: boolean;

  constructor(permissions) {
    this[accessKeys.ASSETS_ALLOWED] = permissions?.includes('OTM.Train');
    this[accessKeys.INFRASTRUCTURE_ALLOWED] = permissions?.includes('OTM.Infrastructure');
    this[accessKeys.DEVICE_MANAGEMENT_ALLOWED] = permissions?.includes('OTM.DeviceManagement');
    this[accessKeys.ASSET_MANAGEMENT_ALLOWED] = permissions?.includes('OTM.AssetManagement');
    this[accessKeys.USER_MANAGEMENT_ALLOWED] = permissions?.includes('OTM.UserManagement');
  }
}

class Status {
  status_id: string;
  name: string;
  next_status: string | undefined;
  revert_status: string | undefined;
  possible_transitions: string[];
  maintenance_report_allowed: boolean;
  maintenance_complete_allowed: boolean;

  constructor(issue: TIssue) {
    this.status_id = issue.issueStatusId;
    this.name = issue.issueStatus.description;
    this.next_status = undefined;
    this.revert_status = undefined;
    this.possible_transitions = [];
    this.maintenance_report_allowed = false;
    this.maintenance_complete_allowed = false;
  }

  status_transition_allowed = (new_status) => {
    return this.possible_transitions.includes(new_status);
  };

  // possible dependency on user role
  status_transition_allowed_to = (user_access) => {
    return Object.values(user_access).some((access) => !!access);
  };
}

export class Unread extends Status {
  constructor(issue: TIssue) {
    super(issue);
    this.next_status = ISSUE_STATUS.READ;
    this.revert_status = ISSUE_STATUS.READ;
    this.possible_transitions = [ISSUE_STATUS.READ];
  }
}

export class Read extends Status {
  constructor(issue: TIssue) {
    super(issue);
    this.next_status = ISSUE_STATUS.PROCESSED;
    this.revert_status = ISSUE_STATUS.UNREAD;
    this.possible_transitions = [
      ISSUE_STATUS.PROCESSED,
      ISSUE_STATUS.IGNORED,
      ISSUE_STATUS.EXPORTED,
      ISSUE_STATUS.UNREAD,
    ];
    this.maintenance_report_allowed = true;
  }
}

export class Ignored extends Status {
  constructor(issue: TIssue) {
    super(issue);
    this.next_status = ISSUE_STATUS.PROCESSED;
    this.revert_status = ISSUE_STATUS.PROCESSED;
    this.possible_transitions = [ISSUE_STATUS.PROCESSED];
  }
}

export class Processed extends Status {
  constructor(issue: TIssue) {
    super(issue);
    this.next_status = ISSUE_STATUS.MAINTENANCE_COMPLETED;
    this.possible_transitions = [ISSUE_STATUS.UNREAD, ISSUE_STATUS.MAINTENANCE_COMPLETED];
    this.maintenance_complete_allowed = true;
  }
}

export class Exported extends Status {
  constructor(issue: TIssue) {
    super(issue);
    this.next_status = ISSUE_STATUS.PROCESSED;
    this.possible_transitions = [ISSUE_STATUS.PROCESSED, ISSUE_STATUS.IGNORED];
  }
}

export const getIssueStatus = (issue: TIssue) => {
  switch (issue.issueStatus.description) {
    case ISSUE_STATUS.READ:
      return new Read(issue);

    case ISSUE_STATUS.UNREAD:
      return new Unread(issue);

    case ISSUE_STATUS.PROCESSED:
      return new Processed(issue);

    case ISSUE_STATUS.IGNORED:
      return new Ignored(issue);

    case ISSUE_STATUS.EXPORTED:
      return new Exported(issue);

    default:
      return new Status(issue);
  }
};
