import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { useDispatch, useSelector } from '../../hooks/redux';
import { styled, useTheme } from '@mui/material/styles';
import { TIssue } from '../../types';
import { cellToLatLng, isValidCell } from 'h3-js';
import { MapContainer } from 'react-leaflet';
import { Map as TMap } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { selectFocusedMarker, selectInitialMapPosition } from '../../store/selectors/map';
import MapLayer from './MapLayer';
import { setFocusedMarkerPending } from '../../store/slices/map';

/* ------- Constants ------- */
const mapResolution = 11;
const zoomResolution = 16;

/* ------- Styles ------- */
const MapWrapper = styled('div')(() => ({
  height: '100%',
  width: '100%',

  '& .baseLayerGrayscale': {
    filter: 'grayscale(1)',
  },
}));

const fullSize = {
  height: '100%',
  width: '100%',
};

/* ------- Types ------- */
export type MapHandle = {
  showIssueOnMap: (issueId: string, h3Index: string) => void;
  resizeMap: () => void;
};

interface IInfrastructureMapProps {
  issues: TIssue[];
}

/* ------- Components ------- */
const InfrastructureMap = forwardRef<MapHandle, IInfrastructureMapProps>(({ issues }, ref) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const initialMapPosition = useSelector(selectInitialMapPosition);
  const focusedMarker = useSelector(selectFocusedMarker);
  const leafletMapRef = useRef<TMap | null>(null);

  useImperativeHandle(
    ref,
    () => ({
      showIssueOnMap(issueId, h3Index) {
        // check if h3index is valid
        if (!isValidCell(h3Index)) return;

        // fly to hexagon
        leafletMapRef.current?.flyTo(cellToLatLng(h3Index), zoomResolution);

        // set new focused marker
        dispatch(setFocusedMarkerPending({ focusedMarkerId: issueId, h3Index }));
      },
      resizeMap() {
        // timeout is needed to prevent problems with running conditions
        setTimeout(() => {
          leafletMapRef.current?.invalidateSize({ pan: false });
        }, theme.transitions.duration.enteringScreen);
      },
    }),
    [dispatch, theme.transitions.duration.enteringScreen],
  );

  return (
    <MapWrapper data-testid='map'>
      {initialMapPosition && (
        <MapContainer
          ref={leafletMapRef}
          center={focusedMarker?.h3Index ? cellToLatLng(focusedMarker.h3Index) : cellToLatLng(initialMapPosition)}
          zoom={focusedMarker.h3Index ? zoomResolution : mapResolution}
          style={fullSize}
        >
          <MapLayer issues={issues} />
        </MapContainer>
      )}
    </MapWrapper>
  );
});

InfrastructureMap.displayName = 'InfrastructureMap';
export default InfrastructureMap;
