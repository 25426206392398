import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  Checkbox,
  MenuItem,
  OutlinedInput,
  Typography,
  TextField,
  Select,
} from '@mui/material';
import CloseButton from '../../../../../components/Buttons/CloseButton';
import { selectIssueCauses } from '../../../../../store/selectors/issue';
import { useDispatch, useSelector } from '../../../../../hooks/redux';
import { IIssueFeedback, createIssueFeedbacksPending } from '../../../../../store/slices/issues';
import { SEVERITY_LEVEL } from '../../../../../types';
import ExtraWidthButton from '../../../../../components/Buttons/ExtraWidthButton';

/* ------- Styles ------- */
const DialogActions = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '32px',
});

const StyledOutlinedInput = styled(OutlinedInput)({
  borderRadius: '10px',
  color: '#767676',
  height: '40px',
  width: '50%',
});

const Label = styled(Typography)({
  color: 'black',
  fontWeight: '700',
  fontSize: '14px',
});

const DialogHeader = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '32px 32px 24px',
});

const DialogContainer = styled(DialogContent)({
  width: '462px',
  maxHeight: '650px',
  minHeight: '385px',
  padding: '0px 32px 0 32px',
});

const StyledFormLabel = styled('div')({
  width: '20%',
});

const StyleFormControl = styled(FormControl)({
  flexDirection: 'row',
  alignItems: 'center',
  margin: '12px 0',
  width: '100%',
  gap: '24px',
});

const Title = styled('h3')(({ theme }) => ({
  margin: '0',
  fontWeight: 700,
  color: theme.palette.blue[100],
}));

const CloseButtonContainer = styled('div')({
  display: 'flex',
  justifyContent: 'end',
  position: 'absolute',
  top: '3px',
  right: '10px',
});

const CheckboxLabel = styled('span')({
  fontWeight: 400,
  fontSize: '12px',
});

/* ------- Types ------- */
interface IMaintenanceReportProps {
  issueId: string;
  reportAllowed: boolean;
  onSubmit: () => void;
}

/* ------- Components ------- */
const MaintenanceReport: React.FC<IMaintenanceReportProps> = ({ issueId, reportAllowed, onSubmit }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const dispatch = useDispatch();
  const issueCauses = useSelector(selectIssueCauses);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [report, setReport] = useState<Omit<IIssueFeedback, 'issueId'>>({
    issueCauseId: '',
    severityLevel: '',
    otherCause: '',
    ignoreIssue: false,
    comment: '',
  });

  const causeLangKey = `cause${language[0].toUpperCase() + language.slice(1)}`;

  const isOtherCause = report.issueCauseId === issueCauses.find((cause) => !cause.issueTypeId)?.id;

  const selectPlaceholder = t('issues:detailedView:maintenanceReport:labels:selectPlaceholder');

  const severityOptions = [
    { key: SEVERITY_LEVEL.LOW, label: t(`issues:severity:${SEVERITY_LEVEL.LOW}`) },
    { key: SEVERITY_LEVEL.MEDIUM, label: t(`issues:severity:${SEVERITY_LEVEL.MEDIUM}`) },
    { key: SEVERITY_LEVEL.HIGH, label: t(`issues:severity:${SEVERITY_LEVEL.HIGH}`) },
  ];

  const handleUpdateReport = (key: string) => {
    return (event) => {
      const nextValue = event.target.value;
      setReport({
        ...report,
        [key]: nextValue,
      });
    };
  };

  const handleCloseDialog = () => {
    setShowForm(false);
    setReport({ issueCauseId: '', severityLevel: '', otherCause: '', ignoreIssue: false, comment: '' });
  };

  const handleSubmit = () => {
    dispatch(
      createIssueFeedbacksPending({
        issueId,
        ...report,
      }),
    );
    onSubmit();
    handleCloseDialog();
  };

  const handleCancel = () => {
    handleCloseDialog();
  };

  const getIsSubmitAllowed = () => {
    if (report.issueCauseId.length) {
      if (report.issueCauseId === issueCauses.find((cause) => !cause.issueTypeId)?.id) {
        return !!report.otherCause.length && (report.ignoreIssue || !!report.severityLevel.length);
      } else {
        return report.ignoreIssue || !!report.severityLevel.length;
      }
    }

    return false;
  };

  return (
    <>
      <ExtraWidthButton
        variant='contained'
        onClick={() => setShowForm(true)}
        data-testid='maintenance-report-link'
        disabled={!reportAllowed}
      >
        {t('issues:detailedView:overview:maintenanceReportLink')}
      </ExtraWidthButton>
      <Dialog open={showForm} onClose={() => handleCloseDialog()}>
        <DialogHeader>
          <Title>{t('issues:detailedView:maintenanceReport:title')}</Title>
          <CloseButtonContainer>
            <CloseButton onClick={handleCloseDialog} />
          </CloseButtonContainer>
        </DialogHeader>

        <DialogContainer data-testid='maintenance-report-content'>
          <StyleFormControl size='small' focused={false}>
            <StyledFormLabel>
              <Label>{t('issues:detailedView:maintenanceReport:labels:cause')}</Label>
            </StyledFormLabel>
            <Select
              value={report.issueCauseId}
              onChange={handleUpdateReport('issueCauseId')}
              input={<StyledOutlinedInput />}
              sx={{
                '& .MuiSelect-select .notranslate::after': selectPlaceholder
                  ? {
                      content: `"${selectPlaceholder}"`,
                      opacity: 0.67,
                    }
                  : {},
              }}
            >
              {issueCauses.map((opt, index) => (
                <MenuItem key={opt.id} value={opt.id} data-testid={`cause-option-${index}`}>
                  {opt[causeLangKey]}
                </MenuItem>
              ))}
            </Select>
          </StyleFormControl>

          {isOtherCause ? (
            <StyleFormControl size='small' focused={false}>
              <StyledFormLabel>
                <Label>{t('issues:detailedView:maintenanceReport:labels:other')}</Label>
              </StyledFormLabel>
              <TextField
                InputProps={{
                  sx: {
                    borderRadius: '10px',
                    '& ::placeholder': {
                      fontSize: '14px',
                      fontStyle: 'italic',
                    },
                  },
                }}
                placeholder={t('issues:detailedView:maintenanceReport:labels:otherPlaceholder')}
                size='small'
                onChange={handleUpdateReport('otherCause')}
              />
            </StyleFormControl>
          ) : null}

          <StyleFormControl size='small' focused={false}>
            <StyledFormLabel>
              <Label>{t('issues:detailedView:maintenanceReport:labels:severity')}</Label>
            </StyledFormLabel>
            <Select
              value={report.severityLevel}
              disabled={report.ignoreIssue}
              onChange={handleUpdateReport('severityLevel')}
              input={<StyledOutlinedInput />}
              sx={{
                '& .MuiSelect-select .notranslate::after': selectPlaceholder
                  ? {
                      content: `"${selectPlaceholder}"`,
                      opacity: 0.67,
                    }
                  : {},
              }}
            >
              {severityOptions.map((opt, index) => (
                <MenuItem key={opt.key} value={opt.key} data-testid={`severity-option-${index}`}>
                  {opt.label}
                </MenuItem>
              ))}
            </Select>
          </StyleFormControl>

          <StyleFormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={report.ignoreIssue}
                  onChange={(e) => handleUpdateReport('ignoreIssue')({ target: { value: e.target.checked } })}
                  data-testid='report-cause-ignore'
                />
              }
              label={<CheckboxLabel>{t('issues:detailedView:maintenanceReport:labels:ignore')}</CheckboxLabel>}
            />
          </StyleFormControl>
          <FormControl sx={{ width: '100%' }} size='small' focused={false}>
            <div style={{ padding: '12px 0' }}>
              <Label>{t('issues:detailedView:maintenanceReport:labels:additional')}</Label>
            </div>
            <TextField
              InputProps={{
                sx: {
                  borderRadius: '0px',
                  '& ::placeholder': {
                    fontSize: '12px',
                    fontStyle: 'italic',
                  },
                },
              }}
              placeholder={t('issues:detailedView:maintenanceReport:labels:additionalPlaceholder')}
              size='small'
              multiline
              rows={5}
              fullWidth
              onChange={handleUpdateReport('comment')}
            />
          </FormControl>
        </DialogContainer>

        <DialogActions>
          <ExtraWidthButton variant='outlined' onClick={handleCancel} data-testid='maintenance-report-cancel'>
            {t('common:cancel')}
          </ExtraWidthButton>
          <ExtraWidthButton
            variant='contained'
            onClick={handleSubmit}
            data-testid='maintenance-report-submit'
            disabled={!getIsSubmitAllowed()}
          >
            {t('issues:detailedView:maintenanceReport:submit')}
          </ExtraWidthButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MaintenanceReport;
