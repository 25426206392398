import { SORT_ORDER } from './types';

export const routes: Record<string, string> = Object.freeze({
  LANDING: '/',
  DASHBOARD: '/dashboard',
  TRAIN: '/dashboard/train',
  TRACK: '/dashboard/track',
  EXPORT_CART: '/dashboard/export-cart',
  EXPORT_HISTORY: '/dashboard/export-history',
  PROFILE: '/profile',
  ADMIN: '/dashboard/administration',
  UNAUTHORIZED: '/403',
});

export const accessKeys = Object.freeze({
  ASSETS_ALLOWED: 'assets_allowed',
  INFRASTRUCTURE_ALLOWED: 'infrastructure_allowed',
  DEVICE_MANAGEMENT_ALLOWED: 'device_management_allowed',
  ASSET_MANAGEMENT_ALLOWED: 'asset_management_allowed',
  USER_MANAGEMENT_ALLOWED: 'user_management_allowed',
});

export const sortOptions = [
  {
    value: SORT_ORDER.DESCENDING,
    label: 'Descending',
    compareFn: (a, b) => new Date(b as string).getTime() - new Date(a as string).getTime(),
  },
  {
    value: SORT_ORDER.ASCENDING,
    label: 'Ascending',
    compareFn: (a, b) => new Date(a as string).getTime() - new Date(b as string).getTime(),
  },
];

export const ISSUE_LIST_FETCH_INTERVAL: number = 300000; // ms, 5 minutes

export const MARK_AS_READ_DELAY: number = 4000;

export const ALERT_TIMEOUT: number = 3000;

export const SNACKBAR_TIMEOUT: number = 8000;

export const NA_PLACEHOLDER = '-';
