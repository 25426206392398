import { takeLatest, call, put, select } from 'redux-saga/effects';
import { graphqlClient, queries } from '../services/graphqlClient';
import {
  fetchUserDataFailed,
  fetchUserDataSuccess,
  fetchUserDataPending,
  fetchUsersPending,
  fetchUsersSuccess,
  fetchUsersFailed,
  TDeleteUser,
  deleteUserPending,
  deleteUserFailed,
  fetchUserRolesSuccess,
  fetchUserRolesFailed,
  createUserSuccess,
  createUserFailed,
  fetchUserRolesPending,
  createUserPending,
  deleteUserSuccess,
  TUpdateUserRoles,
  updateUserRolesFailed,
  updateUserRolesPending,
} from '../store/slices/user';
import apiClient from '../services/apiClient';
import { selectAuthUser } from '../store/selectors/user';
import { TCreateUser } from '../types';
import { PayloadAction } from '@reduxjs/toolkit';
import { REACT_APP_USE_API_DIRECTLY_FF } from '../config';

const gqlUserId = 'a6f9f132c92445cab0f8409d0dbd8c45';

function* fetchUserDataSaga() {
  try {
    const authUser = yield select(selectAuthUser);

    const result = REACT_APP_USE_API_DIRECTLY_FF
      ? yield call(apiClient.get, `/organizations/${authUser.organizationId}/users/${authUser.localAccountId}`)
      : yield call(graphqlClient.query, {
          query: queries.user.GET_USER_DATA,
          variables: { userId: gqlUserId },
        });

    yield put(
      fetchUserDataSuccess({
        user: REACT_APP_USE_API_DIRECTLY_FF ? result : result.data.user,
      }),
    );
  } catch (error) {
    yield put(
      fetchUserDataFailed({
        error,
      }),
    );
  }
}

function* fetchUsersSaga() {
  try {
    const authUser = yield select(selectAuthUser);

    if (authUser) {
      const result = REACT_APP_USE_API_DIRECTLY_FF
        ? yield call(apiClient.get, `/organizations/${authUser.organizationId}/users`)
        : yield call(graphqlClient.query, {
            query: queries.user.GET_USERS,
          });

      yield put(
        fetchUsersSuccess({
          users: REACT_APP_USE_API_DIRECTLY_FF ? result : result.data.users,
        }),
      );
    }
  } catch (error) {
    yield put(
      fetchUsersFailed({
        error,
      }),
    );
  }
}

function* fetchUserRolesSaga() {
  try {
    const authUser = yield select(selectAuthUser);

    if (authUser) {
      const result = yield call(apiClient.get, `/organizations/${authUser.organizationId}/roles`);

      yield put(
        fetchUserRolesSuccess({
          roles: result,
        }),
      );
    }
  } catch (error) {
    yield put(
      fetchUserRolesFailed({
        error,
      }),
    );
  }
}

function* updateUserRoles({ payload }: PayloadAction<TUpdateUserRoles>) {
  try {
    yield call(apiClient.put, `/organizations/${payload.organizationId}/users/${payload.userId}`, {
      roleIds: payload.roleIds,
      displayName: payload.displayName,
      firstName: payload.firstName,
      lastName: payload.lastName,
    });

    yield put(fetchUsersPending());
  } catch (error) {
    yield put(
      updateUserRolesFailed({
        error,
      }),
    );
  }
}

function* createUserSaga({ payload }: PayloadAction<TCreateUser>) {
  try {
    const authUser = yield select(selectAuthUser);

    yield call(apiClient.post, `/organizations/${authUser.organizationId}/users`, payload);

    yield put(fetchUsersPending());
    yield put(createUserSuccess());
  } catch (error) {
    yield put(
      createUserFailed({
        error,
      }),
    );
  }
}

function* deleteUserSaga({ payload }: PayloadAction<TDeleteUser>) {
  try {
    const authUser = yield select(selectAuthUser);

    yield call(apiClient.delete, `/organizations/${authUser.organizationId}/users/${payload.userId}`);

    yield put(fetchUsersPending());
    yield put(deleteUserSuccess());
  } catch (error) {
    yield put(
      deleteUserFailed({
        error,
      }),
    );
  }
}

export default function* watch() {
  yield takeLatest(fetchUserDataPending, fetchUserDataSaga);
  yield takeLatest(fetchUsersPending, fetchUsersSaga);
  yield takeLatest(fetchUserRolesPending, fetchUserRolesSaga);
  yield takeLatest(updateUserRolesPending, updateUserRoles);
  yield takeLatest(deleteUserPending, deleteUserSaga);
  yield takeLatest(createUserPending, createUserSaga);
}
