import React, { useRef, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import { Line } from 'react-chartjs-2';
import { Box, Button, ButtonGroup, Dialog, DialogContent } from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import CloseIcon from '@mui/icons-material/Close';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import { styled } from '@mui/material/styles';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
import { TChartData } from '../../types';
import theme from '../../theme';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, zoomPlugin);

/* ------- Styles ------- */
const ChartWrapper = styled(Box)<{ width: number }>(({ width }) => ({
  display: 'inline-block',
  position: 'relative',
  width,
}));

const ChartControls = styled(ButtonGroup)`
  position: absolute;
  bottom: 6rem;
  right: 3.5rem;
`;

const ControlBtn = styled(Button)`
  padding: 4px;
`;

const FullScreen = styled(FullscreenIcon)`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
`;

const Close = styled(CloseIcon)`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
`;

/* ------- Types ------- */
type TGenerateDatasets = () => {
  label: string;
  data: number[];
  borderColor: string;
  backgroundColor: string;
}[];

interface IChartProps {
  title?: string;
  labels: TChartData['ts'];
  data: Omit<TChartData, 'ts'>;
  hiddenDatasets?: string[];
  xAxisLabel?: string;
  yAxisLabel?: string;
  width?: number;
  fullscreen?: boolean;
  onClose?: () => void;
}

/* ------- Components ------- */
const Chart: React.FC<IChartProps> = ({
  title,
  labels,
  data,
  hiddenDatasets,
  xAxisLabel,
  yAxisLabel,
  width = 500,
  fullscreen = false,
  onClose,
}) => {
  const [isFullScreen, setIsFullScreen] = useState(fullscreen);
  const chartRef = useRef<ChartJSOrUndefined<'line', number[], string> | null>(null);

  const handleOpenFullScreen = () => {
    setIsFullScreen(true);
  };

  const handleCloseFullScreen = () => {
    setIsFullScreen(false);
    onClose && onClose();
  };

  const handleZoomIn = () => {
    if (chartRef && chartRef.current) {
      chartRef.current.zoom(1.1);
    }
  };

  const handleZoomOut = () => {
    if (chartRef && chartRef.current) {
      chartRef.current.zoom(0.9);
    }
  };

  const handleZoomReset = () => {
    if (chartRef && chartRef.current) {
      chartRef.current.resetZoom();
    }
  };

  const generateDatasets: TGenerateDatasets = () => {
    const colors = [
      theme.palette.green[100],
      theme.palette.others.actionBlue[100],
      theme.palette.blue[100],
      theme.palette.others.orange[100],
    ];
    const datasets = Object.keys(data).map((key, i) => {
      return {
        label: key,
        data: data[key],
        borderColor: colors[i],
        backgroundColor: colors[i],
        pointStyle: false,
        segment: {
          borderWidth: 1,
        },
        hidden: !!hiddenDatasets?.includes(key),
      };
    });

    return datasets;
  };

  const EnabledZoom = {
    pan: {
      enabled: true,
      mode: 'x',
      modifierKey: 'shift',
    },
    zoom: {
      mode: 'x',
      wheel: {
        enabled: true,
      },
      pinch: {
        enabled: true,
      },
      drag: {
        enabled: true,
      },
    },
  };

  const DisabledZoom = {
    pan: { enabled: false },
    zoom: {
      wheel: { enabled: false },
      pinch: { enabled: false },
    },
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom' as const,
        family: "'Roboto', 'sans-serif'",
        size: 14,
      },
      title: {
        display: !!title,
        text: title,
      },
      zoom: DisabledZoom,
    },
    scales: {
      x: {
        display: true,
        title: {
          display: !!xAxisLabel,
          text: xAxisLabel,
        },
        ticks: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
      y: {
        display: true,
        title: {
          display: !!yAxisLabel,
          text: yAxisLabel,
        },
      },
    },
  };

  return (
    <>
      {!fullscreen && (
        <ChartWrapper width={width}>
          <FullScreen onClick={handleOpenFullScreen} />
          <Line
            options={options}
            data={{
              labels,
              datasets: generateDatasets(),
            }}
            data-testid='chart'
          />
        </ChartWrapper>
      )}

      <Dialog fullWidth maxWidth='lg' open={isFullScreen} onClose={handleCloseFullScreen}>
        <DialogContent>
          <Close onClick={handleCloseFullScreen} />
          <Line
            ref={chartRef}
            options={{ ...options, plugins: { ...options.plugins, zoom: EnabledZoom } }}
            data={{
              labels,
              datasets: generateDatasets(),
            }}
            data-testid='fullscreen-chart'
          />

          <ChartControls orientation='vertical' variant='contained' color='inherit'>
            <ControlBtn onClick={handleZoomIn}>
              <ZoomInIcon />
            </ControlBtn>
            <ControlBtn onClick={handleZoomOut}>
              <ZoomOutIcon />
            </ControlBtn>
            <ControlBtn onClick={handleZoomReset}>
              <SearchOffIcon />
            </ControlBtn>
          </ChartControls>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Chart;
