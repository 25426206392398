import { gql } from '@apollo/client';

/*------------Issues------------*/
export const ISSUE_ATTRIBUTE_FIELDS = gql`
  fragment IssueAttributes on IssueAttribute {
    id
    issueAttributeName
    description
    valueRegex
    isSystemOwned
    issueAttributeValues {
      id
      issueId
      issueAttributeId
      value
    }
  }
`;

export const ISSUE_TYPE_FIELDS = gql`
  fragment IssueTypeFields on IssueType {
    id
    description
    isSystemOwned
  }
`;

export const ISSUE_STATUS_FIELDS = gql`
  fragment IssueStatusFields on IssueStatus {
    id
    description
  }
`;

export const ISSUE_STATUS_CHANGE_HISTORY = gql`
  ${ISSUE_STATUS_FIELDS}
  fragment IssueStatusChangeHistory on IssueStatusChangeHistory {
    id
    issueId
    fromStatusId
    toStatusId
    changeDateTime
    description
    issue {
      id
    }
    fromStatus {
      ...IssueStatusFields
    }
    toStatus {
      ...IssueStatusFields
    }
  }
`;

export const MEASUREMENT_EVENTS = gql`
  fragment MeasurementEvent on MeasurementEvent {
    measurementEventDateTime
    severityLevel
    eventData {
      rawData
    }
  }
`;

export const ISSUE_COMMENT = gql`
  fragment IssueComment on IssueComment {
    id
    issueId
    comment
    createdAt
    createdBy
    modifiedAt
    issueCommentTags {
      userId
    }
  }
`;

export const ISSUE_FEEDBACK = gql`
  fragment IssueFeedback on IssueFeedback {
    id
    issueId
    createdAt
    createdBy
    modifiedAt
    issueCauseId
    issueCause {
      causeEn
      causeDe
    }
    otherCause
    comment
    severityLevel
    ignoreIssue
  }
`;

/*------------Users------------*/
export const USER = gql`
  fragment User on User {
    id
    tenantId
    userName
    authenticationProvider
    email
    language
    userDescription
    lastLogin
    createdAt
    modifiedAt
    isDeleted
    tenant {
      id
      tenantName
    }
  }
`;
