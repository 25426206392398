import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Paper,
  Box,
  Link,
  Checkbox,
  FormLabel,
  FormControl,
  FormGroup,
  FormControlLabel,
} from '@mui/material';
import { getFormattedDate, getIssueLink, getThemedBorder } from '../../../../helpers';
import { styled } from '@mui/material/styles';
import { selectExportCart } from '../../../../store/selectors/export';
import { useDispatch, useSelector } from '../../../../hooks/redux';
import useCalculatedDimensions from '../../../../hooks/useCalculatedDimensions';
import {
  createExportCollectionPending,
  fetchExportCartPending,
  removeFromExportCartPending,
} from '../../../../store/slices/export';
import CustomSnackbar from '../../../../components/Snackbar';
import { routes } from '../../../../constants';
import { ISSUE_CATEGORY } from '../../../../types';
import ExtraWidthButton from '../../../../components/Buttons/ExtraWidthButton';
import useAccessControl from '../../../../hooks/useAccessControl';

/* ------- Styles ------- */
const StyledCell = styled(TableCell)({
  padding: '16px 32px',
});

const EmptyCartBlock = styled(Typography)({
  fontWeight: 400,
  fontSize: '32px',
  color: '#BCBCBC',
  display: 'flex',
  width: '100%',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '-10%',
});

const ExportButtonContainer = styled('div')({
  display: 'flex',
  justifyContent: 'end',
  width: '100%',
  paddingRight: '20px',
  height: '48px',
});

const ExportCartContainer = styled(Box)({
  width: '100%',
  padding: '45px 90px 40px 40px',
});

const CartTitle = styled(Typography)({
  flex: '1 1 100%',
  fontWeight: 700,
  fontSize: '22px',
  marginBottom: '30px',
});

const IssueBlockTitle = styled(Typography)({
  flex: '1 1 100%',
  fontWeight: 700,
  fontSize: '18px',
});

/* ------- Components ------- */

const ExportCart: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userAccess } = useAccessControl();

  const headerRef = document.getElementsByTagName('header')[0];
  const cartHeaderRef = useRef<HTMLDivElement | null>(null);
  const selectFormatRef = useRef<HTMLFieldSetElement | null>(null);
  const cartSubmitRef = useRef<HTMLDivElement | null>(null);
  const { height } = useCalculatedDimensions([
    headerRef,
    cartHeaderRef.current,
    selectFormatRef.current,
    cartSubmitRef.current,
  ]);
  const scrollingSpaceHeight = height - 110; // minus container paddings

  const cart = useSelector(selectExportCart);
  const [exportFormat, setExportFormat] = useState<{ isPdf: boolean; isDoc: boolean }>({ isPdf: false, isDoc: false });
  const [showSubmitNotification, setShowSubmitNotification] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  useEffect(() => {
    dispatch(fetchExportCartPending());
  }, [dispatch]);

  useEffect(() => {
    if (isSubmitted && !cart.length) {
      setShowSubmitNotification(true);
      setIsSubmitted(false);
    }
  }, [isSubmitted, cart]);

  const handleExportFormatCheck = (e: React.ChangeEvent<HTMLInputElement>, isChecked: boolean) => {
    const optionKey = e.target.name;
    const nextExportFormat = { ...exportFormat, [optionKey]: isChecked };

    setExportFormat(nextExportFormat);
  };

  const handleRemoveFromCart = (ids: string[]) => {
    dispatch(removeFromExportCartPending({ issueIds: ids }));
  };

  const handleCreateExport = () => {
    dispatch(createExportCollectionPending(exportFormat));
    setIsSubmitted(true);
  };

  const handleGoToExportHistory = () => {
    setShowSubmitNotification(false);
    navigate(routes.EXPORT_HISTORY);
  };

  const infrastructureIssues = cart.filter((item) => item.issueCategory === ISSUE_CATEGORY.TRACK);

  const trainIssues = cart.filter((item) => item.issueCategory === ISSUE_CATEGORY.ASSET);

  const mapInfrastructureIssues = () =>
    infrastructureIssues.map((row) => (
      <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <StyledCell sx={{ width: '250px' }} component='th' scope='row' align='left'>
          <span style={{ fontWeight: '700', fontSize: '16px' }}>{row.issueType.description}</span>
        </StyledCell>
        <StyledCell sx={{ width: '350px' }} align='left'>
          {row.line || null}
        </StyledCell>
        <StyledCell align='left'>{getFormattedDate(row.issueDateTime)}</StyledCell>
        <StyledCell sx={{ width: '220px' }} align='left'>
          <Link
            component='button'
            underline='always'
            variant='body1'
            onClick={() => navigate(getIssueLink(row.id))}
            sx={{ fontSize: '15px' }}
          >
            {t('export:cart:goToIssue')}
          </Link>
        </StyledCell>
        <StyledCell sx={{ width: '220px' }} align='left'>
          <Link
            component='button'
            underline='none'
            variant='body1'
            onClick={() => handleRemoveFromCart([row.id])}
            sx={{ fontSize: '15px' }}
          >
            {t('export:cart:remove')}
          </Link>
        </StyledCell>
      </TableRow>
    ));

  const mapTrainIssues = () =>
    trainIssues.map((row) => (
      <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <StyledCell sx={{ width: '250px' }} component='th' scope='row' align='left'>
          <span style={{ fontWeight: '700', fontSize: '16px' }}>{row.issueType.description}</span>
        </StyledCell>
        <StyledCell align='left'>
          {row.id}
          {/* {row.device}  Device/train name should be added*/}
        </StyledCell>
        <StyledCell sx={{ width: '220px' }} align='left'>
          <Link
            component='button'
            underline='always'
            variant='body1'
            onClick={() => navigate(getIssueLink(row.id))}
            sx={{ fontSize: '15px' }}
          >
            {t('export:cart:goToIssue')}
          </Link>
        </StyledCell>
        <StyledCell sx={{ width: '220px' }} align='left'>
          <Link
            component='button'
            underline='none'
            variant='body1'
            onClick={() => handleRemoveFromCart([row.id])}
            sx={{ fontSize: '15px' }}
          >
            {t('export:cart:remove')}
          </Link>
        </StyledCell>
      </TableRow>
    ));

  return (
    <ExportCartContainer>
      <div ref={cartHeaderRef} style={{ display: 'flex', justifyContent: 'space-between' }}>
        <CartTitle variant='body1' data-testid='export-cart-title'>
          {t('export:cart:title')}
        </CartTitle>

        <Link
          component='button'
          underline='none'
          variant='body1'
          onClick={() => handleRemoveFromCart(cart.map((item) => item.id))}
          data-testid='export-cart-reset-button'
          sx={{ minWidth: '205px', display: cart.length ? 'flex' : 'none' }}
        >
          {t('export:cart:emptyCart')}
        </Link>
      </div>

      {cart.length ? (
        <>
          <div style={{ maxHeight: `${scrollingSpaceHeight}px`, overflow: 'auto', overflowY: 'auto' }}>
            {userAccess.infrastructure_allowed ? (
              <div style={{ marginTop: '30px', display: infrastructureIssues.length ? 'block' : 'none' }}>
                <IssueBlockTitle variant='body2' id='infrastructureTitle' data-testid='export-cart-track-header'>
                  {userAccess.assets_allowed ? t('export:cart:trackIssuesTitle') : null}
                </IssueBlockTitle>

                <TableContainer
                  component={Paper}
                  sx={{
                    boxShadow: 0,
                    borderRadius: 0,
                    marginTop: '20px',
                    borderTop: (theme) => getThemedBorder(theme),
                    borderBottom: (theme) => getThemedBorder(theme),
                  }}
                >
                  <Table sx={{ minWidth: 650 }} aria-label='track table'>
                    <TableBody data-testid='export-cart-track-list'>{mapInfrastructureIssues()}</TableBody>
                  </Table>
                </TableContainer>
              </div>
            ) : null}

            {userAccess.assets_allowed ? (
              <div style={{ marginTop: '70px', display: trainIssues.length ? 'block' : 'none' }}>
                <IssueBlockTitle variant='body2' id='trainTitle'>
                  {userAccess.infrastructure_allowed ? t('export:cart:trainIssuesTitle') : null}
                </IssueBlockTitle>

                <TableContainer
                  component={Paper}
                  sx={{
                    boxShadow: 0,
                    borderRadius: 0,
                    marginTop: '20px',
                    borderTop: (theme) => getThemedBorder(theme),
                    borderBottom: (theme) => getThemedBorder(theme),
                  }}
                >
                  <Table sx={{ minWidth: 650 }} aria-label='train table'>
                    <TableBody>{mapTrainIssues()}</TableBody>
                  </Table>
                </TableContainer>
              </div>
            ) : null}
          </div>

          <FormControl ref={selectFormatRef} component='fieldset' sx={{ m: 4, marginBottom: 0 }} variant='standard'>
            <FormLabel sx={{ color: 'black' }} component='h1' focused={false}>
              {t('export:cart:selectFormat')}
            </FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={exportFormat.isPdf}
                    onChange={handleExportFormatCheck}
                    name='isPdf'
                    data-testid='export-cart-isPdf'
                  />
                }
                label='PDF'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={exportFormat.isDoc}
                    onChange={handleExportFormatCheck}
                    name='isDoc'
                    data-testid='export-cart-isDoc'
                  />
                }
                label='.doc'
              />
            </FormGroup>
          </FormControl>

          <ExportButtonContainer ref={cartSubmitRef}>
            <ExtraWidthButton
              variant='contained'
              onClick={handleCreateExport}
              data-testid='export-cart-submit'
              disabled={!Object.values(exportFormat).some((value) => value)}
            >
              {t('export:cart:export')}
            </ExtraWidthButton>
          </ExportButtonContainer>
        </>
      ) : (
        <EmptyCartBlock variant='body1' id='tableTitle' data-testid='export-cart-empty'>
          {t('export:cart:emptyCartViewLabel')}
        </EmptyCartBlock>
      )}
      <CustomSnackbar
        open={showSubmitNotification}
        message={t('export:cart:successMessage')}
        onClose={() => setShowSubmitNotification(false)}
        onAction={handleGoToExportHistory}
        actionLabel={t('export:cart:successActionLabel')}
        data-testid='export-cart-snackbar'
      />
    </ExportCartContainer>
  );
};

export default ExportCart;
