import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getToken } from '../auth';
import { REACT_APP_GRAPHQL_URL } from '../../config';

const httpLink = createHttpLink({
  uri: REACT_APP_GRAPHQL_URL,
});

const authLink = setContext(async (_request, prevContext) => {
  const { headers } = prevContext;
  const token = await getToken();

  return {
    headers: {
      ...headers,
      'Access-Control-Allow-Credentials': true,
      credentials: 'include',
      authorization: token ? `Bearer ${token}` : `Bearer ${process.env.REACT_APP_JWT_TOKEN}`,
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'ignore',
    },
  },
});

export default client;
