import { gql } from '@apollo/client';
import { USER } from '../fragments';

const GET_USER_DATA = gql`
  ${USER}
  query GetUser($userId: Uuid!) {
    user(id: $userId) {
      ...User
    }
  }
`;

const GET_USERS = gql`
  ${USER}
  query GetUsers {
    users {
      ...User
    }
  }
`;

export default { GET_USER_DATA, GET_USERS };
