import React, { useRef } from 'react';
import theme from '../../../theme';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from '../../../hooks/redux';
import { LayerGroup, LayersControl, Polygon, TileLayer, useMapEvents } from 'react-leaflet';
import L, { PathOptions, LayerGroup as TLayerGroup } from 'leaflet';
import { cellToBoundary, cellToLatLng, isValidCell, latLngToCell } from 'h3-js';
import { SEVERITY_LEVEL, TIssue } from '../../../types';
import { setFocusedMarkerPending } from '../../../store/slices/map';
import { selectFocusedMarker } from '../../../store/selectors/map';

/* ------- Types ------- */
type TBaseLayers = {
  name: string;
  url: string;
  attribution: string;
  className?: string;
  checked?: boolean;
}[];

interface IMapLayerProps {
  issues: TIssue[];
}

/* ------- Components ------- */
const MapLayer: React.FC<IMapLayerProps> = ({ issues }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const focusedMarker = useSelector(selectFocusedMarker);
  const polyGroupRef = useRef<TLayerGroup>(null);

  const highlightPolygon = (issue: TIssue) => {
    dispatch(setFocusedMarkerPending({ focusedMarkerId: issue.id, h3Index: issue.h3Index }));
  };

  const getPolyOptions = (severity: SEVERITY_LEVEL | null, issueId: string) => {
    // set default options
    const options: PathOptions = {
      fillColor: theme.palette.success.main,
      fillOpacity: 0.5,
      color: theme.palette.black[80],
      weight: 1,
      bubblingMouseEvents: false,
    };

    // set color if hexagon is focused
    if (focusedMarker.id === issueId) {
      return { ...options, fillColor: theme.palette.others.actionBlue[100] };
    }

    // set color based on severity if hexagon is not focused
    switch (severity) {
      case SEVERITY_LEVEL.HIGH:
        return { ...options, fillColor: theme.severity[severity] };
      case SEVERITY_LEVEL.MEDIUM:
        return { ...options, fillColor: theme.severity[severity] };
      case SEVERITY_LEVEL.LOW:
        return { ...options, fillColor: theme.severity[severity] };
      default:
        return options;
    }
  };

  const getScaledPolyBoundary = (h3Index: string, zoom: number) => {
    const minResolution = 7;
    const maxResolution = 11;

    const [cellLat, cellLang] = cellToLatLng(h3Index);
    let resolution = zoom - 3.5;

    if (resolution > maxResolution) {
      resolution = maxResolution;
    }

    if (resolution < minResolution) {
      resolution = minResolution;
    }

    const scaledCell = latLngToCell(cellLat, cellLang, resolution);
    return cellToBoundary(scaledCell);
  };

  const mapEvents = useMapEvents({
    zoomstart: () => {
      // remove all polygons to prevent issues with scaling and during flyTo
      polyGroupRef?.current?.clearLayers();
    },
    zoomend: () => {
      // add all polygons after zoom is finished with new resolution
      issues.forEach((issue) => {
        if (polyGroupRef?.current) {
          L.polygon(
            getScaledPolyBoundary(issue.h3Index, mapEvents.getZoom()),
            getPolyOptions(issue.severityLevel, issue.id),
          )
            .on('click', () => {
              // Seems like some leaflet bug and event handler is immutable so we can't relay on state or selector values because handler uses first value only
              highlightPolygon(issue);

              // flyTo is used to trigger map update.
              mapEvents.flyTo(cellToLatLng(issue.h3Index), mapEvents.getZoom());
            })
            .addTo(polyGroupRef?.current);
        }
      });
    },
  });

  const baseLayers: TBaseLayers = [
    {
      name: t('map:baseLayerGreyscale:title'),
      url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: `${t(
        'map:baseLayerGreyscale:attributionName',
      )} &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>`,
      className: 'baseLayerGrayscale',
      checked: true,
    },
    {
      name: t('map:baseLayerColored:title'),
      url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: `${t(
        'map:baseLayerColored:attributionName',
      )} &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>`,
      className: 'baseLayerColored',
    },
  ];

  return (
    <LayersControl position='topright'>
      {baseLayers.map((layer) => (
        <LayersControl.BaseLayer key={layer.name} checked={layer.checked} name={layer.name}>
          <TileLayer url={layer.url} attribution={layer.attribution} className={layer.className} />
        </LayersControl.BaseLayer>
      ))}
      <LayersControl.Overlay name={t('map:overlayRails:title')} checked>
        <TileLayer
          url='https://tiles.openrailwaymap.org/standard/{z}/{x}/{y}.png'
          attribution={`${t(
            'map:overlayRails:attributionName',
          )} &copy; <a href="https://www.openstreetmap.org/copyright">OpenRailwayMap</a>`}
          className='overlayRails'
        />
      </LayersControl.Overlay>
      <LayersControl.Overlay name={t('map:overlayMarker:title')} checked>
        <LayerGroup ref={polyGroupRef}>
          {issues
            ?.filter((issue) => isValidCell(issue.h3Index))
            ?.map((issue) => (
              <Polygon
                key={issue.id}
                smoothFactor={0.5}
                pathOptions={getPolyOptions(issue.severityLevel as SEVERITY_LEVEL, issue.id)}
                positions={getScaledPolyBoundary(issue.h3Index, mapEvents.getZoom())}
                eventHandlers={{
                  click: () => highlightPolygon(issue),
                }}
              />
            ))}
        </LayerGroup>
      </LayersControl.Overlay>
    </LayersControl>
  );
};

export default MapLayer;
