import { gql } from '@apollo/client';

const GET_NETWORKS = gql`
  query GetNetworks {
    networks {
      id
      tenantId
      name
      line {
        start {
          coordinates
          type
          bbox
        }
        end {
          coordinates
          type
          bbox
        }
      }
    }
  }
`;

export default { GET_NETWORKS };
