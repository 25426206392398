import React, { useRef, useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { styled } from '@mui/material/styles';
import CustomTabPanel from '../../../../components/TabPanel';
import { getThemedBorder, allyTabProps } from '../../../../helpers';
import useCalculatedDimensions from '../../../../hooks/useCalculatedDimensions';
import { useTranslation } from 'react-i18next';
import UserManagement from './UserManagement';
import useAccessControl from '../../../../hooks/useAccessControl';
import { accessKeys, routes } from '../../../../constants';
import CustomTooltip from '../../../../components/CustomTooltip';

/* ------- Styles ------- */
const TabNavContainer = styled(Box)(
  ({ theme }) => `
    border-bottom: ${getThemedBorder(theme)};
    border-top: ${getThemedBorder(theme)};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-shadow: 2px 6px 6px 0px rgba(0, 0, 0, 0.05);
  `,
);

const StyledTab = styled(Tab)`
  min-width: 140px;
`;

const Administration = () => {
  const { t } = useTranslation();
  const { userAccess, AccessControl } = useAccessControl();

  const tabNavRef = useRef<HTMLDivElement>(null);
  const headerRef = document.getElementsByTagName('header')[0];
  const { height } = useCalculatedDimensions([headerRef, tabNavRef?.current]);
  const tabHeight = height - 2; // remove 2px to prevent scroll bar from appearing

  const [selectedTab, setSelectedTab] = useState<number>(0);

  const handleChange = (_e: React.SyntheticEvent | null, tabIndex: number) => {
    setSelectedTab(tabIndex);
  };

  return (
    <AccessControl
      accessKeys={[accessKeys.USER_MANAGEMENT_ALLOWED, accessKeys.ASSET_MANAGEMENT_ALLOWED]}
      redirectUrl={routes.UNAUTHORIZED}
    >
      <Box sx={{ position: 'relative', width: '100%', height: tabHeight }}>
        <TabNavContainer ref={tabNavRef}>
          <Tabs value={selectedTab} onChange={handleChange} aria-label='administration tabs' sx={{ marginTop: '22px' }}>
            <StyledTab
              label={t('administration:userManagement:title')}
              {...allyTabProps(0)}
              disabled={!userAccess.user_management_allowed}
            />
            <CustomTooltip message={t('common:comingSoon')}>
              {/* <span> is required to have onHover event working */}
              <span>
                <StyledTab
                  label={t('administration:assetManagement:title')}
                  {...allyTabProps(1)}
                  disabled // ={!userAccess.asset_management_allowed} when asset management ready
                />
              </span>
            </CustomTooltip>
          </Tabs>
        </TabNavContainer>
        <CustomTabPanel value={selectedTab} index={0} height={tabHeight - 72} width={'96%'}>
          <UserManagement headerHeight={height} />
        </CustomTabPanel>
      </Box>
    </AccessControl>
  );
};

export default Administration;
