import { createSlice } from '@reduxjs/toolkit';
import { ApolloError } from '@apollo/client/errors';
import {
  TIssue,
  TIssueStatus,
  TIssueType,
  TIssueFilter,
  TIssueComment,
  TIssueCause,
  SORT_ORDER,
  TSeverityInfo,
  SEVERITY_LEVEL,
  TIssueSort,
  TIssueFeedback,
  TFeedbackSort,
  TFeedbackFilter,
} from '../../types';

/*-----------------TYPES-----------------*/
type TPayloadError = {
  error: ApolloError;
  isFiltered?: boolean;
};

type TFetchIssuesList = {
  issues: {
    list: TIssue[];
    lastUpdated: string;
  };
};

type TFetchIssuesInfo = {
  issueTypes: TIssueType[];
  issueStatuses: TIssueStatus[];
};

type TSetIssueFilter = {
  filter: TIssueFilter;
  isFiltered: boolean;
};

type TSetIssueSort = {
  sort: TIssueSort;
};

type TFetchIssueDetails = {
  issueDetails: TIssue | null;
};

type TSetIssuesListAttributes = {
  issues: {
    offset?: number;
    limit?: number;
  };
};

type TSetIssueStatus = {
  issueId: TIssue['id'];
  statusId: TIssueStatus['id'];
};

export type TSetFilterPending = {
  filter: object | null;
};

export type TSetSortPending = {
  sort: object | null;
};

type TFetchIssueDetailsPending = {
  issueId?: TIssue['id'];
  refetch?: boolean;
};

export type TSetIssueCommentsPending = {
  issueComments: {
    issueId: string;
    comment: string;
    tagUserIds: string[];
  }[];
};

type TSetIssueComments = {
  addedIssueComments: TIssueComment[];
};

export type TUpdateIssueCommentsPending = {
  issueComments: {
    id: string;
    comment: string;
    tagUserIds?: string[];
  }[];
};

type TUpdateIssueComments = {
  updatedIssueComments: TIssueComment[];
};

export type TDeleteIssueCommentsPending = {
  ids: string[];
};

type TDeleteIssueComments = {
  removedIssueComments: TIssueComment[];
};

type TSetFeedbacksSort = {
  sort: TFeedbackSort;
};

type TSetFeedbacksFilter = {
  feedbackFilter: TFeedbackFilter;
  isFiltered: boolean;
};

export interface IIssueFeedback {
  issueId: string;
  issueCauseId: string;
  severityLevel: string;
  otherCause: string;
  ignoreIssue: boolean;
  comment: string;
}

export type TIssueFeedbackUpdate = {
  id: string;
  issueId: string;
  issueCauseId: string;
  otherCause: string;
  severityLevel: string;
  ignoreIssue: boolean;
  comment: string;
};

export interface IIssue {
  issueCauses: TIssueCause[];
  issueDetails: TIssue | null;
  issues: {
    list: TIssue[];
    issuesSort: TIssueSort;
    issuesFilter: TIssueFilter;
    limit: number;
    offset: number;
    isFiltered: boolean;
    lastUpdated: string | undefined;
  };
  issueTypes: TIssueType[];
  issueStatuses: TIssueStatus[];
  issueSeverities: TSeverityInfo[];
  issueFeedbacksSort: TFeedbackSort;
  issueFeedbacksFilter: {
    feedbackFilter: TFeedbackFilter;
    isFiltered: boolean;
  };
  error: ApolloError | null;
}

/*-----------------INITIAL STATE-----------------*/
export const initialState: IIssue = {
  issueCauses: [],
  issueDetails: null,
  issues: {
    list: [],
    issuesSort: {
      issueDateTime: SORT_ORDER.DESCENDING, // Latest is first
    },
    issuesFilter: {
      issueType: {
        description: {
          in: [],
        },
      },
      severityLevel: {
        description: {
          in: [],
        },
      },
      issueStatus: {
        description: {
          in: ['Unread', 'Read', 'Exported'],
        },
      },
    },
    limit: 10,
    offset: 0,
    isFiltered: true,
    lastUpdated: undefined,
  },
  issueTypes: [],
  issueStatuses: [],
  issueSeverities: [
    {
      description: SEVERITY_LEVEL.HIGH,
      id: SEVERITY_LEVEL.HIGH,
      label: 'High',
    },
    {
      description: SEVERITY_LEVEL.MEDIUM,
      id: SEVERITY_LEVEL.MEDIUM,
      label: 'Medium',
    },
    {
      description: SEVERITY_LEVEL.LOW,
      id: SEVERITY_LEVEL.LOW,
      label: 'Low',
    },
  ],
  issueFeedbacksSort: {
    createdAt: SORT_ORDER.DESCENDING,
  },
  issueFeedbacksFilter: {
    feedbackFilter: {
      issueCause: {
        issueCauseId: {
          in: [],
        },
      },
    },
    isFiltered: false,
  },
  error: null,
};

/*-----------------SLICE-----------------*/
const issuesSlice = createSlice({
  name: 'issues',
  initialState,
  reducers: {
    /*-----------------Pending Actions/Reducers-----------------*/
    fetchIssuesListPending: (state) => state,
    refetchIssuesListPending: (state) => state,
    resetIssueFilter: (state) => state,
    fetchIssuesInfoPending: (state) => state,
    fetchIssueFeedbacksPending: (state) => state,
    setIssueStatusPending: (
      state,
      _action: {
        payload: TSetIssueStatus;
      },
    ) => state,
    setIssueFilterPending: (
      state,
      _action: {
        payload: TSetFilterPending;
      },
    ) => state,
    setIssueSortPending: (
      state,
      _action: {
        payload: TSetSortPending;
      },
    ) => state,
    fetchIssueDetailsPending: (
      state,
      _action: {
        payload: TFetchIssueDetailsPending;
      },
    ) => state,
    setIssueCommentsPending: (
      state,
      _action: {
        payload: TSetIssueCommentsPending;
      },
    ) => state,
    updateIssueCommentsPending: (
      state,
      _action: {
        payload: TUpdateIssueCommentsPending;
      },
    ) => state,
    deleteIssueCommentsPending: (
      state,
      _action: {
        payload: TDeleteIssueCommentsPending;
      },
    ) => state,
    createIssueFeedbacksPending: (
      state,
      _action: {
        payload: Partial<TIssueFeedback>;
      },
    ) => state,
    updateIssueFeedbacksPending: (
      state,
      _action: {
        payload: TIssueFeedbackUpdate;
      },
    ) => state,
    setFeedbacksSortPending: (
      state,
      _action: {
        payload: TSetSortPending;
      },
    ) => state,
    setFeedbacksFilterPending: (
      state,
      _action: {
        payload: TSetFilterPending;
      },
    ) => state,

    /*-----------------Success Actions/Reducers-----------------*/
    fetchIssuesListSuccess: (state, { payload }: { payload: TFetchIssuesList }) => {
      return {
        ...state,
        issues: {
          ...state.issues,
          list: [...state.issues.list, ...payload.issues.list],
          lastUpdated: payload.issues.lastUpdated,
        },
      };
    },
    refetchIssuesListSuccess: (state, { payload }: { payload: TFetchIssuesList }) => {
      return {
        ...state,
        issues: {
          ...state.issues,
          list: payload.issues.list,
          lastUpdated: payload.issues.lastUpdated,
        },
      };
    },
    fetchIssuesInfoSuccess: (state, { payload }: { payload: TFetchIssuesInfo }) => {
      return {
        ...state,
        issueTypes: payload.issueTypes,
        issueStatuses: payload.issueStatuses,
      };
    },
    setIssueFilterSuccess: (state, { payload }: { payload: TSetIssueFilter }) => {
      return {
        ...state,
        issues: {
          ...state.issues,
          isFiltered: payload.isFiltered,
          issuesFilter: payload.filter,
        },
      };
    },
    setIssueSortSuccess: (state, { payload }: { payload: TSetIssueSort }) => {
      return {
        ...state,
        issues: {
          ...state.issues,
          issuesSort: payload.sort,
        },
      };
    },
    fetchIssueDetailsSuccess: (state, { payload }: { payload: TFetchIssueDetails }) => {
      return {
        ...state,
        issueDetails: payload.issueDetails,
      };
    },
    setIssuesListAttributes: (state, { payload }: { payload: TSetIssuesListAttributes }) => {
      return {
        ...state,
        issues: {
          ...state.issues,
          offset: payload.issues.offset || 0,
        },
      };
    },
    resetIssuesList: (state) => {
      return {
        ...state,
        issues: {
          ...state.issues,
          list: initialState.issues.list,
          limit: initialState.issues.limit,
          offset: initialState.issues.offset,
          lastUpdated: initialState.issues.lastUpdated,
        },
      };
    },
    setIssueCommentsSuccess: (state, { payload }: { payload: TSetIssueComments }) => {
      if (!state?.issueDetails?.issueComments) return state;

      return {
        ...state,
        issueDetails: {
          ...state.issueDetails,
          issueComments: [...state.issueDetails.issueComments, ...payload.addedIssueComments],
        },
      };
    },
    updateIssueCommentsSuccess: (state, { payload }: { payload: TUpdateIssueComments }) => {
      if (!state?.issueDetails?.issueComments) return state;

      return {
        ...state,
        issueDetails: {
          ...state.issueDetails,
          issueComments: state.issueDetails.issueComments.map(
            (issueComment) =>
              payload.updatedIssueComments.find((updatedComment) => updatedComment.id === issueComment.id) ||
              issueComment,
          ),
        },
      };
    },
    deleteIssueCommentsSuccess: (state, { payload }: { payload: TDeleteIssueComments }) => {
      if (!state?.issueDetails?.issueComments) return state;

      return {
        ...state,
        issueDetails: {
          ...state.issueDetails,
          issueComments: state.issueDetails.issueComments.filter(
            (issueComment) =>
              !payload.removedIssueComments.some((removedComment) => removedComment.id === issueComment.id),
          ),
        },
      };
    },
    setIssueCauses: (state, { payload }: { payload: { issueCauses: TIssueCause[] } }) => {
      return {
        ...state,
        issueCauses: payload.issueCauses,
      };
    },
    fetchIssueFeedbacksSuccess: (state, { payload }: { payload: { issueFeedbacks: TIssueFeedback[] } }) => {
      return {
        ...state,
        issueDetails: {
          ...(state.issueDetails as TIssue),
          issueFeedbacks: payload.issueFeedbacks,
        },
      };
    },
    setFeedbacksSortSuccess: (state, { payload }: { payload: TSetFeedbacksSort }) => {
      return {
        ...state,
        issueFeedbacksSort: payload.sort,
      };
    },
    setFeedbacksFilterSuccess: (state, { payload }: { payload: TSetFeedbacksFilter }) => {
      return {
        ...state,
        issueFeedbacksFilter: {
          ...state.issueFeedbacksFilter,
          isFiltered: payload.isFiltered,
          feedbackFilter: payload.feedbackFilter,
        },
      };
    },

    /*-----------------Failed Actions/Reducers-----------------*/
    fetchIssuesListFailed: (state, { payload }: { payload: TPayloadError }) => {
      return {
        ...state,
        error: payload.error,
      };
    },
    refetchIssuesListFailed: (state, { payload }: { payload: TPayloadError }) => {
      return {
        ...state,
        issues: {
          ...state.issues,
        },
        error: payload.error,
      };
    },
    fetchIssuesInfoFailed: (state, { payload }: { payload: TPayloadError }) => {
      return {
        ...state,
        issueStatuses: initialState.issueStatuses,
        issueTypes: initialState.issueTypes,
        error: payload.error,
      };
    },
    setIssueStatusFailed: (state, { payload }: { payload: TPayloadError }) => {
      return {
        ...state,
        error: payload.error,
      };
    },
    setIssueFilterFailed: (state, { payload }: { payload: TPayloadError }) => {
      return {
        ...state,
        issues: {
          ...state.issues,
          isFiltered: payload.isFiltered || false,
          issuesFilter: {
            issueType: {
              description: {
                in: [],
              },
            },
          },
        },
        error: payload.error,
      };
    },
    setIssueSortFailed: (state, { payload }: { payload: TPayloadError }) => {
      return {
        ...state,
        issues: {
          ...state.issues,
          issuesSort: {
            issueDateTime: SORT_ORDER.DESCENDING,
          },
        },
        error: payload.error,
      };
    },
    fetchIssueDetailsFailed: (state, { payload }: { payload: TPayloadError }) => {
      return {
        ...state,
        issueDetails: initialState.issueDetails,
        error: payload.error,
      };
    },
    setIssueCommentsFailed: (state, { payload }: { payload: TPayloadError }) => {
      return {
        ...state,
        error: payload.error,
      };
    },
    updateIssueCommentsFailed: (state, { payload }: { payload: TPayloadError }) => {
      return {
        ...state,
        error: payload.error,
      };
    },
    deleteIssueCommentsFailed: (state, { payload }: { payload: TPayloadError }) => {
      return {
        ...state,
        error: payload.error,
      };
    },
    fetchIssueFeedbacksFailed: (state, { payload }: { payload: TPayloadError }) => {
      return {
        ...state,
        error: payload.error,
      };
    },
    createIssueFeedbacksFailed: (state, { payload }: { payload: TPayloadError }) => {
      return {
        ...state,
        error: payload.error,
      };
    },
    updateIssueFeedbacksFailed: (state, { payload }: { payload: TPayloadError }) => {
      return {
        ...state,
        error: payload.error,
      };
    },
    setFeedbacksSortFailed: (state, { payload }: { payload: TPayloadError }) => {
      return {
        ...state,
        issueFeedbacksSort: {
          createdAt: SORT_ORDER.DESCENDING,
        },
        error: payload.error,
      };
    },
    setFeedbacksFilterFailed: (state, { payload }: { payload: TPayloadError }) => {
      return {
        ...state,
        issueFeedbacksFilter: {
          ...state.issueFeedbacksFilter,
          isFiltered: payload.isFiltered || false,
          filter: {
            issueCause: {
              issueCauseId: {
                in: [],
              },
            },
          },
        },
        error: payload.error,
      };
    },
  },
});

export const {
  fetchIssuesListPending,
  refetchIssuesListPending,
  fetchIssuesInfoPending,
  setIssueStatusPending,
  setIssueFilterPending,
  setIssueSortPending,
  fetchIssueDetailsPending,
  setIssueCommentsPending,
  updateIssueCommentsPending,
  deleteIssueCommentsPending,
  fetchIssueFeedbacksPending,
  createIssueFeedbacksPending,
  updateIssueFeedbacksPending,
  setFeedbacksSortPending,
  setFeedbacksFilterPending,
  fetchIssuesListSuccess,
  refetchIssuesListSuccess,
  fetchIssuesInfoSuccess,
  setIssueFilterSuccess,
  setIssueSortSuccess,
  fetchIssueDetailsSuccess,
  setIssuesListAttributes,
  resetIssuesList,
  setIssueCommentsSuccess,
  updateIssueCommentsSuccess,
  deleteIssueCommentsSuccess,
  setIssueCauses,
  fetchIssueFeedbacksSuccess,
  setFeedbacksSortSuccess,
  setFeedbacksFilterSuccess,
  fetchIssuesListFailed,
  refetchIssuesListFailed,
  fetchIssuesInfoFailed,
  setIssueStatusFailed,
  setIssueFilterFailed,
  setIssueSortFailed,
  fetchIssueDetailsFailed,
  setIssueCommentsFailed,
  updateIssueCommentsFailed,
  deleteIssueCommentsFailed,
  fetchIssueFeedbacksFailed,
  createIssueFeedbacksFailed,
  updateIssueFeedbacksFailed,
  setFeedbacksSortFailed,
  setFeedbacksFilterFailed,
} = issuesSlice.actions;

export default issuesSlice.reducer;
