import { takeLatest, call, put, select } from 'redux-saga/effects';
import { graphqlClient, queries } from '../services/graphqlClient';
import {
  selectIssueDetails,
  selectIssueStatuses,
  selectIssueFeedbacksFilter,
  selectIssueFeedbacksSort,
  selectIssuesListAttributes,
} from '../store/selectors/issue';
import mutations from '../services/graphqlClient/mutations';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  fetchIssuesInfoPending,
  fetchIssuesInfoFailed,
  fetchIssuesInfoSuccess,
  fetchIssuesListPending,
  fetchIssuesListFailed,
  fetchIssuesListSuccess,
  refetchIssuesListPending,
  refetchIssuesListSuccess,
  refetchIssuesListFailed,
  fetchIssueDetailsPending,
  setIssueStatusPending,
  setIssueStatusFailed,
  setIssueFilterPending,
  setIssueFilterSuccess,
  setIssueFilterFailed,
  fetchIssueDetailsSuccess,
  fetchIssueDetailsFailed,
  setIssueCommentsPending,
  setIssueCommentsSuccess,
  setIssueCommentsFailed,
  deleteIssueCommentsPending,
  deleteIssueCommentsSuccess,
  deleteIssueCommentsFailed,
  TSetFilterPending,
  TSetIssueCommentsPending,
  TDeleteIssueCommentsPending,
  resetIssuesList,
  initialState,
  updateIssueCommentsPending,
  TUpdateIssueCommentsPending,
  updateIssueCommentsSuccess,
  updateIssueCommentsFailed,
  setIssueCauses,
  createIssueFeedbacksPending,
  createIssueFeedbacksFailed,
  TSetSortPending,
  setIssueSortSuccess,
  setIssueSortFailed,
  setIssueSortPending,
  updateIssueFeedbacksPending,
  TIssueFeedbackUpdate,
  updateIssueFeedbacksFailed,
  fetchIssueFeedbacksPending,
  fetchIssueFeedbacksSuccess,
  fetchIssueFeedbacksFailed,
  setFeedbacksSortPending,
  setFeedbacksSortSuccess,
  setFeedbacksSortFailed,
  setFeedbacksFilterPending,
  setFeedbacksFilterSuccess,
  setFeedbacksFilterFailed,
} from '../store/slices/issues';
import { TFeedbackFilter, TFeedbackSort, TIssueFeedback, TIssueFilter, TIssueSort, TIssueStatus } from '../types';
import { omit } from '../helpers';
import { setInitialMapPositionPending } from '../store/slices/map';

const removeEmptyIssueFilters = (filter: TIssueFilter | TFeedbackFilter, key: string) => {
  const notNullFilterValues: TIssueFilter[] | TFeedbackFilter[] = [];
  Object.keys(filter).forEach((filterKey) => {
    if (filter[filterKey][key].in.length) {
      notNullFilterValues.push({ [filterKey]: filter[filterKey] });
    }
  });

  return { filter: Object.assign({}, ...notNullFilterValues), isEmptyList: !notNullFilterValues.length };
};

function* fetchIssuesListSaga() {
  try {
    const { issuesSort, issuesFilter, isFiltered, limit, offset } = yield select(selectIssuesListAttributes);
    const { filter, isEmptyList } = removeEmptyIssueFilters(issuesFilter, 'description');

    const result = yield call(graphqlClient.query, {
      query: queries.issue.GET_ISSUES,
      variables: {
        issuesSort,
        ...(isFiltered &&
          !isEmptyList && {
            issuesFilter: filter,
          }),
        ...(limit && {
          limit,
        }),
        ...(offset && {
          offset,
        }),
      },
    });

    yield put(
      fetchIssuesListSuccess({
        issues: {
          list: result.data.issues,
          lastUpdated: new Date().toString(),
        },
      }),
    );

    yield put(
      setInitialMapPositionPending({
        h3Index: result?.data.issues[0].h3Index,
      }),
    );
  } catch (error) {
    yield put(
      fetchIssuesListFailed({
        error,
      }),
    );
  }
}

function* refetchIssuesListSaga() {
  try {
    const { issuesSort, issuesFilter, isFiltered, limit, offset } = yield select(selectIssuesListAttributes);
    const newLimit = limit + offset;
    const { filter, isEmptyList } = removeEmptyIssueFilters(issuesFilter, 'description');

    const result = yield call(graphqlClient.query, {
      query: queries.issue.GET_ISSUES,
      variables: {
        issuesSort,
        ...(isFiltered &&
          !isEmptyList && {
            issuesFilter: filter,
          }),
        limit: newLimit,
        offset: 0,
        fetchPolicy: 'network-only',
      },
    });

    yield put(
      refetchIssuesListSuccess({
        issues: {
          list: result.data.issues,
          lastUpdated: new Date().toString(),
        },
      }),
    );
  } catch (error) {
    yield put(
      refetchIssuesListFailed({
        error,
      }),
    );
  }
}

function* fetchIssuesInfoSaga() {
  try {
    const result = yield call(graphqlClient.query, {
      query: queries.issue.GET_ISSUES_INFO,
    });

    yield put(
      fetchIssuesInfoSuccess({
        issueTypes: result.data.issueTypes,
        issueStatuses: result.data.issueStatuses,
      }),
    );
  } catch (error) {
    yield put(
      fetchIssuesInfoFailed({
        error,
      }),
    );
  }
}

function* updateIssueStatus({ payload }: PayloadAction<{ issueId: string; statusId: string }>) {
  try {
    yield call(graphqlClient.mutate, {
      mutation: mutations.issue.UPDATE_ISSUES,
      variables: {
        issues: [
          {
            id: payload.issueId,
            issueStatusId: payload.statusId,
          },
        ],
      },
    });

    yield put(refetchIssuesListPending());
    yield put(fetchIssueDetailsPending({ refetch: true }));
  } catch (error) {
    yield put(
      setIssueStatusFailed({
        error,
      }),
    );
  }
}

function* setIssueFilter({ payload }: PayloadAction<TSetFilterPending>) {
  try {
    const { filter } = payload;
    const updatedFilter: TIssueFilter = filter
      ? Object.assign(
          {},
          ...Object.keys(filter).map((key) => ({
            [key]: {
              description: {
                in: filter[key],
              },
            },
          })),
        )
      : initialState.issues.issuesFilter;
    const { isEmptyList } = removeEmptyIssueFilters(updatedFilter, 'description');

    yield put(resetIssuesList());
    yield put(
      setIssueFilterSuccess({
        filter: updatedFilter,
        isFiltered: !isEmptyList,
      }),
    );
    yield put(fetchIssuesListPending());
  } catch (error) {
    yield put(
      setIssueFilterFailed({
        error,
        isFiltered: false,
      }),
    );
  }
}

function* setIssueSort({ payload }: PayloadAction<TSetSortPending>) {
  try {
    const { sort } = payload;
    const updatedSort: TIssueSort = sort || initialState.issues.issuesSort;

    yield put(resetIssuesList());
    yield put(
      setIssueSortSuccess({
        sort: updatedSort,
      }),
    );
    yield put(fetchIssuesListPending());
  } catch (error) {
    yield put(
      setIssueSortFailed({
        error,
      }),
    );
  }
}

function* getIssueDetails({ payload }: PayloadAction<{ issueId?: string; refetch?: boolean }>) {
  try {
    const storedIssueDetails = yield select(selectIssueDetails);
    const issueId = payload?.issueId || storedIssueDetails?.id;
    if (!issueId) return;

    const detailsResult = yield call(graphqlClient.query, {
      query: queries.issue.GET_ISSUE_BY_ID,
      variables: {
        issueId,
      },
      ...(payload.refetch && { fetchPolicy: 'network-only' }),
    });

    yield put(
      fetchIssueDetailsSuccess({
        issueDetails: detailsResult.data?.issue,
      }),
    );

    const issueTypeId = detailsResult.data?.issue.issueTypeId;

    const causesResult = yield call(graphqlClient.query, {
      query: queries.issue.GET_CAUSES_BY_ISSUE_TYPE,
      variables: {
        issueTypeId,
      },
    });

    yield put(
      setIssueCauses({
        issueCauses: causesResult.data?.issueCausesByIssueTypeId,
      }),
    );
  } catch (error) {
    yield put(
      fetchIssueDetailsFailed({
        error,
      }),
    );
  }
}

function* setIssueComments({ payload }: PayloadAction<TSetIssueCommentsPending>) {
  try {
    const result = yield call(graphqlClient.mutate, {
      mutation: mutations.issue.CREATE_ISSUE_COMMENTS,
      variables: {
        issueComments: payload.issueComments,
      },
    });

    yield put(
      setIssueCommentsSuccess({
        addedIssueComments: result.data?.createIssueComments,
      }),
    );
    yield put(fetchIssueDetailsPending({}));
  } catch (error) {
    yield put(
      setIssueCommentsFailed({
        error,
      }),
    );
    yield put(fetchIssueDetailsPending({}));
  }
}

function* updateIssueComments({ payload }: PayloadAction<TUpdateIssueCommentsPending>) {
  try {
    const result = yield call(graphqlClient.mutate, {
      mutation: mutations.issue.UPDATE_ISSUE_COMMENTS,
      variables: {
        issueComments: payload.issueComments,
      },
    });

    yield put(
      updateIssueCommentsSuccess({
        updatedIssueComments: result.data?.updateIssueComments,
      }),
    );
  } catch (error) {
    yield put(
      updateIssueCommentsFailed({
        error,
      }),
    );
  }
}

function* deleteIssueComments({ payload }: PayloadAction<TDeleteIssueCommentsPending>) {
  try {
    const result = yield call(graphqlClient.mutate, {
      mutation: mutations.issue.DELETE_ISSUE_COMMENTS,
      variables: {
        ids: payload.ids,
      },
    });

    yield put(
      deleteIssueCommentsSuccess({
        removedIssueComments: result.data?.deleteIssueComments,
      }),
    );
  } catch (error) {
    yield put(
      deleteIssueCommentsFailed({
        error,
      }),
    );
  }
}

function* getIssueFeedbacks() {
  try {
    const sort = yield select(selectIssueFeedbacksSort);
    const { feedbackFilter, isFiltered } = yield select(selectIssueFeedbacksFilter);
    const { filter, isEmptyList } = removeEmptyIssueFilters(feedbackFilter, 'issueCauseId');
    const details = yield select(selectIssueDetails);
    // issues always filtered by issueId to get only the feedbacks for the opened detail view.
    // optionally, the filters defined by the user are added
    const result = yield call(graphqlClient.query, {
      query: queries.issue.GET_ISSUE_FEEDBACKS,
      variables: {
        sort,
        filter: {
          issueId: { eq: details.id },
          ...(isFiltered &&
            !isEmptyList && {
              ...filter,
            }),
        },
      },
    });

    yield put(
      fetchIssueFeedbacksSuccess({
        issueFeedbacks: result.data?.issueFeedbacks,
      }),
    );
  } catch (error) {
    yield put(
      fetchIssueFeedbacksFailed({
        error,
      }),
    );
  }
}

function* createIssueFeedbacks({ payload }: PayloadAction<Partial<TIssueFeedback>>) {
  try {
    const success = yield call(graphqlClient.mutate, {
      mutation: mutations.issue.CREATE_ISSUE_FEEDBACKS,
      variables: {
        issueFeedbacks: [payload],
      },
    });

    if (success && payload.ignoreIssue && payload.issueId) {
      const issueStatuses: TIssueStatus[] = yield select(selectIssueStatuses);
      const ignoreStatusId = issueStatuses.find((status) => status.description === 'Ignored')?.id;

      ignoreStatusId && (yield put(setIssueStatusPending({ issueId: payload.issueId, statusId: ignoreStatusId })));
      yield put(fetchIssueFeedbacksPending());
    }

    yield put(fetchIssueDetailsPending({ issueId: payload.issueId }));
    yield put(refetchIssuesListPending());
  } catch (error) {
    yield put(
      createIssueFeedbacksFailed({
        error,
      }),
    );
  }
}

function* updateIssueFeedbacks({ payload }: PayloadAction<TIssueFeedbackUpdate>) {
  try {
    yield call(graphqlClient.mutate, {
      mutation: mutations.issue.UPDATE_ISSUE_FEEDBACKS,
      variables: {
        issueFeedbacks: [omit(payload, ['issueId'])],
      },
    });

    yield put(fetchIssueFeedbacksPending());
  } catch (error) {
    yield put(
      updateIssueFeedbacksFailed({
        error,
      }),
    );
  }
}

function* setFeedbacksSort({ payload }: PayloadAction<TSetSortPending>) {
  try {
    const { sort } = payload;
    const updatedSort: TFeedbackSort = sort || initialState.issues.issuesSort;

    yield put(
      setFeedbacksSortSuccess({
        sort: updatedSort,
      }),
    );

    yield put(fetchIssueFeedbacksPending());
  } catch (error) {
    yield put(
      setFeedbacksSortFailed({
        error,
      }),
    );
  }
}

function* setFeedbacksFilter({ payload }: PayloadAction<TSetFilterPending>) {
  try {
    const { filter } = payload;
    const updatedFilter: TFeedbackFilter = filter
      ? Object.assign(
          {},
          ...Object.keys(filter).map((key) => ({
            [key]: {
              issueCauseId: {
                in: filter[key],
              },
            },
          })),
        )
      : initialState.issueFeedbacksFilter.feedbackFilter;
    const { isEmptyList } = removeEmptyIssueFilters(updatedFilter, 'issueCauseId');

    yield put(
      setFeedbacksFilterSuccess({
        feedbackFilter: updatedFilter,
        isFiltered: !isEmptyList,
      }),
    );

    yield put(fetchIssueFeedbacksPending());
  } catch (error) {
    yield put(
      setFeedbacksFilterFailed({
        error,
        isFiltered: false,
      }),
    );
  }
}

export default function* watch() {
  yield takeLatest(fetchIssuesListPending, fetchIssuesListSaga);
  yield takeLatest(refetchIssuesListPending, refetchIssuesListSaga);
  yield takeLatest(fetchIssuesInfoPending, fetchIssuesInfoSaga);
  yield takeLatest(setIssueFilterPending, setIssueFilter);
  yield takeLatest(setIssueSortPending, setIssueSort);
  yield takeLatest(setIssueStatusPending, updateIssueStatus);
  yield takeLatest(fetchIssueDetailsPending, getIssueDetails);
  yield takeLatest(setIssueCommentsPending, setIssueComments);
  yield takeLatest(updateIssueCommentsPending, updateIssueComments);
  yield takeLatest(deleteIssueCommentsPending, deleteIssueComments);
  yield takeLatest(fetchIssueFeedbacksPending, getIssueFeedbacks);
  yield takeLatest(createIssueFeedbacksPending, createIssueFeedbacks);
  yield takeLatest(updateIssueFeedbacksPending, updateIssueFeedbacks);
  yield takeLatest(setFeedbacksSortPending, setFeedbacksSort);
  yield takeLatest(setFeedbacksFilterPending, setFeedbacksFilter);
}
