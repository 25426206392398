import React, { useEffect, useRef, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { v4 as uuidv4 } from 'uuid';

/* ------- Styles ------- */
const List = styled('ul')<{ height: number }>(({ height }) => ({
  display: 'flex',
  flexDirection: 'column',
  listStyle: 'none',
  height: height,
  paddingInlineStart: 0,
  marginBlock: 0,
  overflowY: 'scroll',
}));

/* ------- Types ------- */
interface IInfiniteList {
  listItems: React.ReactNode[];
  listHeight: number;
  loadMore: () => void;
  loadMoreAllowed: boolean;
}

/* ------- Components ------- */
const InfiniteList: React.FC<IInfiniteList> = ({ listItems, listHeight, loadMoreAllowed, loadMore }) => {
  const listRef = useRef<HTMLUListElement | null>(null);
  const lastItemRef = useRef<HTMLLIElement | null>(null);
  const isLastItem = (listIndex: number) => listIndex === listItems.length - 1;

  const observer = useMemo(() => {
    return new IntersectionObserver(
      ([entry]) => {
        entry.isIntersecting && lastItemRef?.current?.setAttribute('data-testid', 'isInView');
        entry.isIntersecting && loadMoreAllowed && loadMore();
      },
      {
        root: listRef?.current,
        rootMargin: '30px 0px 0px 0px', // 30px top margin to compensate the missing height caused by latestUpdate
      },
    );
  }, [listRef, lastItemRef, loadMoreAllowed, loadMore]);

  if (lastItemRef?.current) {
    observer.observe(lastItemRef?.current);
  }

  useEffect(() => {
    const lastItem = lastItemRef?.current;

    if (lastItem) {
      observer.observe(lastItem);
    }

    return () => {
      if (lastItem) {
        observer.unobserve(lastItem);
      }
    };
  }, [lastItemRef, observer]);

  return (
    <List ref={listRef} height={listHeight}>
      {listItems?.map((item, index) => (
        <li key={uuidv4()} ref={isLastItem(index) ? lastItemRef : null}>
          {item}
        </li>
      ))}
    </List>
  );
};

export default InfiniteList;
