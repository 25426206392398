import { createSlice } from '@reduxjs/toolkit';
import { ApolloError } from '@apollo/client/errors';

/*-----------------TYPES-----------------*/
type TPayloadError = {
  error: ApolloError;
};

export type TSetInitialMapPositionPending = {
  h3Index: string;
};

export type TSetFocusedMarkerPending = {
  focusedMarkerId: string | null;
  h3Index: string | null;
};

export interface IMap {
  initialMapPosition: string | null;
  marker: {
    focusedMarker: {
      id: string | null;
      h3Index: string | null;
    };
  };
  error: ApolloError | null;
}

/*-----------------INITIAL STATE-----------------*/
export const initialState: IMap = {
  initialMapPosition: null,
  marker: {
    focusedMarker: {
      id: null,
      h3Index: null,
    },
  },
  error: null,
};

/*-----------------SLICE-----------------*/
const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    /*-----------------Pending Actions/Reducers-----------------*/
    setInitialMapPositionPending: (
      state,
      _action: {
        payload: TSetInitialMapPositionPending;
      },
    ) => state,
    setFocusedMarkerPending: (
      state,
      _action: {
        payload: TSetFocusedMarkerPending;
      },
    ) => state,

    /*-----------------Success Actions/Reducers-----------------*/
    setInitialMapPositionSuccess: (state, { payload }: { payload: TSetInitialMapPositionPending }) => {
      return {
        ...state,
        initialMapPosition: payload.h3Index,
      };
    },
    setFocusedMarkerSuccess: (state, { payload }: { payload: TSetFocusedMarkerPending }) => {
      return {
        ...state,
        marker: {
          ...state.marker,
          focusedMarker: {
            ...state.marker.focusedMarker,
            id: payload.focusedMarkerId,
            h3Index: payload.h3Index,
          },
        },
      };
    },

    /*-----------------Failed Actions/Reducers-----------------*/
    setInitialMapPositionFailed: (state, { payload }: { payload: TPayloadError }) => {
      return {
        ...state,
        error: payload.error,
      };
    },
    setFocusedMarkerFailed: (state, { payload }: { payload: TPayloadError }) => {
      return {
        ...state,
        error: payload.error,
      };
    },
  },
});

export const {
  setInitialMapPositionPending,
  setFocusedMarkerPending,
  setInitialMapPositionSuccess,
  setFocusedMarkerSuccess,
  setInitialMapPositionFailed,
  setFocusedMarkerFailed,
} = mapSlice.actions;

export default mapSlice.reducer;
