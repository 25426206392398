import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Paper,
  Box,
  Link,
  IconButton,
  TableHead,
} from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { getFormattedDate, getFormattedTime, getIssueLink, getThemedBorder } from '../../../../helpers';
import { styled } from '@mui/material/styles';
import { selectExportHistory } from '../../../../store/selectors/export';
import { useDispatch, useSelector } from '../../../../hooks/redux';
import useCalculatedDimensions from '../../../../hooks/useCalculatedDimensions';
import { fetchExportHistoryPending, setExportHistoryAttributes } from '../../../../store/slices/export';
import { TExportCollection, TExportIssue, TSortOrder } from '../../../../types';
import Sort from '../../../../components/Sort';
import { sortOptions } from '../../../../constants';

/* ------- Styles ------- */
const CollapsibleRow = styled(TableRow, { shouldForwardProp: (prop) => prop !== 'open' })<{ open: boolean }>(
  ({ theme, open }) => ({
    background: open ? theme.palette.grey[200] : 'transparent',
    '& > *': { borderTop: getThemedBorder(theme) },
    height: '60px',
  }),
);

const StyledCell = styled(TableCell)(({ theme }) => ({
  padding: '16px 16px',
  borderTop: getThemedBorder(theme),
  borderBottom: 'unset',
}));

const EmptyHistoryBlock = styled(Typography)({
  fontWeight: 400,
  fontSize: '32px',
  color: '#BCBCBC',
  display: 'flex',
  width: '100%',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '-9%',
});

const ExportHistoryContainer = styled(Box)({
  width: '100%',
  padding: '45px 90px 40px 40px',
});

const HistoryTitle = styled(Typography)({
  flex: '1 1 100%',
  fontWeight: 700,
  fontSize: '22px',
  paddingBottom: '15px',
});

const SortContainer = styled('div')({
  margin: '15px 0',
});

/* ------- Components ------- */
const ExportHistory: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const headerRef = document.getElementsByTagName('header')[0];
  const historyHeaderRef = useRef<HTMLDivElement | null>(null);
  const sortContainerRef = useRef<HTMLDivElement | null>(null);
  const { height } = useCalculatedDimensions([headerRef, historyHeaderRef.current, sortContainerRef.current]);
  const { collections, exportsSort } = useSelector(selectExportHistory);
  const [expandedExports, setExpandedExports] = useState<string[]>([]);
  const scrollingSpaceHeight = height - 190;

  const handleExportExpand = (key: string) => {
    const isExpanded = expandedExports.includes(key);

    if (isExpanded) {
      setExpandedExports(expandedExports.filter((el) => el !== key));
    } else {
      setExpandedExports(expandedExports.concat([key]));
    }
  };

  useEffect(() => {
    dispatch(fetchExportHistoryPending());
  }, [dispatch]);

  const tableHeader = [
    { label: '', width: '30px' },
    { label: t('export:history:headers:eventType'), width: '300px' },
    { label: t('export:history:headers:date'), width: '200px' },
    { label: t('export:history:headers:time'), width: '350px' },
    { label: t('export:history:headers:actions'), width: '200px' },
    { label: '', width: '200px' },
  ];

  const handleSort = (value: string) => {
    const sortValue = value as TSortOrder;

    dispatch(setExportHistoryAttributes({ exportsSort: { createdAt: sortValue } }));
    dispatch(fetchExportHistoryPending());
  };

  const exportRow = (row: TExportCollection, issue: TExportIssue, isSingleExport: boolean, isOpen = true) => {
    return (
      <TableRow
        key={`${row.id}-${issue.id}`}
        sx={{
          '& > *': { ...(!isSingleExport && { border: 'unset' }) },
          visibility: isOpen ? 'visible' : 'collapse',
          height: '60px',
        }}
        data-testid={isSingleExport ? 'export-single-item' : 'export-bulk-list-item'}
      >
        <StyledCell scope='row' />
        <StyledCell scope='row' align='left'>
          {issue.issueType.description}
        </StyledCell>
        <StyledCell align='left'>{isSingleExport ? getFormattedDate(row.createdAt) : null}</StyledCell>
        <StyledCell align='left'>{isSingleExport ? getFormattedTime(row.createdAt) : null}</StyledCell>
        <StyledCell align='left'>
          {isSingleExport ? (
            <Link
              component='button'
              underline='none'
              variant='body1'
              disabled
              onClick={() => {}}
              sx={{ fontSize: '15px' }}
            >
              {t('export:history:downloadPdf')}
            </Link>
          ) : null}
        </StyledCell>
        <StyledCell align='left'>
          <Link
            component='button'
            underline='always'
            variant='body1'
            onClick={() => navigate(`${getIssueLink(issue.id)}/events`)}
            sx={{ fontSize: '15px' }}
          >
            {t('export:history:viewEvent')}
          </Link>
        </StyledCell>
      </TableRow>
    );
  };

  const singleExport = (row: TExportCollection) => {
    const issue = row.exportCollectionBridgeIssues[0].issue;

    return exportRow(row, issue, true);
  };

  const bulkExport = (row: TExportCollection) => {
    const isOpen = expandedExports.includes(row.id);
    const issues = row.exportCollectionBridgeIssues.map((item) => item.issue);

    return [
      <CollapsibleRow
        key={row.id}
        selected={isOpen}
        open={isOpen}
        onClick={() => handleExportExpand(row.id)}
        data-testid='export-history-bulk'
      >
        <StyledCell>
          <IconButton aria-label='expand row' size='small' sx={{ padding: 0 }}>
            {isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </StyledCell>
        <StyledCell scope='row'>{t('export:history:bulkLabel')}</StyledCell>
        <StyledCell align='left'>{getFormattedDate(row.createdAt)}</StyledCell>
        <StyledCell align='left'>{getFormattedTime(row.createdAt)}</StyledCell>
        <StyledCell align='left'>
          <Link
            component='button'
            underline='none'
            variant='body1'
            disabled
            onClick={(e) => {
              e.stopPropagation();
            }}
            sx={{ fontSize: '15px' }}
          >
            {t('export:history:downloadPdf')}
          </Link>
        </StyledCell>
        <StyledCell align='left' />
      </CollapsibleRow>,
      issues.map((issue) => exportRow(row, issue, false, isOpen)),
    ];
  };

  const mapCollections = () =>
    collections.map((row) => {
      const issues = row.exportCollectionBridgeIssues.map((item) => item.issue);

      return issues.length > 1 ? bulkExport(row) : singleExport(row);
    });

  const exportHistorySortOptions = sortOptions.map((opt) => ({
    ...opt,
    label: t(`common:sort:${opt.label}`),
  }));

  return (
    <ExportHistoryContainer>
      <HistoryTitle ref={historyHeaderRef} variant='body1' data-testid='export-history-title'>
        {t('export:history:title')}
      </HistoryTitle>

      {collections.length ? (
        <>
          <SortContainer ref={sortContainerRef}>
            <Sort
              icon={false}
              sortByLabel={t('common:sort:byDate')}
              options={exportHistorySortOptions}
              selected={exportsSort.createdAt}
              onChange={handleSort}
            />
          </SortContainer>
          <TableContainer
            component={Paper}
            sx={{
              borderRadius: 0,
              marginTop: '35px',
              maxHeight: scrollingSpaceHeight,
              border: (theme) => getThemedBorder(theme),
            }}
          >
            <Table sx={{ minWidth: 650, height: '100%' }} stickyHeader aria-label='history table'>
              <TableHead>
                <TableRow>
                  {tableHeader.map((item, index) => (
                    <TableCell key={index} align='left' style={{ ...(item.width && { width: item.width }) }}>
                      <b>{item.label || null}</b>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>{mapCollections()}</TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <EmptyHistoryBlock variant='body1' id='tableTitle' data-testid='export-history-empty'>
          {t('export:history:emptyHistoryViewLabel')}
        </EmptyHistoryBlock>
      )}
    </ExportHistoryContainer>
  );
};

export default ExportHistory;
