import React from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { FormControl, IconButton, MenuItem, OutlinedInput } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import { SORT_ORDER, TSortOrder } from '../../types';

/* ------- Styles ------- */
const SortContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  minWidth: '240px',
});

/* ------- Types ------- */
interface IFilterProps {
  onChange: (selectedOption: string, selectedOrder: TSortOrder) => void;
  sortByLabel?: string;
  icon?: boolean;
  selected: string;
  options: { value: string; label: string }[];
  sortOrder?: TSortOrder;
}

/* ------- Components ------- */
const Sort: React.FC<IFilterProps> = ({
  onChange,
  sortByLabel = 'date',
  icon = true,
  selected,
  options,
  sortOrder,
}) => {
  const { t } = useTranslation();

  const handleChangeSortValue = (event: SelectChangeEvent) => {
    const nextValue = event.target.value;
    onChange(nextValue, SORT_ORDER.DESCENDING);
  };

  const handleChangeSortOrder = () => {
    const nextOrder = sortOrder === SORT_ORDER.ASCENDING ? SORT_ORDER.DESCENDING : SORT_ORDER.ASCENDING;
    onChange(selected, nextOrder);
  };

  return (
    <SortContainer data-testid='sort-block'>
      <span data-testid='sort-by-label'>
        {t('common:list:sort')}
        &nbsp;
        {sortByLabel || null}
      </span>
      <FormControl sx={{ m: 1, marginRight: 0 }} size='small' focused={false}>
        <Select value={selected} onChange={handleChangeSortValue} input={<OutlinedInput />}>
          {options.map((opt, index) => (
            <MenuItem key={opt.value} value={opt.value} data-testid={`sort-option-${index}`}>
              {opt.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {icon ? (
        <IconButton
          sx={{
            color: (theme) => theme.palette.black[100],
            '.MuiTouchRipple-ripple .MuiTouchRipple-child': {
              backgroundColor: 'transparent',
            },
            ':hover': {
              backgroundColor: 'transparent',
            },
          }}
          onClick={handleChangeSortOrder}
        >
          <SwapVertIcon
            sx={{ transform: `scaleX(${sortOrder === SORT_ORDER.ASCENDING ? -1 : 1})` }}
            data-testid='sort-icon'
            color='primary'
          />
        </IconButton>
      ) : null}
    </SortContainer>
  );
};

export default Sort;
