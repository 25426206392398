import packageJson from '../package.json';

const configure = (varName: string, fallback?: unknown) => {
  // @ts-expect-error: _env_ is not default field for window object, it's set by environment
  const value = process.env[varName] || window._env_?.[varName] || fallback;

  if (value === undefined && process.env.NODE_ENV === 'production') {
    throw new Error(JSON.stringify(`Missing required config ${varName}`));
  }

  if (value === 'true') return true;
  if (value === 'false') return false;

  return value;
};

export const REACT_APP_VERSION = configure('REACT_APP_VERSION', packageJson.version);

export const REACT_APP_GRAPHQL_URL = configure('REACT_APP_GRAPHQL_URL');
export const REACT_APP_ONEIOT_API_DOMAIN = configure('REACT_APP_ONEIOT_API_DOMAIN');

export const REACT_APP_CLIENT_ID = configure('REACT_APP_CLIENT_ID');
export const REACT_APP_AUTHORITY_DOMAIN = configure('REACT_APP_AUTHORITY_DOMAIN');
export const REACT_APP_AUTHORITY_URI = configure('REACT_APP_AUTHORITY_URI');
export const REACT_APP_SIGNUP_SIGNIN_POLICY = configure('REACT_APP_SIGNUP_SIGNIN_POLICY');
export const REACT_APP_SCOPES_URI = configure('REACT_APP_SCOPES_URI');

export const REACT_APP_USE_API_DIRECTLY_FF = configure('REACT_APP_USE_API_DIRECTLY_FF');
