import React, { useState, useRef } from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from '../../../../../hooks/redux';
import { selectIssueStatuses } from '../../../../../store/selectors/issue';
import { setIssueStatusPending } from '../../../../../store/slices/issues';
import ContextMenu, { ContextButton } from '../../../../../components/ContextMenu';
import Link from '@mui/material/Link';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  getIssueFormattedDate,
  copyToClipboard,
  mapIssueStatusToId,
  getIssueLink,
  getIssueStatus,
} from '../../../../../helpers';
import { ALERT_TIMEOUT, routes } from '../../../../../constants';
import { ISSUE_STATUS, TIssue, TSeverityLevel } from '../../../../../types';
import { MapHandle } from '../../../../../components/InfrastructureMap';
import { addToExportCartPending } from '../../../../../store/slices/export';
import CustomAlert from '../../../../../components/Alert';
import { Typography, alpha } from '@mui/material';
import { selectFocusedMarker } from '../../../../../store/selectors/map';
import { setFocusedMarkerPending } from '../../../../../store/slices/map';

/* ------- Styles ------- */
const ListItem = styled('div')<{ highlighted: boolean }>(({ theme, highlighted }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '12px 8px',
  borderBottom: '1px solid #d9d9d9',
  cursor: 'pointer',
  height: '100px',
  background: highlighted ? alpha(theme.palette.blue[20], 0.35) : 'transparent',
  gap: '30px',
}));

const Severity = styled('div')<{ severity: TSeverityLevel | null }>(({ theme, severity }) => ({
  minWidth: 8,
  height: 72,
  background: severity ? theme.severity[severity] : 'transparent',
}));

const IssueType = styled(Typography)({
  marginBlock: 14,
});

const DetailViewLinkContainer = styled('span')`
  display: inline-block;
  border: none;
  background-color: inherit;
  padding: 0;
  margin-bottom: 10px;
`;

const InfoGroup = styled('div')({
  marginLeft: '10px',
  '& p': { margin: '10px 0' },
});

const IconBlock = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  width: '50%',
});

const DateDetailsBlock = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '50%',
  padding: '0 10px',
});

/* ------- Types ------- */
interface ITrackIssue {
  issue: TIssue;
  map: React.RefObject<MapHandle>;
}

/* ------- Components ------- */
const TrackIssue: React.FC<ITrackIssue> = ({ issue, map }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const issueStatuses = useSelector(selectIssueStatuses);
  const focusedMarker = useSelector(selectFocusedMarker);

  const status = getIssueStatus(issue);

  const isDetailViewOpen = () => window.location.href.includes(issue.id);
  const isHighlighted = isDetailViewOpen() || focusedMarker.id === issue.id;

  const [contextOpen, setContextOpen] = useState<boolean>(false);
  const [alert, setAlert] = useState<{ show: boolean; message: string }>({ show: false, message: '' });
  const menuAnchorRef = useRef<HTMLButtonElement | null>(null);

  const revertStatus = (revert_status) => {
    const revertStatusId = mapIssueStatusToId(issueStatuses)[revert_status];

    if (issue.id && revertStatusId) {
      dispatch(
        setIssueStatusPending({
          issueId: issue.id,
          statusId: revertStatusId,
        }),
      );
    }
  };

  const handleCopyLink = () => {
    copyToClipboard(getIssueLink(issue.id, true));
    setAlert({ show: true, message: t('issues:linkCopied') });
    setTimeout(() => {
      setAlert({ show: false, message: '' });
    }, ALERT_TIMEOUT);
  };

  const handleAddToExport = (id: string) => {
    dispatch(addToExportCartPending({ issueId: id }));
    setAlert({ show: true, message: t('export:addToCartMessage') });
    setTimeout(() => {
      setAlert({ show: false, message: '' });
    }, ALERT_TIMEOUT);
  };

  const triggerStatusActionMap = {
    [ISSUE_STATUS.READ]: t('issues:contextMenu:read'),
    [ISSUE_STATUS.UNREAD]: t('issues:contextMenu:unread'),
    [ISSUE_STATUS.PROCESSED]: t('issues:contextMenu:stopIgnore'),
  };

  const getContextActions = () => [
    ...(status.revert_status
      ? [
          {
            name: triggerStatusActionMap[status.revert_status],
            callback: () => revertStatus(status.revert_status),
          },
        ]
      : []),

    { name: t('issues:contextMenu:exportPdf'), callback: () => console.log('export pdf'), disabled: true },
    { name: t('issues:contextMenu:exportDoc'), callback: () => console.log('export doc'), disabled: true },
    { name: t('issues:contextMenu:addToExport'), callback: () => handleAddToExport(issue.id), disabled: true },
    {
      name: t('issues:contextMenu:send:title'),
      href: `mailto:?subject=${t('issues:contextMenu:send:subject')}&body=${getIssueLink(issue.id)}`,
    },
    { name: t('issues:contextMenu:copyLink'), callback: handleCopyLink },
    { name: t('issues:contextMenu:emailSupport'), callback: () => {}, disabled: true },
  ];

  const getIssueTypeIcon = () => {
    switch (issue.issueType.description) {
      case 'HardSpot':
        return '/icons/issueTypes/hardspot.svg';
      case 'Height':
        return '/icons/issueTypes/height.svg';
      case 'Geometry':
        return '/icons/issueTypes/geometry.svg';
      case 'DynamicUpliftForce':
        return '/icons/issueTypes/upliftforce.svg';
    }
  };

  const handleOpenDetailView = (e) => {
    e.stopPropagation();

    // set marker focus to current issue
    dispatch(setFocusedMarkerPending({ focusedMarkerId: issue.id, h3Index: issue.h3Index }));
    // navigate to issue detail view
    navigate(`${routes.TRACK}/${issue.id}`);
  };

  const statusToStyles = (theme) => {
    switch (issue.issueStatus.description) {
      case ISSUE_STATUS.READ:
        return {
          itemBackgroundColor: 'transparent',
          titleFontWeight: 400,
          titleColor: theme.palette.black[100],
          descriptionColor: theme.palette.black[60],
        };

      case ISSUE_STATUS.UNREAD:
        return {
          itemBackgroundColor: alpha(theme.palette.green[20], 0.7),
          titleFontWeight: 700,
          titleColor: theme.palette.black[100],
          descriptionColor: theme.palette.black[100],
        };

      case ISSUE_STATUS.IGNORED:
        return {
          itemBackgroundColor: alpha(theme.palette.grey[60], 0.11),
          titleFontWeight: 400,
          titleColor: theme.palette.black[60],
          descriptionColor: theme.palette.black[40],
        };

      default:
        return {
          itemBackgroundColor: 'transparent',
          titleFontWeight: 400,
          titleColor: theme.palette.black[100],
          descriptionColor: theme.palette.black[60],
        };
    }
  };

  return (
    <>
      <ListItem
        highlighted={isHighlighted}
        onClick={() => map.current?.showIssueOnMap(issue.id, issue.h3Index)}
        data-testid='trackIssue'
        sx={isHighlighted ? {} : { backgroundColor: (theme) => statusToStyles(theme).itemBackgroundColor }}
      >
        <IconBlock>
          <Severity severity={issue.severityLevel} />
          <img src={getIssueTypeIcon()} alt={issue.issueType.description} width={40} height={40} />
          <InfoGroup>
            <IssueType
              sx={{
                fontWeight: (theme) => statusToStyles(theme).titleFontWeight,
                color: (theme) => statusToStyles(theme).titleColor,
              }}
            >
              {issue.issueType.description}
            </IssueType>
            <Typography
              sx={{
                fontSize: '14px',
                color: (theme) => (isHighlighted ? theme.palette.black[100] : statusToStyles(theme).descriptionColor),
              }}
            >
              {issue?.line || `ID: ${issue?.id.substring(32, 36)}`}
            </Typography>
          </InfoGroup>
        </IconBlock>
        <DateDetailsBlock>
          <InfoGroup>
            <Typography sx={{ fontSize: '14px', color: (theme) => statusToStyles(theme).titleColor }}>
              {getIssueFormattedDate(issue.issueDateTime)}
            </Typography>
            <DetailViewLinkContainer>
              <Link component='button' onClick={handleOpenDetailView}>
                {t('issues:detailViewBtn')}
              </Link>
            </DetailViewLinkContainer>
          </InfoGroup>
          <ContextButton
            ref={menuAnchorRef}
            testId='issueMenu-button'
            menuOpen={contextOpen}
            handleOpenMenu={() => setContextOpen(true)}
          >
            <MoreVertIcon />
          </ContextButton>
        </DateDetailsBlock>
      </ListItem>

      {contextOpen && (
        <ContextMenu
          menuOpen={true}
          anchorEl={menuAnchorRef.current}
          handleCloseMenu={() => setContextOpen(false)}
          contextActions={getContextActions()}
        />
      )}

      <CustomAlert show={alert.show} message={alert.message} severity='success' />
    </>
  );
};

export default TrackIssue;
