import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { EventType } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { routes } from '../constants';
import Login from './Login';
import Dashboard from './Dashboard';
import DetailedView from './Dashboard/containers/DetailedView';
import Profile from './Profile';
import { b2cPolicies } from '../services/auth/auth.config';
import UnauthorizedAccess from '../components/UnauthorizedAccess';

const Pages = () => {
  const { instance } = useMsal();

  useEffect(() => {
    const callbackId = instance.addEventCallback((event) => {
      if (
        (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
        event?.payload?.account
      ) {
        /**
         * For the purpose of setting an active account for UI update, we want to consider only the auth
         * response resulting from SUSI flow. "tfp" claim in the id token tells us the policy (NOTE: legacy
         * policies may use "acr" instead of "tfp"). To learn more about B2C tokens, visit:
         * https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
         */
        if (event?.payload?.idTokenClaims?.['tfp'] === b2cPolicies.names.editProfile) {
          // retrieve the account from initial sing-in to the app
          const originalSignInAccount = instance
            .getAllAccounts()
            .find(
              (account) =>
                account?.idTokenClaims?.oid === event?.payload?.idTokenClaims?.oid &&
                account?.idTokenClaims?.sub === event?.payload?.idTokenClaims?.sub &&
                account?.idTokenClaims?.['tfp'] === b2cPolicies?.names.signUpSignIn,
            );

          const signUpSignInFlowRequest = {
            authority: b2cPolicies.authorities.signUpSignIn.authority,
            account: originalSignInAccount,
          };

          // silently login again with the signUpSignIn policy
          instance.ssoSilent(signUpSignInFlowRequest);
        }
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
    // eslint-disable-next-line
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path={routes.LANDING} element={<Login />} />
        <Route path={routes.PROFILE} element={<Profile />} />
        <Route path={routes.DASHBOARD} element={<Dashboard />}>
          <Route path=':view'>
            <Route path=':issueId' element={<DetailedView />}>
              <Route path=':tab' />
            </Route>
          </Route>
        </Route>
        <Route path={routes.UNAUTHORIZED} element={<UnauthorizedAccess />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Pages;
