import { put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  setInitialMapPositionPending,
  setFocusedMarkerPending,
  setInitialMapPositionSuccess,
  setFocusedMarkerSuccess,
  setInitialMapPositionFailed,
  setFocusedMarkerFailed,
  TSetInitialMapPositionPending,
  TSetFocusedMarkerPending,
} from '../store/slices/map';

function* setInitialMapPosition({ payload }: PayloadAction<TSetInitialMapPositionPending>) {
  const { h3Index } = payload;

  try {
    yield put(
      setInitialMapPositionSuccess({
        h3Index,
      }),
    );
  } catch (error) {
    yield put(
      setInitialMapPositionFailed({
        error,
      }),
    );
  }
}

function* setFocusedMarker({ payload }: PayloadAction<TSetFocusedMarkerPending>) {
  const { focusedMarkerId, h3Index } = payload;

  try {
    yield put(
      setFocusedMarkerSuccess({
        focusedMarkerId,
        h3Index,
      }),
    );
  } catch (error) {
    yield put(
      setFocusedMarkerFailed({
        error,
      }),
    );
  }
}

export default function* watch() {
  yield takeLatest(setInitialMapPositionPending, setInitialMapPosition);
  yield takeLatest(setFocusedMarkerPending, setFocusedMarker);
}
